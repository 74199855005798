import { Component, NgZone, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'environments/environment';
import { AuthService } from 'app/auth/auth.service';
import { OktaAuth, OktaAuthOptions } from '@okta/okta-auth-js';
import { AppState } from '@app/core/ngrx';
import { Store } from '@ngrx/store';

@Component({
  selector: 'ecp-auth-essilor-admin',
  template: ``,
})
export class EssilorAdminComponent implements OnInit {
  /**
   * config
   */
  config: OktaAuthOptions = environment.okta.client;
  /**
   * authClient
   */
  authClient = new OktaAuth(this.config);

  /**
   * constructor
   * @param ngZone
   * @param authService
   * @param store
   * @param router
   */
  constructor(
    private ngZone: NgZone,
    private authService: AuthService,
    private store: Store<AppState>,
    private router: Router,
  ) {}
  /**
   * ngOnInit
   */
  ngOnInit() {
    const config2: OktaAuthOptions = environment.okta.client;
    const authClient2 = new OktaAuth(config2);
    authClient2.token.getWithRedirect({
      clientId: environment.okta.oidc.clientId,
      responseType: ['id_token', 'token'],
      redirectUri: environment.okta.client.redirectUri + '/idp',
      scopes: environment.okta.oidc.scopes.split(' '),
      state: '210eea7a-5a30-4b06-ade3-25214a10f6aa',
      nonce: '4d8a9814-d44c-4587-8c67-ebbb33749437',
      idp: environment.okta.oidc.idp,
    });
  }
}
