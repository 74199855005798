import { Injectable } from '@angular/core';
import { catchError, map, switchMap, takeUntil } from 'rxjs/operators';

import { BrandsService } from '@app/core/services';
import { ofType, Actions, Effect, createEffect } from '@ngrx/effects';
import { BrandsActionTypes, AuthActionTypes } from '../actions';
import { of } from 'rxjs';

/**
 * BrandsEpics
 */
@Injectable()
export class BrandsEffects {
  /**
   * constructor
   * @param {BrandsService} brandsService
   */
  constructor(
    private brandsService: BrandsService,
    private actions$: Actions,
  ) {}

  /**
   * loadBrands
   * @param {ActionsObservable<any>} action$
   * @returns {Observable<any>}
   */
  loadBrands = createEffect(() =>
    this.actions$.pipe(
      ofType(BrandsActionTypes.LOAD_BRANDS),
      switchMap(() => {
        return this.brandsService.getBrands().pipe(
          map(brands => ({
            type: BrandsActionTypes.LOAD_BRANDS_SUCCESS,
            payload: brands,
          })),
          catchError(err =>
            of({ type: BrandsActionTypes.LOAD_BRANDS_ERROR, payload: err }),
          ),
        );
      }),
    ),
  );

  /**
   * loadBrandsError
   * @param {ActionsObservable<any>} action$
   * @returns {Observable<any>}
   */
  loadBrandsError = createEffect(() =>
    this.actions$.pipe(
      ofType(BrandsActionTypes.LOAD_BRANDS_ERROR),
      map(() => ({ type: AuthActionTypes.LOGOUT })),
    ),
  );

  /**
   * loadPatientBrands
   * @param {ActionsObservable<any>} action$
   * @returns {Observable<any>}
   */
  loadPatientBrands = createEffect(() =>
    this.actions$.pipe(
      ofType(BrandsActionTypes.LOAD_PATIENT_BRANDS),
      switchMap((action: any) => {
        return this.brandsService.getPatientBrands(action.payload.id).pipe(
          map(brands => ({
            type: BrandsActionTypes.LOAD_BRANDS_SUCCESS,
            payload: brands,
          })),
          catchError(err =>
            of({ type: BrandsActionTypes.LOAD_BRANDS_ERROR, payload: err }),
          ),
        );
      }),
    ),
  );
}
