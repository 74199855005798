import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { UserActionTypes, AuthActionTypes } from '../actions';
import { mergeMap, map, takeUntil, catchError, switchMap } from 'rxjs/operators';
import { Actions, Effect, ofType, createEffect } from '@ngrx/effects';
import { UserService } from '@app/core/services';

@Injectable()
export class UserEffects {
  constructor(private actions$: Actions, private userService: UserService) {}

  loadUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActionTypes.LOAD_USER),
      switchMap(() => {
        return this.userService.getUser().pipe(
          map(user => ({
            type: UserActionTypes.LOAD_USER_SUCCESS,
            payload: user,
          })),
          catchError(err =>
            of({
              type: UserActionTypes.LOAD_USER_ERROR,
              payload: err,
            }),
          ),
        );
      }),
    ),
  );

  changeProfile = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActionTypes.CHANGE_USER_ACCOUNT),
      switchMap(({ payload }) => {
        return this.userService.switchProfile(payload).pipe(
          map(user => 
            ({
            type: UserActionTypes.LOAD_USER_SUCCESS,
            payload: user,
          })
          ),
          catchError(err => of({ type: UserActionTypes.LOAD_USER_ERROR, payload: err })),
        );
      }),
    ),
  );
}
