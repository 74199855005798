<div class="carousel__wrapper" #wrapper>
  <div #carousel class="carousel__inner" (swipeleft)="next()" (swiperight)="prev()">
    <ng-container *ngFor="let item of items;" class="carousel__item">
      <ng-container [ngTemplateOutlet]="item.tpl" class="carousel__slide"></ng-container>
    </ng-container>
  </div>
</div>

<div *ngIf="controls && items.length > 1" class="carousel__dots">
  <ng-container *ngFor="let item of items; let index = index">
    <button (click)="goto(index);" [class.active]="index === currentSlide">{{ index }}</button>
  </ng-container>
</div>

<div *ngIf="controls && items.length > 1" class="carousel__controls">
  <button
    class="carousel__prev"
    (click)="prev()"
    [disabled]="currentSlide === 0">
    <mat-icon svgIcon="ecp-arrow-left"></mat-icon>
  </button>
  <button
    class="carousel__next"
    (click)="next()"
    [disabled]="currentSlide === (items.length - 1)">
    <mat-icon svgIcon="ecp-arrow-right"></mat-icon>
  </button>
</div>
