<ul class="sidenav__list">
    <li *ngFor="let link of links">
        <a *ngIf="link.ext" [href]="link.link" target="_blank">
          {{ link.label }}
        </a>

        <a *ngIf="!link.ext" [routerLink]="link.link">
          {{ link.label }}
        </a>
    </li>
</ul>
