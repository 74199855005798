import { animate, AnimationBuilder, AnimationFactory, AnimationPlayer, style } from '@angular/animations';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ContentChildren,
  Directive,
  ElementRef,
  HostBinding,
  Input,
  OnInit,
  QueryList,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { CarouselItemDirective } from './carousel-item.directive';

@Directive({ selector: '.carousel__item' })
export class CarouselElementDirective {}

@Component({
  selector: 'ecp-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CarouselComponent implements OnInit, AfterViewInit {
  @HostBinding('class.carousel') carousel_ = true;

  @Input() timing = '250ms ease-out';
  @Input() controls = true;

  @ViewChild('wrapper', { static: true }) private wrapper: ElementRef;
  @ViewChild('carousel', { static: true }) private carousel: ElementRef;

  @ContentChildren(CarouselItemDirective)
  items: QueryList<CarouselItemDirective>;

  private player: AnimationPlayer;

  private itemWidth: number;

  currentSlide = 0;

  onResize: any;

  constructor(private renderer: Renderer2, private animation: AnimationBuilder) {}

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    this.renderer.listen('window', 'resize', event => {
      this.onResizing(event);
    });

    this.onResizing();
  }

  onResizing(event?: any): void {
    clearTimeout(this.onResize);
    this.onResize = setTimeout(() => {
      this.itemWidth = this.wrapper.nativeElement.getBoundingClientRect().width;
      this.translate();
    }, 500);
  }

  private buildAnimation(offset: number = 0): AnimationFactory {
    return this.animation.build([
      animate(
        this.timing,
        style({
          transform: `translateX(-${offset}px)`,
        }),
      ),
    ]);
  }

  private updateAnimationPlayer(animation: AnimationFactory): void {
    this.player = animation.create(this.carousel.nativeElement);
  }

  private translate(): void {
    try {
      const offset = this.currentSlide * this.itemWidth;
      this.updateAnimationPlayer(this.buildAnimation(offset));
      this.player.play();
    } catch (err) {
      // console.log(err);
    }
  }

  prev(): void {
    if (this.currentSlide === 0) return;
    this.currentSlide = (this.currentSlide - 1 + this.items.length) % this.items.length;

    this.translate();
  }

  next(): void {
    if (this.currentSlide + 1 === this.items.length) return;
    this.currentSlide = (this.currentSlide + 1) % this.items.length;

    this.translate();
  }

  goto(index: number = 0): void {
    this.currentSlide = index;
    this.translate();
  }
}
