import { Injectable, NgZone } from '@angular/core';
import { environment } from '@env/environment';
import { DateTime } from 'luxon';
import { interval, Observable, Observer } from 'rxjs';
import { map, takeWhile } from 'rxjs/operators';
import { AppState, AuthActionTypes } from '@app/core/ngrx';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { AccessToken, OktaAuth, OktaAuthOptions } from '@okta/okta-auth-js';

@Injectable({ providedIn: 'root' })
export class AuthService {
  sessionTimer: Observable<any>;
  config: OktaAuthOptions = environment.okta.client;
  authClient = new OktaAuth(this.config);
  $isAuthenticated: Observable<boolean>;

  private observer: Observer<boolean>;
  private nonce: string;

  constructor(private store: Store<AppState>, private router: Router, private ngZone: NgZone) {
    this.$isAuthenticated = new Observable((observer: Observer<boolean>) => {
      this.observer = observer;
      this.isAuthenticated().then(
        val => {
          // Check if user has active session
          this.authClient.session
            .exists()
            // if exist, retrieve session data
            .then(() => this.authClient.session.get())
            .then(session => {
              // create session timer
              // @ts-ignore
              this.createSessionTimer(session.expiresAt);
            });
          observer.next(val);
        },
        error => {
          console.error('> $isAuthenticated - isAuthenticated() - error => ', error);
        },
      );
    });
  }

  async isAuthenticated() {
    // Checks if there is a current accessToken in the TokenManger.
    return !!(await this.authClient.tokenManager.get('accessToken'));
  }

  async handleAuthentication() {
    const tokens = await this.authClient.token.parseFromUrl();
    this.authClient.tokenManager.add('idToken', tokens.tokens.idToken);
    this.authClient.tokenManager.add('accessToken', tokens.tokens.accessToken);

    if (await this.isAuthenticated()) {
      this.observer.next(true);
    }

    // Retrieve the saved URL and navigate back
    const url = sessionStorage.getItem('okta-app-url');
    this.ngZone.run(() => this.router.navigateByUrl(url));
  }

  async logout() {
    await this.authClient.revokeAccessToken(); // strongly recommended
    await this.authClient.tokenManager.clear();
    await this.authClient.signOut({
      postLogoutRedirectUri: environment.okta.client.logoutUri,
    });
  }

  login({ username, password }): Promise<void | boolean> {
    return this.authClient.signInWithCredentials({ username, password }).then(async res => {
      if (res.status !== 'SUCCESS') {
        // Added Password Expired condition
        if (res.status === 'PASSWORD_EXPIRED') {
          throw new Error(`PASSWORD_EXPIRED`);
        } else if (res.status !== 'SUCCESS') {
          throw new Error(`Need handle for ${res.status} status`);
        }
      }

      return this.requestTokens(res.sessionToken);
    });
  }

  async getAccessToken(): Promise<AccessToken> {
    try {
      const accessToken: AccessToken = (await this.authClient.tokenManager.get('access_token')) as AccessToken;
      return accessToken;
    } catch (err) {
      this.router.navigateByUrl('auth/sso');
      return null;
    }
  }
  /**
   * Get user information from Okta access token,
   * this information is necessary to Web-To-Case implementations
   * when is required to know which user had sent the case.
   */
  async getUserInfo(): Promise<any> {
    // const token = await this.authClient.tokenManager.get('access_token');
    const accessToken: AccessToken = (await this.authClient.tokenManager.get('accessToken')) as AccessToken;
    return this.authClient.token.getUserInfo(accessToken);
  }

  parseTokensFromURL(): Promise<any> {
    return this.authClient.token.parseFromUrl();
  }

  async refreshToken(): Promise<any> {
    return this.authClient.tokenManager
      .get('access_token')
      .then(token => this.authClient.token.renew(token))
      .then(token => {
        if (!token) throw new Error('Failed to refresh token');
        this.authClient.tokenManager.add('access_token', token);
        return null;
      })
      .catch(err => {
        // handle unauthorized api calls
        if (err.name === 'AuthSdkError') {
          this.store.dispatch({
            type: AuthActionTypes.LOGOUT,
            payload: this.router.url,
          });
          this.logout();
        }
        return err;
      });
  }

  createSessionTimer(expiresAt: string): void {
    if (!expiresAt) {
      return;
    }
    // Parse ISO string date
    const endDate: DateTime = DateTime.fromISO(expiresAt);

    // Create timer using rxjs interval
    this.sessionTimer = interval(1000).pipe(
      map(
        () =>
          endDate
            // Get date diff between session and user local date in minutes and seconds
            .diffNow(['hours', 'minutes', 'seconds'], {
              conversionAccuracy: 'longterm', // ensure time accuracy
            })
            .toObject(), // return { hours: number, minutes: number, seconds: number }
      ),
      takeWhile(time => time.hours >= 0 && time.minutes >= 0 && Math.floor(time.seconds) >= 0),
    );
  }

  setTokens(tokens): void {
    const { idToken, accessToken } = tokens.tokens;
    this.authClient.tokenManager.add('id_token', idToken);
    this.authClient.tokenManager.add('access_token', accessToken);
  }

  private requestTokens(sessionToken?: string): Promise<void> {
    return this.authClient.token.getWithRedirect({
      clientId: environment.okta.client.clientId,
      responseType: ['id_token', 'token'],
      redirectUri: environment.okta.client.redirectUri,
      scopes: [environment.okta.oidc.scopes],
      sessionToken,
    });
  }

  private requestTokensWithoutPrompt(sessionToken: boolean, nonce: string = this.nonce): Promise<any> {
    return this.authClient.token.getWithoutPrompt({
      clientId: environment.okta.client.clientId,
      responseType: ['id_token', 'token'],
      redirectUri: window.location.origin,
      scopes: [environment.okta.oidc.scopes],
      nonce,
    });
  }
}
