import { CartFrames } from '@app/core/models';
import { FramesCartActions, FramesCartActionTypes } from '@app/core/ngrx';

/**
 * FramesCartState
 */
export interface FramesCartState {
  loaded: boolean;
  pending: boolean;
  error: any;
  framesCart: Array<CartFrames>;
}

/**
 * FRAMES_CART_INITIAL_STATE
 * @type {{loaded: boolean; pending: boolean; error: null; profile: null}}
 */
export const FRAMES_CART_INITIAL_STATE: FramesCartState = {
  loaded: false,
  pending: false,
  error: null,
  framesCart: [],
};
/**
 * framesCartReducer
 * @param state
 * @param action
 */
export const framesCartReducer = (
  state: FramesCartState = FRAMES_CART_INITIAL_STATE,
  action: FramesCartActions,
): FramesCartState => {
  switch (action.type) {
    case FramesCartActionTypes.LOAD_FRAMES_CART:
      return {
        ...state,
        error: null,
        loaded: false,
        pending: true,
      };
    case FramesCartActionTypes.LOAD_FRAMES_CART_SUCCESS:
      return {
        ...state,
        loaded: true,
        pending: false,
        framesCart: action.payload['frames'],
      };
    case FramesCartActionTypes.CLEAR_FRAMES_CART:
      return {
        ...state,
        framesCart: [],
      };
    case FramesCartActionTypes.ADD_FRAMES_TO_CART:
      return {
        ...state,
        framesCart: [...state.framesCart, action.payload],
      };
    case FramesCartActionTypes.REMOVE_FRAMES_TO_CART:
      return {
        ...state,
        framesCart: [
          ...state.framesCart.filter((item, i) => i !== action.payload),
        ],
      };
    default:
      return state;
  }
};
