import { Injectable } from '@angular/core';

import { ApiService } from './api.service';
import { HttpClient } from '@angular/common/http';

/**
 * UserService
 */
@Injectable({ providedIn: 'root' })
export class VwService {
  /**
   * url
   */
  public url;

  /**
   * constructor
   * @param {ApiService} apiService
   * @param {HttpClient} http
   */
  constructor(private apiService: ApiService, private http: HttpClient) {}

  getFrameImage(fpc) {
    return this.apiService.get(`/image/imagedata/${fpc}`);
  }
}
