<ng-container *ngIf="!fallback">
  <div class="row">
    <div class="col s12 m12 l6">
      <div class="content__header-container">
        <img class="content__image" [src]="content?.imgSrc" [alt]="content?.title">
        <div class="content__header">
          <h3 class="h3 content__title">{{ content?.title }}</h3>
        </div>
      </div>
    </div>

    <div class="col s12 m12 l6">
      <div class="content__content">
        <h3 class="h3 content__title">{{ content?.title }}</h3>
        <p class="content__desc">{{ content?.description }}</p>
        <a *ngIf="content.linkUrl" [href]="content.linkUrl" rel="noopener noreferrer" class="btn btn-primary">Link Url Title</a>
      </div>
    </div>
  </div>
</ng-container>
