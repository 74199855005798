import { Component, Input, Output } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { Subscription } from 'rxjs';

@Component({
  selector: 'ecp-password-requirements',
  templateUrl: './password-requirements.component.html',
  styleUrls: ['./password-requirements.component.scss'],
})
export class PasswordRequirementsComponent {
  passwordFieldSub: Subscription;
  @Input()
  passwordField: AbstractControl;

  @Input()
  validationList: Array<any>;

  constructor() {}

  checkExpression(validation) {
    if (this.passwordField) {
      let compareRegex = new RegExp(validation.regex, 'g');
      validation.isMet = compareRegex.exec(this.passwordField.value);
      return validation.isMet ? validation.direction : !validation.direction;
    }
    return false;
  }
}
