import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap, takeUntil } from 'rxjs/operators';

import { Router } from '@angular/router';
import { Effect, Actions, ofType, createEffect } from '@ngrx/effects';
import { BulkOrdersActionTypes, AuthActionTypes } from '../actions';
import { BulkOrderService } from '@app/core/services';

/**
 * BulkOrdersEpics
 */
@Injectable()
export class BulkOrdersEffects {
  /**
   * constructor
   * @param {BulkOrdersService} bulkOrderService
   */
  constructor(private bulkOrderService: BulkOrderService, private router: Router, private actions$: Actions) {
  }

  /**
   * loadBulkOrders
   * @param {ActionsObservable<any>} action$
   * @returns {Observable<any>}
   */
  loadBulkOrders = createEffect(() =>
    this.actions$.pipe(
      ofType(BulkOrdersActionTypes.LOAD_BULK_ORDERS),
      switchMap(() => {
        return this.bulkOrderService.getBulkOrders().pipe(
          map(bulkOrder => ({
            type: BulkOrdersActionTypes.LOAD_BULK_ORDERS_SUCCESS,
            payload: bulkOrder.bulkOrders,
          })),
          catchError(err =>
            of({
              type: BulkOrdersActionTypes.LOAD_BULK_ORDERS_ERROR,
              payload: err,
            }),
          ),
        );
      }),
    ),
  );
  loadBulkOrderDetails = createEffect(() =>
    this.actions$.pipe(
      ofType(BulkOrdersActionTypes.LOAD_BULK_ORDER_DETAILS),
      switchMap(({ payload }) => {
        return this.bulkOrderService.getBulkOrderByOrderId(payload).pipe(
          map(bulkOrder => ({
            type: BulkOrdersActionTypes.LOAD_BULK_ORDER_DETAILS_SUCCESS,
            payload: bulkOrder,
          })),
          catchError(err => of({ type: BulkOrdersActionTypes.LOAD_BULK_ORDER_DETAILS_ERROR, payload: err })),
        );
      }),
    ),
  );
  
  loadBulkOrdersReview = createEffect(() =>
    this.actions$.pipe(
      ofType(BulkOrdersActionTypes.LOAD_BULK_ORDERS_REVIEW),
      switchMap(({ payload }) => {
        return this.bulkOrderService.submitBulkOrderReview(payload).pipe(
          map(bulkOrder => ({
            type: BulkOrdersActionTypes.LOAD_BULK_ORDERS_REVIEW_SUCCESS,
            payload: bulkOrder,
          })),
          catchError(err => of({ type: BulkOrdersActionTypes.LOAD_BULK_ORDERS_REVIEW_ERROR, payload: err })),
        );
      }),
    ),
  );

  /**
   * loadBulkOrdersError
   * @param {ActionsObservable<any>} action$
   * @returns {Observable<any>}
   */
  loadBulkOrdersError = createEffect(() =>
    this.actions$.pipe(
      ofType(BulkOrdersActionTypes.LOAD_BULK_ORDERS_ERROR),
      map(() => ({ type: AuthActionTypes.LOGOUT })),
    ),
  );
}
