import { Store, select } from '@ngrx/store';
import {
  Directive,
  Input,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';
import { Observable, Subscription, concat } from 'rxjs';
import { AppState, selectPatientId } from '@app/core/ngrx';

@Directive({
  selector: '[showPatient]',
})
export class PatientDirective implements OnInit, OnDestroy {
  private userLoaded: Observable<boolean>;
  private patientId: Observable<string>;
  private condition: boolean;
  private subscription: Subscription;
  private hidden = true;
  private isPatientIdLoaded = false;
  private isProfileLoaded = false;

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private store: Store<AppState>,
  ) {}

  @Input()
  set showPatient(condition: boolean) {
    this.condition = condition;
    this.updateView();
  }

  ngOnInit(): void {
    this.patientId = this.store.pipe(select(selectPatientId));
    this.userLoaded = this.store.pipe(select(state => state.user.loaded));
    concat(this.userLoaded).subscribe(result => {
      this.isProfileLoaded = result;
      concat(this.patientId).subscribe(result2 => {
        this.isPatientIdLoaded = result2 ? true : false;
        this.updateView();
      });
    });
  }

  updateView(): void {
    if (this.shouldShow()) {
      if (this.hidden) {
        this.viewContainer.createEmbeddedView(this.templateRef);
        this.hidden = false;
      }
    } else {
      this.viewContainer.clear();
      this.hidden = true;
    }
  }

  shouldShow(): boolean {
    return (
      (this.isPatientIdLoaded && this.isProfileLoaded && this.condition) ||
      (!this.isPatientIdLoaded && !this.isProfileLoaded && !this.condition)
    );
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }

    this.viewContainer.clear();
  }
}
