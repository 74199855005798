import {
  Directive,
  ElementRef,
  HostListener,
  Input,
  Renderer2,
  AfterViewInit,
} from '@angular/core';

@Directive({
  selector: '[ecpHover]',
})
export class HoverDirective implements AfterViewInit {
  constructor(private elementRef: ElementRef, private renderer: Renderer2) {}
  @Input() hoverClass: string;
  public element: any;

  ngAfterViewInit() {
    this.element = this.elementRef.nativeElement;
  }

  @HostListener('mouseenter') onMouseEnter() {
    this.renderer.addClass(this.element, this.hoverClass);
  }
  @HostListener('mouseleave') onMouseLeave() {
    this.renderer.removeClass(this.element, this.hoverClass);
  }

  @HostListener('click') onMouseClick() {
    this.renderer.removeClass(this.element, this.hoverClass);
  }

  private highlight(color: string) {
    this.elementRef.nativeElement.style.backgroundColor = color;
  }
}
