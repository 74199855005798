<div class="container" id="catalog">
  <div
    class="row is-flex"
    *ngIf="(totalResults$ | async) === 0 && (!(searchChips$ | async) || (searchChips$ | async)?.length === 0)"
  >
    <div class="col s12">
      <button
        class="btn btn-primary"
        style="width: 100%"
        name="browse entire catalog"
        [disabled]="framesPending$ | async"
        (click)="browseEntireCatalog()"
      >
        Browse Entire Catalog
      </button>
    </div>
  </div>
</div>
<ecp-catalog-filter></ecp-catalog-filter>
<div
  class="row"
  *ngIf="
    (frames$ | async)?.length > 0 ||
    ((searchChips$ | async) && (searchChips$ | async)?.length > 0) ||
    ((searchBrands$ | async) && (searchBrands$ | async)?.length > 0)
  "
>
  <div class="col is-flex is-fullwidth">
    <div class="container container-frames">
      <ecp-search-chips></ecp-search-chips>
      <span class="mb-20"></span>
      <ecp-catalog-frame-list *ngIf="(frames$ | async)?.length > 0"></ecp-catalog-frame-list>

      <button
        class="btn btn-primary is-fullwidth"
        name="browse entire catalog"
        [disabled]="framesPending$ | async"
        (click)="viewMoreFrames()"
        *ngIf="(frames$ | async)?.length > 0"
      >
        View More Frames
      </button>
      <div class="row is-flex" *ngIf="(frames$ | async)?.length === 0 && (framesLoaded$ | async) === true">
        <div class="col s12 text-align-center">
          <h4>There are no more frames matching the above criteria</h4>
          <h1>Sorry, there are no frames matching</h1>
          <h1>
            <span *ngFor="let searchChip of (filter$ | async).searchTerms; let isLast = last">
              {{ searchChip }}{{ isLast ? '' : ', ' }}
            </span>
            <span *ngFor="let frameSize of (filter$ | async).selectedFrameSize; let isLast = last">
              {{ frameSize.name }}{{ isLast ? '' : ', ' }}
            </span>
            <span *ngFor="let brands of (filter$ | async).selectedBrands; let isLast = last">
              {{ brands }}{{ isLast ? '' : ', ' }}
            </span>
          </h1>
        </div>
        <div class="col s12 m6 offset-m3 l4 offset-l4 z-depth-6">
          <h3>Consider this Tips:</h3>
          <ul>
            <li><h4>Check for spelling errors or typos</h4></li>
            <li><h4>Use fewer words</h4></li>
            <li><h4>Search using a different attribute</h4></li>
            <li><h4>Clear all filters to view all frames</h4></li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>
