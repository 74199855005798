import { Component, NgZone, OnInit } from '@angular/core';
import { AuthService } from '../auth.service';
import { Store } from '@ngrx/store';
import { AppState, AuthActionTypes } from '@app/core/ngrx';
import { Router } from '@angular/router';
import { VwService } from '@app/core/services';
import { environment } from '@env/environment';

/**
 * AuthSSOCallbackComponent
 */
@Component({
  selector: 'ecp-sso-callback',
  template: `
    <div class="loader">
      <div class="loader-icon">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  `,
})
export class AuthSSOCallbackComponent implements OnInit {
  /**
   * constructor
   * @param {AuthService} authService
   * @param vwService
   * @param {Store<AppState>} store
   * @param {Router} router
   */
  constructor(
    private _ngZone: NgZone,
    private authService: AuthService,
    private vwService: VwService,
    private store: Store<AppState>,
    private router: Router,
  ) {}

  /**
   * ngOnInit
   */
  ngOnInit(): void {
    this.authService
      .parseTokensFromURL()
      .then(tokens => {
        if (!tokens) throw new Error('SSO Callback: No Tokens parsed');

        this.authService.setTokens(tokens);
        this._ngZone.run(() => {
          this.router.navigateByUrl(window.localStorage.getItem('returnUrl') || environment.okta.authRedirectUrl, {
            replaceUrl: true,
          });
        });

        this.store.dispatch({ type: AuthActionTypes.LOGIN_SUCCESS });
        window.localStorage.removeItem('returnUrl');
      })
      .catch(err => {
        this.store.dispatch({
          type: AuthActionTypes.LOGIN_ERROR,
          payload: err,
        });
        this.navigateAway('/auth/logout');
      });
  }
  private navigateAway(url) {
    this._ngZone.run(() => this.router.navigateByUrl(url, { replaceUrl: true }));
  }
}
