import { ReturnsStatus } from '@app/core/models';
import { ReturnsStatusActions, ReturnsStatusActionTypes } from '@app/core/ngrx';
import { consoleTestResultHandler } from 'tslint/lib/test';

/**
 * FramesCartState
 */
export interface ReturnsStatusState {
  loaded: boolean;
  pending: boolean;
  error: any;
  frameReturns: Array<ReturnsStatus>;
  searchTerm: string;
}

/**
 * FRAMES_CART_INITIAL_STATE
 * @type {{loaded: boolean; pending: boolean; error: null; profile: null}}
 */
export const RETURNS_STATUS_INITIAL_STATE: ReturnsStatusState = {
  loaded: false,
  pending: false,
  error: null,
  frameReturns: [],
  searchTerm: '',
};
/**
 * framesCartReducer
 * @param state
 * @param action
 */
export const returnsStatusReducer = (
  state: ReturnsStatusState = RETURNS_STATUS_INITIAL_STATE,
  action: ReturnsStatusActions,
): ReturnsStatusState => {
  switch (action.type) {
    case ReturnsStatusActionTypes.LOAD_RETURNS_STATUS:
      return {
        ...state,
        error: null,
        loaded: false,
        pending: true,
      };
    case ReturnsStatusActionTypes.LOAD_RETURNS_STATUS_SUCCESS:
      return {
        ...state,
        loaded: true,
        pending: false,
        frameReturns: action.payload['ecpReturns'],
      };

    case ReturnsStatusActionTypes.SEARCH_RETURNS_STATUS:
      return { ...state, searchTerm: action.payload };
    default:
      return state;
  }
};
