import { Framesize } from '@app/core/models/frame-size.model';
import { CatalogActions, CatalogActionTypes } from '../actions';

export interface CatalogState {
  filter: Filter;
}

export class Filter {
  searchTerms?: Array<string>;
  selectedBrands?: Array<string>;
  selectedFrameSize?: Array<Framesize>;
  skip?: number;
  isSearch: boolean;
}

export const CATALOG_INITIAL_STATE: CatalogState = {
  filter: {
    searchTerms: null,
    selectedBrands: null,
    selectedFrameSize: null,
    skip: null,
    isSearch: false,
  },
};

export const catalogReducer = (
  state: CatalogState = CATALOG_INITIAL_STATE,
  action: CatalogActions,
): CatalogState => {
  switch (action.type) {
    case CatalogActionTypes.ADD_SEARCH_CHIP:
      return {
        ...state,
        filter: {
          ...state.filter,
          searchTerms: state.filter.searchTerms
            ? [...state.filter.searchTerms, action.payload]
            : [action.payload],
          skip: 0,
          isSearch: true,
        },
      };
    case CatalogActionTypes.REMOVE_SEARCH_CHIP:
      return {
        ...state,
        filter: {
          ...state.filter,
          searchTerms: state.filter.searchTerms.filter(term => {
            return action.payload !== term;
          }),
          skip: 0,
          isSearch: true,
        },
      };
    case CatalogActionTypes.CLEAR_SEARCH_CHIP:
      return {
        ...state,
        filter: {
          ...state.filter,
          searchTerms: [],
          skip: 0,
          isSearch: true,
        },
      };
    case CatalogActionTypes.LOAD_SELECTED_FRAMESIZE:
      return {
        ...state,
        filter: {
          ...state.filter,
          selectedFrameSize: action.payload,
          skip: 0,
          isSearch: true,
        },
      };
    case CatalogActionTypes.REMOVE_SELECTED_FRAMESIZE:
      return {
        ...state,
        filter: {
          ...state.filter,
          selectedFrameSize: state.filter.selectedFrameSize.filter(
            framesize => {
              return action.payload !== framesize;
            },
          ),
          skip: 0,
          isSearch: true,
        },
      };
    case CatalogActionTypes.CLEAR_SELECTED_FRAMESIZE:
      return {
        ...state,
        filter: {
          ...state.filter,
          selectedFrameSize: [],
          skip: 0,
          isSearch: true,
        },
      };
    case CatalogActionTypes.LOAD_SELECTED_BRANDS:
      return {
        ...state,
        filter: {
          ...state.filter,
          selectedBrands: action.payload,
          skip: 0,
          isSearch: true,
        },
      };
    case CatalogActionTypes.REMOVE_SELECTED_BRANDS:
      return {
        ...state,
        filter: {
          ...state.filter,
          selectedBrands: state.filter.selectedBrands.filter(brands => {
            return action.payload !== brands;
          }),
          skip: 0,
          isSearch: true,
        },
      };
    case CatalogActionTypes.CLEAR_SELECTED_BRANDS:
      return {
        ...state,
        filter: {
          ...state.filter,
          selectedBrands: [],
          skip: 0,
          isSearch: true,
        },
      };
    case CatalogActionTypes.CLEAR_ALL_FILTERS:
      return {
        ...state,
        filter: {
          ...state.filter,
          selectedBrands: [],
          selectedFrameSize: [],
          searchTerms: [],
          skip: 0,
          isSearch: true,
        },
      };
    case CatalogActionTypes.LOAD_MORE_FRAMES:
      return {
        ...state,
        filter: {
          ...state.filter,
          skip: (state.filter.skip || 16) + 16,
          isSearch: true,
        },
      };
    case CatalogActionTypes.HALT_SEARCH:
      return {
        ...state,
        filter: {
          ...state.filter,
          isSearch: false,
        },
      };
    default:
      return state;
  }
};
