<div [class]="theme ? theme : 'one-portal'">
  <button class="mat-dialog-close" mat-dialog-close>
    <mat-icon svgIcon="close"></mat-icon>
  </button>

  <!-- Dialog: Header -->
  <div class="mat-dialog-header">
    <h2>Login</h2>
    <hr/>
    <small>*Required field</small>
  </div>

  <!-- Dialog: Content -->
  <div class="mat-dialog-body">
    <ecp-login-form></ecp-login-form>
    <p class="text-align-left">Your practice must be registered in order to gain access to the MyFrameBoard® Portal. If you would like to register or are unable to log in for any reason, <a routerLink="/contact-us">Contact Us</a>.</p>
  </div>
</div>
