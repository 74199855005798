import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { HttpClient } from '@angular/common/http';
import { Catalog } from '@app/core/models/catalog.model';
import { ApiService } from '@app/core/services/api.service';
import { State } from '@ngrx/store';
import { AppState } from '@app/core/ngrx';

/**
 * BrandsService
 */
@Injectable({ providedIn: 'root' })
export class SearchService {
  get skip(): number {
    return this._skip;
  }

  set skip(value: number) {
    this._skip = value;
  }
  private _skip = 0;
  /**
   * url
   */
  public url;
  /**
   * constructor
   * @param {ApiFdService} apiService
   * @param {HttpClient} http
   */
  constructor(private apiService: ApiService, private http: HttpClient, private state: State<AppState>) {}

  /**
   * getFrames
   * @returns {Observable<getFrames>}
   */
  getFrames(data: Object = {}): Observable<Catalog> {
    const options = {
      headers: {
        programKey: this.state.getValue().user.profile.program.programKey,
      },
    };
    return this.apiService.post('/catalog/search', data, options);
  }

  /**
   * searchFrames
   */
  searchFrames(params: Object = {}): Observable<Catalog> {
    const options = {
      headers: {
        programKey: this.state.getValue().user.profile.program.programKey,
      },
    };
    return this.apiService.post('/catalog/search', params, options);
  }

  /**
   * searchFrames
   */
  exportBrandPlanogram(params: Object = {}): Observable<Catalog> {
    const options = {
      headers: {
        programKey: this.state.getValue().user.profile.program.programKey,
      },
    };
    return this.apiService.post('/catalog/download', params, options);
  }

  /**
   * loadMoreFrames
   */
  loadMoreFrames(): Observable<Catalog> {
    const options = {
      headers: {
        programKey: this.state.getValue().user.profile.program.programKey,
      },
    };
    this.skip += 16;
    const data: Object = { terms: [], brands: [], sizes: [], skip: this.skip };
    return this.apiService.post('/catalog/search', data, options);
  }

  /**
   * loadRelatedFrames
   */
  loadRelatedFrames(upc): Observable<Catalog> {
    const options = {
      headers: {
        programKey: this.state.getValue().user.profile.program.programKey,
      },
    };
    return this.apiService.get(`/catalog/related-frames/${upc}`, options);
  }

  /**
   * loadPatientRelatedFrames
   */
  loadPatientRelatedFrames(upc, patientId): Observable<Catalog> {
    const options = {
      headers: {
        programKey: this.state.getValue().user.profile.program.programKey,
      },
    };
    return this.apiService.get(`/catalog/related-frames/${patientId}/${upc}`, options);
  }

  /**
   * searchPatientFrames
   */
  searchPatientFrames(code: string, params: Object = {}): Observable<Catalog> {
    return this.apiService.post(`/catalog/search/${code}`, params);
  }
}
