import { Component, NgZone, OnInit } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { Store, select } from '@ngrx/store';
import { AppState, AuthActionTypes, selectProfile, UserActionTypes } from '@app/core/ngrx';
import { AuthService } from '@app/auth/auth.service';
import { Router } from '@angular/router';

/**
 * LoginStaticFormComponent
 */
@Component({
  selector: 'ecp-login-form',
  templateUrl: './login-form.component.html',
  styleUrls: ['./login-form.component.scss'],
})
export class LoginFormComponent implements OnInit {
  /**
   * loginForm
   */
  loginForm: FormGroup;
  /**
   * error$
   */
  // @select(['auth', 'error'])
  error$: Observable<any>;
  /**
   * pending$
   */
  // @select(['auth', 'pending'])
  pending$: Observable<boolean>;
  /**
   * profile$
   */
  // @select(['user', 'profile'])
  profile$: Observable<any>;
  /**
   * rememberMeFlag
   */
  rememberMeFlag;

  /**
   * constructor
   * @param {NgRedux<AppState>} redux
   * @param {AuthService} authService
   * @param {Router} router
   * @param {AuthStaticService} loginService
   */
  constructor(
    private _ngZone: NgZone,
    private store: Store<AppState>,
    private authService: AuthService,
    private router: Router,
    private loginService: AuthService,
  ) {}

  /**
   * ngOnInit
   */
  ngOnInit(): void {
    this.error$ = this.store.pipe(select(state => state.auth.error));
    this.pending$ = this.store.pipe(select(state => state.auth.pending));
    this.profile$ = this.store.pipe(select(selectProfile));
    if (window.localStorage.getItem('rememberMe') === 'false') {
      window.localStorage.removeItem('rememberMe');
    } else {
      this.rememberMeFlag = window.localStorage.getItem('rememberMe');
    }
    this.loginForm = this.createForm(window.localStorage.getItem('username'));
  }

  /**
   * username
   * @returns {AbstractControl}
   */
  get username(): AbstractControl {
    return this.loginForm.get('username');
  }

  /**
   * password
   * @returns {AbstractControl}
   */
  get password(): AbstractControl {
    return this.loginForm.get('password');
  }

  /**
   * createForm
   * @returns {FormGroup}
   */
  createForm(pUsername?: string): FormGroup {
    const username = new FormControl(pUsername, {
      validators: Validators.compose([Validators.required]),
    });

    const password = new FormControl('', {
      validators: Validators.compose([Validators.required, Validators.minLength(1)]),
    });

    return new FormGroup({ username, password }, { updateOn: 'submit' });
  }

  /**
   * handleRememberMe
   * @description Remember Me sets the local storage to remember the value the user puts into the username field
   * @param checked
   */
  handleRememberMe(checked: string): void {
    this.rememberMeFlag = checked;
    window.localStorage.setItem('rememberMe', checked);
  }
  onSubmit() {
    if (this.loginForm.valid) {
      // check for remember me and set the localstorage
      if (this.rememberMeFlag) {
        window.localStorage.setItem('username', this.loginForm.get('username').value.toString());
      } else {
        window.localStorage.removeItem('username');
      }
      this.store.dispatch({ type: AuthActionTypes.LOGIN });

      this.authService.login(this.loginForm.value).catch(err => {
        if (err.toString().indexOf('PASSWORD_EXPIRED') >= 1) {
          this.store.dispatch({
            type: AuthActionTypes.LOGIN_ERROR,
            payload: null,
          });
          this._ngZone.run(() => this.router.navigateByUrl(`reset-password/${this.username.value}`));
        } else {
          console.log('>>>> ERROR => err');
          this.store.dispatch({
            type: AuthActionTypes.LOGIN_ERROR,
            payload: err,
          });
          this.router.navigateByUrl('/auth/logout');
        }
      });
    }

    return false;
  }
  /**
   * onSubmit
   * @returns {boolean}
   */
  // onSubmit() {
  //   // check for remember me and set the localstorage
  //   if (window.localStorage.getItem('rememberMe')) {
  //     console.log('set the username value in local storage.');
  //     console.log(
  //       '> onSubmit - username => ',
  //       this.loginForm.get('username').value,
  //     );
  //     window.localStorage.setItem(
  //       'username',
  //       this.loginForm.get('username').value.toString(),
  //     );
  //   }
  //   if (this.loginForm.valid) {
  //     this.redux.dispatch({ type: AuthActionTypes.LOGIN });
  //     this.loginService.getAuthorization(this.loginForm.value).subscribe(
  //       (res: User) => {
  //         console.log('this.loginService.getAuthorization - res => ', res);
  // this.profile$.subscribe(profile => {
  //   console.log('LOADED PROFILE => ', profile);
  //   this.router.navigateByUrl(
  //     window.localStorage.getItem('returnUrl') || 'dashboard',
  //     { replaceUrl: true },
  //   );
  //   console.log('> After Navigate by URL');
  //   window.localStorage.removeItem('returnUrl');
  // });
  //       },
  //       err => {
  //         this.redux.dispatch({
  //           type: AuthActionTypes.LOGIN_ERROR,
  //           payload: err,
  //         });
  //         this.router.navigateByUrl('', { replaceUrl: true });
  //       },
  //     );
  //   }
  //
  //   return false;
  // }
}
