import { FramesActions, FramesActionTypes } from '../actions';

export interface FrameDetailsState {
  loaded: boolean;
  pending: boolean;
  frame: any;
  error: any;
}

export const FRAME_DETAILS_INITIAL_STATE = {
  loaded: false,
  pending: false,
  frame: null,
  error: null,
};

export const frameDetailsReducer = (
  state: FrameDetailsState = FRAME_DETAILS_INITIAL_STATE,
  action: FramesActions,
): FrameDetailsState => {
  switch (action.type) {
    case FramesActionTypes.LOAD_FRAME_DETAILS:
      return {
        ...state,
        loaded: false,
        pending: true,
      };
    case FramesActionTypes.LOAD_FRAME_DETAILS_SUCCESS:
      return {
        ...state,
        loaded: true,
        pending: false,
        frame: action.payload,
        error: null,
      };
    case FramesActionTypes.LOAD_FRAME_DETAILS_ERROR:
      return {
        ...state,
        loaded: false,
        pending: false,
        frame: null,
        error: action.payload,
      };
    default:
      return state;
  }
};
