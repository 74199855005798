import { FrameToComeActions, FrameToComeActionTypes } from '@app/core/ngrx';

/**
 * FramesCartState
 */
export interface FrameToComeOrderState {
  loaded: boolean;
  pending: boolean;
  error: any;
  orders: Array<any>;
}

/**
 * FRAMES_CART_INITIAL_STATE
 * @type {{loaded: boolean; pending: boolean; error: null; profile: null}}
 */
export const FRAME_TO_COME_ORDER_INITIAL_STATE: FrameToComeOrderState = {
  loaded: false,
  pending: false,
  error: null,
  orders: [],
};
/**
 * framesCartReducer
 * @param state
 * @param action
 */
export const frameToComeOrderReducer = (
  state: FrameToComeOrderState = FRAME_TO_COME_ORDER_INITIAL_STATE,
  action: FrameToComeActions,
): FrameToComeOrderState => {
  switch (action.type) {
    case FrameToComeActionTypes.LOAD_FRAME_TO_COME_ORDERS:
      return {
        ...state,
        error: null,
        loaded: false,
        pending: true,
      };
    case FrameToComeActionTypes.LOAD_FRAME_TO_COME_ORDERS_SUCCESS:
      return {
        ...state,
        loaded: true,
        pending: false,
        orders: action.payload,
      };
    case FrameToComeActionTypes.LOAD_FRAME_TO_COME_ORDERS_ERROR:
      return { ...state, error: action.payload, loaded: false, pending:false};
    default:
      return state;
  }
};
