import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { MyNotifications } from '@app/core/models';
import { ApiService } from '@app/core/services/api.service';
import { State } from '@ngrx/store';
import { AppState } from '@app/core/ngrx';

/**
 * MyNotificationsService
 */
@Injectable({ providedIn: 'root' })
export class MyNotificationsService {
  get notifiedFlag(): boolean {
    return this._notifiedFlag;
  }

  set notifiedFlag(value: boolean) {
    this._notifiedFlag = value;
  }
  private _notifiedFlag = false;
  /**
   * url
   */
  public url;

  /**
   * constructor
   * @param apiService
   * @param redux
   */
  constructor(private apiService: ApiService, private state: State<AppState>) {}

  /**
   * getMyNotifications
   * @returns {Observable<MyNotiifcation[]>}
   */
  getMyNotifications(): Observable<MyNotifications[]> {
    const options = {
      headers: {
        programKey: this.state.getValue().user.profile.program.programKey,
      },
    };
    return this.apiService.get('/notification', options);
  }
}
