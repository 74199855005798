import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap, takeUntil } from 'rxjs/operators';

import { Router } from '@angular/router';
import { FrameReturnsService } from '@app/core/services/frame-returns.service';
import { Actions, Effect, ofType, createEffect } from '@ngrx/effects';
import { ReturnsStatusActionTypes, AuthActionTypes } from '../actions';

/**
 * MyCollectionEpics
 */
@Injectable()
export class ReturnsStatusEffects {
  /**
   * constructor
   * @param {FrameReturnsService} frameReturnsService
   */
  constructor(
    private frameReturnsService: FrameReturnsService,
    private actions$: Actions,
    private router: Router,
  ) {}

  /**
   * loadFrameReturns
   * @param {ActionsObservable<any>} action$
   * @returns {Observable<any>}
   */
  loadFrameReturns = createEffect(() =>
    this.actions$.pipe(
      ofType(ReturnsStatusActionTypes.LOAD_RETURNS_STATUS),
      switchMap(() => {
        return this.frameReturnsService.GetFrameReturns().pipe(
          map(returns => ({
            type: ReturnsStatusActionTypes.LOAD_RETURNS_STATUS_SUCCESS,
            payload: returns,
          })),
          catchError(err =>
            of({
              type: ReturnsStatusActionTypes.LOAD_RETURNS_STATUS_ERROR,
              payload: err,
            }),
          ),
        );
      }),
    ),
  );

  /**
   * loadMyCollectionError
   * @param {ActionsObservable<any>} action$
   * @returns {Observable<any>}
   */
  loadMyCollectionError = createEffect(() =>
    this.actions$.pipe(
      ofType(ReturnsStatusActionTypes.LOAD_RETURNS_STATUS_ERROR),
      map(() => ({ type: AuthActionTypes.LOGOUT })),
    ),
  );
}
