import { Media } from '@app/core/models';
import { MediaActions, MediaActionTypes } from '../actions';

/**
 * MediaState
 */
export interface MediaState {
  loaded: boolean;
  pending: boolean;
  error: any;
  list: Media;
}

/**
 * MEDIA_INITIAL_STATE
 * @type {{loaded: boolean; pending: boolean; error: null; profile: null}}
 */
export const MEDIA_INITIAL_STATE: MediaState = {
  loaded: false,
  pending: false,
  error: null,
  list: null,
};
/**
 * mediaReducer
 * @param {MediaState} state
 * @param {MediaActions} action
 * @returns {MediaState}
 */
export const mediaReducer = (
  state: MediaState = MEDIA_INITIAL_STATE,
  action: MediaActions,
): MediaState => {
  switch (action.type) {
    case MediaActionTypes.LOAD_MEDIA:
      return {
        ...state,
        error: null,
        loaded: false,
        pending: true,
      };
    case MediaActionTypes.LOAD_MEDIA_SUCCESS:
      return {
        ...state,
        loaded: true,
        pending: false,
        list: { ...state.list, ...action.payload },
      };
    case MediaActionTypes.LOAD_MEDIA_ERROR:
      return { ...state, error: action.payload };
    default:
      return state;
  }
};
