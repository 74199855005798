import { Component, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup } from '@angular/forms';
import {
  AppState,
  CatalogActionTypes,
  Filter,
  FramesActionTypes,
} from '@app/core/ngrx';
import { Observable } from 'rxjs/internal/Observable';
import { Frame } from '@app/core/models';
import { Store, select, State } from '@ngrx/store';

@Component({
  selector: 'ecp-catalog-filter',
  templateUrl: './catalog-filter.component.html',
  styleUrls: ['./catalog-filter.component.scss'],
})
export class CatalogFilterComponent implements OnInit, OnDestroy {
  sizeFilters: Array<FilterValue> = [
    { min: 42, max: 50, name: 'Narrow (42-50mm)', value: '1' },
    { min: 48, max: 54, name: 'Medium (48-54mm)', value: '2' },
    { min: 52, max: 63, name: 'Wide (52-63mm)', value: '3' },
  ];
  public brands$: Observable<any>;
  filter$: Observable<Filter>;
  public frames$: Observable<Frame[]>;

  eyeSize: any = [];

  searchTerms: any;
  filterSubscription: any;

  constructor(
    private store: Store<AppState>,
    private fb: FormBuilder,
    private state: State<AppState>,
  ) {}

  CatalogFilter: FormGroup;

  get frameFilter(): AbstractControl {
    return this.CatalogFilter.get('frameFilter');
  }

  get brandArray(): AbstractControl {
    return this.CatalogFilter.get('brandArray');
  }

  ngOnInit(): void {
    this.brands$ = this.store.pipe(select(state => state.brands.list.brands));
    this.filter$ = this.store.pipe(select(state => state.catalog.filter));
    this.frames$ = this.store.pipe(select(state => state.frames.list.frames));

    this.filterSubscription = this.filter$.subscribe(filter => {
      if (!this.CatalogFilter) {
        this.CatalogFilter = this.fb.group({
          frameFilter: [[]],
          brandArray: [[]],
        });
      }

      this.CatalogFilter.reset();

      this.frameFilter.patchValue(filter.selectedFrameSize);
      this.brandArray.patchValue(filter.selectedBrands);

      if (
        (filter.selectedFrameSize ||
          filter.selectedBrands ||
          filter.searchTerms ||
          filter.skip !== null) &&
        filter.isSearch
      ) {
        let payload = {
          terms: filter.searchTerms || [],
          brands: filter.selectedBrands || [],
          sizes: filter.selectedFrameSize || [],
          skip: filter.skip ? filter.skip - 16 : 0,
        };
        if (this.state.getValue().user.patientID) {
          this.store.dispatch({
            type: FramesActionTypes.LOAD_PATIENT_FRAME_SEARCH,
            payload: {
              filters: payload,
              id: this.state.getValue().user.patientID,
            },
          });
        } else {
          this.store.dispatch({
            type: FramesActionTypes.LOAD_FRAME_SEARCH,
            payload: payload,
          });
        }
      }
    });
  }

  ngOnDestroy(): void {
    if (this.filterSubscription) this.filterSubscription.unsubscribe();
  }

  checkBrandList() {
    this.store.dispatch({
      type: FramesActionTypes.LOAD_SELECTED_BRANDS,
      payload: this.brandArray.value,
    });
  }

  checkFilterSize() {
    let index = this.CatalogFilter.controls.frameFilter.value;

    this.store.dispatch({
      type: CatalogActionTypes.LOAD_SELECTED_FRAMESIZE,
      payload: index,
    });
  }

  compareValues(o1: any, o2: any) {
    try {
      if (o1.value === o2.value) {
        return true;
      } else {
        return false;
      }
    } catch (e) {
      // do nothing
    }
  }
}

class FilterValue {
  min: number;
  max: number;
  name: string;
  value: string;
}
