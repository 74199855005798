import { Component, Output, EventEmitter } from '@angular/core';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ValidatePasswordFPO } from '../../validators/password.fpo.validator';

@Component({
  selector: 'ecp-reset-password-form',
  templateUrl: './reset-password-form.component.html',
  styleUrls: ['./reset-password-form.component.scss'],
})
export class ResetPasswordFormComponent {
  hideTemp: boolean;
  hide: boolean;
  hideNew: boolean;
  hideConfirm: boolean;
  @Output() submit: EventEmitter<any> = new EventEmitter();
  @Output() passwordFocused: EventEmitter<any> = new EventEmitter();

  public form: FormGroup = new FormGroup({
    tempPassword: new FormControl('', {
      validators: Validators.compose([Validators.required]),
    }),

    password: new FormControl('', {
      validators: Validators.compose([
        Validators.required,
        ValidatePasswordFPO,
      ]),
    }),

    passwordConfirm: new FormControl('', {
      validators: Validators.compose([Validators.required]),
    }),
  });

  onPasswordFocused($event) {
    this.passwordFocused.emit($event.returnValue);
  }

  get tempPassword(): AbstractControl {
    return this.form.get('tempPassword');
  }

  get password(): AbstractControl {
    return this.form.get('password');
  }

  get passwordConfirm(): AbstractControl {
    return this.form.get('passwordConfirm');
  }

  handleSubmit(event): void {
    this.submit.emit(event);
  }
}
