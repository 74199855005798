import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpParams,
  HttpErrorResponse,
  HttpHeaders,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from '@env/environment';
import { Store } from '@ngrx/store';
import { AppState } from '@app/core/ngrx';

/**
 * ApiService
 */
@Injectable({ providedIn: 'root' })
export class ApiService {
  /**
   * options
   * @type {{headers: {'Content-Type': string}}}
   */
  private options = {
    headers: {
      'Content-Type': 'application/json',
    },
  };

  /**
   * constructor
   * @param {HttpClient} http
   */
  constructor(private http: HttpClient, private store: Store<AppState>) {}

  /**
   * formatErrors
   * @param {HttpErrorResponse} err
   * @returns {Observable<never>}
   */
  private formatErrors(err: HttpErrorResponse): Observable<never> {
    return throwError(err);
  }

  /**
   * get
   * @param {string} path
   * @param {HttpParams} params
   * @returns {Observable<any>}
   */
  get(path: string, options: object = {}): Observable<any> {
    return this.http
      .get(`${environment.apiUrl}${path}`, {
        ...this.options,
        ...options,
      })
      .pipe(catchError(this.formatErrors));
  }

  /**
   * put
   * @param {string} path
   * @param {object} body
   * @param {object} options
   * @returns {Observable<any>}
   */
  put(path: string, body: object = {}, options: object = {}): Observable<any> {
    return this.http
      .put(`${environment.apiUrl}${path}`, JSON.stringify(body), {
        ...this.options,
        ...options,
      })
      .pipe(catchError(this.formatErrors));
  }

  /**
   * post
   * @param {string} path
   * @param {object} body
   * @param {object} options
   * @returns {Observable<any>}
   */
  post(path: string, body: any = {}, options: object = {}): Observable<any> {
    return this.http
      .post(`${environment.apiUrl}${path}`, JSON.stringify(body), {
        ...this.options,
        ...options,
      })
      .pipe(catchError(this.formatErrors));
  }

  /**
   * delete
   * @param {string} path
   * @returns {Observable<any>}
   */
  delete(path: string, options: object = {}): Observable<any> {
    return this.http
      .delete(`${environment.apiUrl}${path}`,{
        ...this.options,
        ...options
      })
      .pipe(catchError(this.formatErrors));
  }
}
