import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';
import { ApiService } from '@app/core/services/api.service';
import { of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ShoppingCartService {
  constructor(private http: HttpClient, private apiService: ApiService) {}

  submitFrameOrder(payload, patientId) {
    return this.http.post(`${environment.apiUrl}/MyCart/${patientId}`, payload);
  }

  deleteCart(cartId) {
    return this.apiService.delete(`/MyCart/${cartId}`);
  }

  getPatientCarts() {
    return this.apiService.get(`/MyCart`);
  }
}
