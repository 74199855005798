/**
 * BulkOrdersDetailsState
 */

import { BulkOrdersActionTypes, BulkOrdersActions } from "../actions";


export interface BulkOrdersDetailsState {
  loaded: boolean;
  pending: boolean;
  error: any;
  order: any;
}

/**
 * MY_ORDERS_INITIAL_STATE
 * @type {{loaded: boolean; pending: boolean; error: null; profile: null}}
 */
export const BULK_ORDERS_DETAILS_INITIAL_STATE: BulkOrdersDetailsState = {
  loaded: false,
  pending: false,
  error: null,
  order: null,
};
/**
 * bulkOrdersReducer
 * @param {BulkOrdersDetailsState} state
 * @param {BulkOrderActions} action
 * @returns {BulkOrdersDetailsState}
 */
export const bulkOrdersDetailsReducer = (
  state: BulkOrdersDetailsState = BULK_ORDERS_DETAILS_INITIAL_STATE,
  action: BulkOrdersActions,
): BulkOrdersDetailsState => {
  switch (action.type) {
    case BulkOrdersActionTypes.LOAD_BULK_ORDER_DETAILS:
      return {
        ...state,
        error: null,
        loaded: false,
        pending: true,
      };
    case BulkOrdersActionTypes.LOAD_BULK_ORDER_DETAILS_SUCCESS:
      return {
        ...state,
        loaded: true,
        pending: false,
        order: action.payload,
      };
    case BulkOrdersActionTypes.LOAD_BULK_ORDER_DETAILS_ERROR:
      return { ...state, error: action.payload };
    default:
      return state;
  }
};
