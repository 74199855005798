import { Store, select } from '@ngrx/store';
import {
  Directive,
  Input,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';
import { Observable, Subscription, concat } from 'rxjs';
import { AppState } from '@app/core/ngrx';

@Directive({
  selector: '[showAuthed]',
})
export class AuthedDirective implements OnInit, OnDestroy {
  // @select(['auth', 'authenticated'])
  private authenticated: Observable<boolean>;

  // @select(['user', 'loaded'])
  private userLoaded: Observable<boolean>;

  private condition: boolean;
  private subscription: Subscription;
  private hidden = true;
  private isAuthenticated = false;
  private isProfileLoaded = false;

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private store: Store<AppState>,
  ) {}

  @Input()
  set showAuthed(condition: boolean) {
    this.condition = condition;
    this.updateView();
  }

  ngOnInit(): void {
    this.authenticated = this.store.pipe(
      select(state => state.auth.authenticated),
    );
    this.userLoaded = this.store.pipe(select(state => state.user.loaded));
    concat(this.authenticated).subscribe(result => {
      this.isAuthenticated = result;
      concat(this.userLoaded).subscribe(result2 => {
        this.isProfileLoaded = result2;
        this.updateView();
      });
    });
  }

  updateView(): void {
    if (this.shouldShow()) {
      if (this.hidden) {
        this.viewContainer.createEmbeddedView(this.templateRef);
        this.hidden = false;
      }
    } else {
      this.viewContainer.clear();
      this.hidden = true;
    }
  }

  shouldShow(): boolean {
    return (
      (this.isAuthenticated && this.isProfileLoaded && this.condition) ||
      (!this.isAuthenticated && !this.isProfileLoaded && !this.condition)
    );
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }

    this.viewContainer.clear();
  }
}
