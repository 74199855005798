import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { OK, UNAUTHORIZED } from 'http-status-codes';

import { AuthService } from '@app/auth/auth.service';
import { NotificationService } from '@app/core/services';
import { of } from 'rxjs/internal/observable/of';
import { environment } from '@env/environment';

/**
 * HttpErrorInterceptor
 */
@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
  static readonly DEFAULT_ERROR_MESSAGE: any = {
    title: 'We’re sorry!',
    content: 'There appears to be a problem—please try again later.',
    confirmBtnText: 'Close',
  };
  static readonly DEFAULT_PWRESET_ERROR_MESSAGE: any = {
    title: 'We’re sorry!',
    content:
      'There appears to be a problem trying to reset your password. Please check your temporary password and your new password. The new password must match and cant be the same as the temp password.',
    confirmBtnText: 'Close',
  };
  /**
   * constructor
   * @param {AuthService} authService
   */
  constructor(private authService: AuthService, private notifier: NotificationService) {}

  /**
   * intercept
   * @param {HttpRequest<any>} req
   * @param {HttpHandler} next
   * @returns {Observable<any>}
   */
  intercept(req: HttpRequest<any>, next: HttpHandler) {
    // console.log('* HttpErrorInterceptor *');
    // console.log('* HttpErrorInterceptor - request.url => ', req.url);
    return next.handle(req).pipe(
      catchError((err: HttpErrorResponse) => {
        if (err.status === UNAUTHORIZED) {
          return this.authService.refreshToken();
        } else {
          if (err.status !== OK) {
            if (req.url.endsWith('/user/resetpassword')) {
              this.notifier.error(HttpErrorInterceptor.DEFAULT_PWRESET_ERROR_MESSAGE);
              return throwError(err);
            } else {
              if (!req.url.endsWith('/1003') && !req.url.includes('/catalog/rba/') && !req.url.includes('.svg')) {
                console.log('HttpErrorInterceptor REQ => ', req);
                console.log('HttpErrorInterceptor next => ', next);
                if (!req.body?.success) {
                  // do custom msg
                  return throwError(err);
                } else {
                  this.notifier.error(HttpErrorInterceptor.DEFAULT_ERROR_MESSAGE);
                  return throwError(err);
                }
              }
            }
          } else {
            // for some reason the API error is thrown...
            return throwError('OK');
          }
        }
      }),
    );
  }
}
