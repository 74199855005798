/**
 * BulkOrdersReviewState
 */

import { BulkOrdersActionTypes, BulkOrdersActions } from "../actions";


export interface BulkOrdersReviewState {
  loaded: boolean;
  pending: boolean;
  error: any;
  order: any;
}

/**
 * MY_ORDERS_INITIAL_STATE
 * @type {{loaded: boolean; pending: boolean; error: null; profile: null}}
 */
export const BULK_ORDERS_REVIEW_INITIAL_STATE: BulkOrdersReviewState = {
  loaded: false,
  pending: false,
  error: null,
  order: null,
};
/**
 * bulkOrdersReducer
 * @param {BulkOrdersReviewState} state
 * @param {BulkOrderActions} action
 * @returns {BulkOrdersReviewState}
 */
export const bulkOrdersReviewReducer = (
  state: BulkOrdersReviewState = BULK_ORDERS_REVIEW_INITIAL_STATE,
  action: BulkOrdersActions,
): BulkOrdersReviewState => {
  switch (action.type) {
    case BulkOrdersActionTypes.LOAD_BULK_ORDERS_REVIEW:
      return {
        ...state,
        error: null,
        loaded: false,
        pending: true,
      };
    case BulkOrdersActionTypes.LOAD_BULK_ORDERS_REVIEW_SUCCESS:
      return {
        ...state,
        loaded: true,
        pending: false,
        order: action.payload,
      };
    case BulkOrdersActionTypes.LOAD_BULK_ORDERS_REVIEW_ERROR:
      return { ...state, error: action.payload, order: null };
    default:
      return state;
  }
};
