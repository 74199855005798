import { ReturnFrames } from '@app/core/models';
import { FrameReturnsActions, FrameReturnsActionTypes } from '@app/core/ngrx';

/**
 * FramesCartState
 */
export interface FrameReturnsState {
  loaded: boolean;
  pending: boolean;
  error: any;
  frameReturns: Array<ReturnFrames>;
}

/**
 * FRAMES_CART_INITIAL_STATE
 * @type {{loaded: boolean; pending: boolean; error: null; profile: null}}
 */
export const FRAME_RETURNS_INITIAL_STATE: FrameReturnsState = {
  loaded: false,
  pending: false,
  error: null,
  frameReturns: [],
};
/**
 * framesCartReducer
 * @param state
 * @param action
 */
export const frameReturnsReducer = (
  state: FrameReturnsState = FRAME_RETURNS_INITIAL_STATE,
  action: FrameReturnsActions,
): FrameReturnsState => {
  switch (action.type) {
    case FrameReturnsActionTypes.LOAD_FRAME_RETURNS:
      return {
        ...state,
        error: null,
        loaded: false,
        pending: true,
      };
    case FrameReturnsActionTypes.LOAD_FRAME_RETURNS_SUCCESS:
      return {
        ...state,
        loaded: true,
        pending: false,
        frameReturns: action.payload['frames'],
      };
    case FrameReturnsActionTypes.CLEAR_FRAME_RETURNS:
      return {
        ...state,
        frameReturns: [],
      };
    case FrameReturnsActionTypes.ADD_FRAME_TO_RETURNS:
      return {
        ...state,
        frameReturns: [...state.frameReturns, action.payload],
      };
    case FrameReturnsActionTypes.REMOVE_FRAME_FROM_RETURNS:
      return {
        ...state,
        frameReturns: [
          ...state.frameReturns.filter((item, i) => i !== action.payload),
        ],
      };
    default:
      return state;
  }
};
