import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap, takeUntil } from 'rxjs/operators';

import { Router } from '@angular/router';
import { MyNotificationsService } from '@app/core/services';
import { Actions, Effect, ofType, createEffect } from '@ngrx/effects';
import { MyNotificationsActionTypes, AuthActionTypes } from '../actions';

/**
 * MyNotificationsEpics
 */
@Injectable()
export class MyNotificationsEffects {
  /**
   * constructor
   * @param {MyNotificationsService} myNotificationsService
   */
  constructor(
    private myNotificationsService: MyNotificationsService,
    private actions$: Actions,
    private router: Router,
  ) {}

  /**
   * loadMyNotifications
   * @param {ActionsObservable<any>} action$
   * @returns {Observable<any>}
   */
  loadMyNotifications = createEffect(() =>
    this.actions$.pipe(
      ofType(MyNotificationsActionTypes.LOAD_MY_NOTIFICATIONS),
      switchMap(() => {
        return this.myNotificationsService.getMyNotifications().pipe(
          map(brands => ({
            type: MyNotificationsActionTypes.LOAD_MY_NOTIFICATIONS_SUCCESS,
            payload: brands,
          })),
          catchError(err =>
            of({
              type: MyNotificationsActionTypes.LOAD_MY_NOTIFICATIONS_ERROR,
              payload: err,
            }),
          ),
        );
      }),
    ),
  );

  /**
   * loadMyNotificationsError
   * @param {ActionsObservable<any>} action$
   * @returns {Observable<any>}
   */
  loadMyNotificationsError = createEffect(() =>
    this.actions$.pipe(
      ofType(MyNotificationsActionTypes.LOAD_MY_NOTIFICATIONS_ERROR),
      map(() => ({ type: AuthActionTypes.LOGOUT })),
    ),
  );
}
