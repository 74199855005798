<div
  class="search-chips-bar mb-20"
  *ngIf="
    (filter$ | async).searchTerms?.length > 0 ||
    (filter$ | async).selectedFrameSize?.length > 0 ||
    (filter$ | async).selectedBrands?.length > 0
  "
>
  <div class="row is-flex">
    <div class="col s12">
      <button
        mat-button=""
        class="btn btn-search-chips"
        (click)="removeSearchChip(searchChip)"
        *ngFor="let searchChip of (filter$ | async).searchTerms"
      >
        {{ searchChip }} x
      </button>
      <button
        mat-button=""
        class="btn btn-search-chips"
        (click)="removeFrameSize(frameSize)"
        *ngFor="let frameSize of (filter$ | async).selectedFrameSize"
      >
        {{ frameSize.name }} x
      </button>
      <button
        mat-button=""
        class="btn btn-search-chips"
        (click)="removeBrand(brands)"
        *ngFor="let brands of (filter$ | async).selectedBrands"
      >
        {{ brands }} x
      </button>
      <a
        class="pointer"
        (click)="clearSearchChips()"
        *ngIf="
          (filter$ | async).searchTerms?.length > 0 ||
          (filter$ | async).selectedFrameSize?.length > 0 ||
          (filter$ | async).selectedBrands?.length > 0
        "
        >Clear All</a
      >
    </div>
  </div>
</div>
