<form class="form" [formGroup]="loginForm" (submit)="onSubmit()">
  <div class="form-wrapper">
    <!-- FORM INPUTS -->
    <div class="row is-flex">
      <div class="col">
        <mat-form-field>
          <input matInput name="username" placeholder="Email Address" formControlName="username" required />
          <mat-error *ngIf="username.errors?.required">
            <fa-icon icon="exclamation-triangle"></fa-icon> Username is required.
          </mat-error>
        </mat-form-field>
      </div>

      <div class="col">
        <mat-form-field>
          <input matInput type="password" name="password" placeholder="Password" formControlName="password" required />
          <mat-error *ngIf="password.errors?.required">
            <fa-icon icon="exclamation-triangle"></fa-icon> Password is required.
          </mat-error>
        </mat-form-field>
      </div>
    </div>

    <!-- INVALID CREDENTIALS MESSAGE -->
    <div class="row">
      <div class="col s12">
        <ng-container *ngIf="error$ | async">
          <p class="text-semibold text-block text-warn mb-20">
            <fa-icon icon="exclamation-triangle"></fa-icon> Email or password are invalid, please try again.
          </p>
        </ng-container>
      </div>
    </div>

    <!-- ACTIONS -->
    <div class="row is-flex">
      <div class="col mb-20">
        <button cdkFocusInitial class="btn btn-primary" name="login" type="submit" [disabled]="pending$ | async">
          Login
          <fa-icon *ngIf="pending$ | async" icon="spinner" [spin]="true"></fa-icon>
        </button>
      </div>
      <div class="col mb-20">
        <mat-checkbox
          class="is-block mt-20 mb-20"
          (change)="handleRememberMe($event.checked)"
          [checked]="rememberMeFlag"
        >
          <strong>Remember Me</strong>
        </mat-checkbox>
        <a routerLink="/reset-password" class="btn btn-link">Forgot Password?</a>
      </div>
    </div>
  </div>
</form>
