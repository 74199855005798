import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { ShoppingCartService } from '@app/core/services';
import { Actions, Effect, ofType, createEffect } from '@ngrx/effects';
import { PatientCartActionTypes } from '../actions';

/**
 * FramesEpics
 */
@Injectable()
export class PatientCartEffects {
  /**
   * constructor
   * @param {FramesService} framesService
   */
  constructor(
    private patientCartService: ShoppingCartService,
    private actions$: Actions,
  ) {}

  /**
   * loadPatientCart
   * @param {ActionsObservable<any>} action$
   * @returns {Observable<any>}
   */
  loadMyCollection = createEffect(() =>
    this.actions$.pipe(
      ofType(PatientCartActionTypes.LOAD_PATIENT_CART),
      switchMap(() => {
        return this.patientCartService.getPatientCarts().pipe(
          map(brands => ({
            type: PatientCartActionTypes.LOAD_PATIENT_CART_SUCCESS,
            payload: brands,
          })),
          catchError(err =>
            of({
              type: PatientCartActionTypes.LOAD_PATIENT_CART_ERROR,
              payload: err,
            }),
          ),
        );
      }),
    ),
  );
}
