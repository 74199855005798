import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ErrorHandler, InjectionToken, NgModule } from '@angular/core';
import { BrowserModule, HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { CustomHammerConfig } from './config';
import { EcpErrorHandler } from './services/error-handler.service';
import { HttpErrorInterceptor } from './interceptors/http-error.interceptor';

import { routerReducer, StoreRouterConnectingModule } from '@ngrx/router-store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { ActionReducerMap, StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { environment } from '@env/environment';
import { appReducer, clearState, routerStateConfig, AppState } from './ngrx';
import { AuthEffects } from './ngrx/effects/auth.effects';
import { BrandsEffects } from './ngrx/effects/brands.effects';
import { FramesEffects } from './ngrx/effects/frames.effects';
import { LayoutEffects } from './ngrx/effects/layout.effects';
import { MediaEffects } from './ngrx/effects/media.effects';
import { MyCollectionEffects } from './ngrx/effects/my-collection.effects';
import { MyNotificationsEffects } from './ngrx/effects/my-notifications.effects';
import { MyOrdersEffects } from './ngrx/effects/my-orders.effects';
import { PatientCartEffects } from './ngrx/effects/patient-cart.effects';
import { UserEffects } from './ngrx/effects/user.effects';
import { MyReturnEffects } from './ngrx/effects/my-return.effects';
import { ReturnsStatusEffects } from './ngrx/effects/returns-status.effects';
import { RealignmentOrdersEffects } from './ngrx/effects/realignment-orders.effects';
import { BulkOrdersEffects } from './ngrx/effects/bulk-orders.effects';
import { SwitchProfileEffects } from './ngrx/effects/switch-profile.effects';
import { FrameToComeEffects } from './ngrx/effects/frame-to-come.effects';

export const REDUCER_TOKEN = new InjectionToken<ActionReducerMap<AppState>>('root reducer');
/**
 * CoreModule
 */
@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    StoreModule.forRoot(REDUCER_TOKEN, { metaReducers: [clearState] }),
    StoreModule.forFeature(routerStateConfig.stateKey, routerReducer),
    StoreRouterConnectingModule.forRoot(routerStateConfig),
    EffectsModule.forRoot([
      AuthEffects,
      BrandsEffects,
      FramesEffects,
      LayoutEffects,
      MediaEffects,
      MyCollectionEffects,
      MyReturnEffects,
      MyNotificationsEffects,
      MyOrdersEffects,
      PatientCartEffects,
      UserEffects,
      MyReturnEffects,
      ReturnsStatusEffects,
      RealignmentOrdersEffects,
      BulkOrdersEffects,
      SwitchProfileEffects,
      FrameToComeEffects
    ]),
    StoreDevtoolsModule.instrument({
      name: 'Okta-NGRX App',
      maxAge: 25,
      logOnly: environment.production,
    }),
  ],
  providers: [
    { provide: ErrorHandler, useClass: EcpErrorHandler },
    { provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptor, multi: true },
    { provide: HAMMER_GESTURE_CONFIG, useClass: CustomHammerConfig },
    {
      provide: REDUCER_TOKEN,
      useValue: appReducer,
    },
  ],
})
export class CoreModule {
  /**
   * constructor
   * @param {NgRedux<AppState>} ngRedux
   * @param {NgReduxRouter} ngReduxRouter
   * @param {RootEpics} rootEpics
   * @param {DevToolsExtension} devTools
   */
  constructor() {}
}
