<div class="container" *ngIf="(frames$ | async)?.length > 0">
  <div class="row">
    <div class="col offset-l3 l3 m6 s12">
      <mat-form-field [formGroup]="CatalogFilter">
        <mat-label
          class="filter-brand__label"
          [ngClass]="{ hidden: frameFilter.value && frameFilter.value.length > 0 }"
        >
          Frame Size
        </mat-label>
        <mat-select
          multiple
          formControlName="frameFilter"
          (selectionChange)="checkFilterSize()"
          [compareWith]="compareValues"
        >
          <mat-option [value]="size" *ngFor="let size of sizeFilters">{{ size.name }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="col offset-l3 l3 m6 s12 pull-l3">
      <mat-form-field>
        <mat-label class="filter-brand__label" [ngClass]="{ hidden: brandArray.value && brandArray.value.length > 0 }">
          Brand
        </mat-label>
        <mat-select multiple [formControl]="brandArray" (selectionChange)="checkBrandList()">
          <mat-option *ngFor="let item of brands$ | async" [value]="item.name">
            {{ item.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>
</div>
