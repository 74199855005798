import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap, takeUntil } from 'rxjs/operators';

import { Router } from '@angular/router';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { FrameToComeActionTypes } from '../actions';
import { FrameToComeService } from '@app/core/services';

/**
 * FrametoComeEffect
 */
@Injectable()
export class FrameToComeEffects {
  /**
   * constructor
   * @param {BulkOrdersService} frameToComeService
   */
  constructor(private frameToComeService: FrameToComeService, private router: Router, private actions$: Actions) {}

  /**
   * loadBulkOrders
   * @param {ActionsObservable<any>} action$
   * @returns {Observable<any>}
   */
  loadSearchFrameToCome = createEffect(() =>
    this.actions$.pipe(
      ofType(FrameToComeActionTypes.SEARCH_FRAME_TO_COME),
      switchMap(({ payload }) => {
        return this.frameToComeService.searchFrameByUPC(payload).pipe(
          map(frame => ({
            type: FrameToComeActionTypes.SEARCH_FRAME_TO_COME_SUCCESS,
            payload: frame,
          })),
          catchError(err =>
            of({
              type: FrameToComeActionTypes.SEARCH_FRAME_TO_COME_ERROR,
              payload: err,
            }),
          ),
        );
      }),
    ),
  );
  loadCartFrameToCome = createEffect(() =>
    this.actions$.pipe(
      ofType(FrameToComeActionTypes.LOAD_FRAME_TO_COME),
      switchMap(({ payload }) => {
        return this.frameToComeService.getCartDetails(payload).pipe(
          map(cart => ({
            type: FrameToComeActionTypes.LOAD_FRAME_TO_COME_SUCCESS,
            payload: cart,
          })),
          catchError(err =>
            of({
              type: FrameToComeActionTypes.LOAD_FRAME_TO_COME_ERROR,
              payload: err,
            }),
          ),
        );
      }),
    ),
  );
  loadOrderFrameToCome = createEffect(() =>
    this.actions$.pipe(
      ofType(FrameToComeActionTypes.LOAD_FRAME_TO_COME_ORDERS),
      switchMap(() => {
        return this.frameToComeService.getFrameToComeOrders().pipe(
          map(cart => ({
            type: FrameToComeActionTypes.LOAD_FRAME_TO_COME_ORDERS_SUCCESS,
            payload: cart,
          })),
          catchError(err =>
            of({
              type: FrameToComeActionTypes.LOAD_FRAME_TO_COME_ORDERS_ERROR,
              payload: err,
            }),
          ),
        );
      }),
    ),
  );
}
