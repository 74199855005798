import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap, takeUntil } from 'rxjs/operators';

import { Router } from '@angular/router';
import { SearchService } from '@app/core/services';
import { Actions, Effect, ofType, createEffect } from '@ngrx/effects';
import { FramesActionTypes, AuthActionTypes } from '../actions';

/**
 * FramesEpics
 */
@Injectable()
export class FramesEffects {
  /**
   * constructor
   * @param {FramesService} framesService
   */
  constructor(
    private framesService: SearchService,
    private actions$: Actions,
  ) {}

  /**
   * loadFrameSearch
   * @param {ActionsObservable<any>} action$
   * @returns {Observable<any>}
   */
  loadFrameSearch = createEffect(() =>
    this.actions$.pipe(
      ofType(FramesActionTypes.LOAD_FRAME_SEARCH),
      switchMap((action: any) => {
        return this.framesService.searchFrames(action.payload).pipe(
          map(frames => {
            if (action.payload.skip === 0) {
              return {
                type: FramesActionTypes.LOAD_FRAMES_SUCCESS,
                payload: frames,
              };
            } else {
              return {
                type: FramesActionTypes.LOAD_MORE_FRAMES_SUCCESS,
                payload: frames,
              };
            }
          }),
          catchError(err =>
            of({ type: FramesActionTypes.LOAD_FRAMES_ERROR, payload: err }),
          ),
        );
      }),
    ),
  );

  loadFrame = createEffect(() =>
    this.actions$.pipe(
      ofType(FramesActionTypes.LOAD_FRAME_DETAILS),
      switchMap(({ payload }) => {
        return this.framesService.searchFrames(payload).pipe(
          map(frames => {
            return {
              type: FramesActionTypes.LOAD_FRAME_DETAILS_SUCCESS,
              payload: frames.frames[0],
            };
          }),
          catchError(err =>
            of({
              type: FramesActionTypes.LOAD_FRAME_DETAILS_ERROR,
              payload: err,
            }),
          ),
        );
      }),
    ),
  );

  loadPatientFrame = createEffect(() =>
    this.actions$.pipe(
      ofType(FramesActionTypes.LOAD_PATIENT_FRAME_DETAILS),
      switchMap((action: any) => {
        return this.framesService
          .searchPatientFrames(action.payload.id, action.payload.filters)
          .pipe(
            map(frames => {
              return {
                type: FramesActionTypes.LOAD_FRAME_DETAILS_SUCCESS,
                payload: frames.frames[0],
              };
            }),
            catchError(err =>
              of({
                type: FramesActionTypes.LOAD_FRAME_DETAILS_ERROR,
                payload: err,
              }),
            ),
          );
      }),
    ),
  );

  /**
   * loadRelatedFrames
   * @param {ActionsObservable<any>} action$
   * @returns {Observable<any>}
   */
  loadRelatedFrames = createEffect(() =>
    this.actions$.pipe(
      ofType(FramesActionTypes.LOAD_RELATED_FRAMES),
      switchMap(({ payload }) => {
        return this.framesService.loadRelatedFrames(payload).pipe(
          map(frames => ({
            type: FramesActionTypes.LOAD_RELATED_FRAMES_SUCCESS,
            payload: frames,
          })),
          catchError(err =>
            of({ type: FramesActionTypes.LOAD_FRAMES_ERROR, payload: err }),
          ),
        );
      }),
    ),
  );

  /**
   * loadRelatedFrames
   * @param {ActionsObservable<any>} action$
   * @returns {Observable<any>}
   */
  loadPatientRelatedFrames = createEffect(() =>
    this.actions$.pipe(
      ofType(FramesActionTypes.LOAD_PATIENT_RELATED_FRAMES),
      switchMap((action: any) => {
        return this.framesService
          .loadPatientRelatedFrames(
            action.payload.upc,
            action.payload.patientId,
          )
          .pipe(
            map(frames => ({
              type: FramesActionTypes.LOAD_RELATED_FRAMES_SUCCESS,
              payload: frames,
            })),
            catchError(err =>
              of({ type: FramesActionTypes.LOAD_FRAMES_ERROR, payload: err }),
            ),
          );
      }),
    ),
  );

  /**
   * loadFramesError
   * @param {ActionsObservable<any>} action$
   * @returns {Observable<any>}
   */
  loadFramesError = createEffect(() =>
    this.actions$.pipe(
      ofType(FramesActionTypes.LOAD_FRAMES_ERROR),
      map(() => ({ type: AuthActionTypes.LOGOUT })),
    ),
  );

  /**
   * loadPatientFrameSearch
   * @param {ActionsObservable<any>} action$
   * @returns {Observable<any>}
   */
  loadPatientFrameSearch = createEffect(() =>
    this.actions$.pipe(
      ofType(FramesActionTypes.LOAD_PATIENT_FRAME_SEARCH),
      switchMap((action: any) => {
        return this.framesService
          .searchPatientFrames(action.payload.id, action.payload.filters)
          .pipe(
            map(frames => {
              if (action.payload.filters.skip === 0) {
                return {
                  type: FramesActionTypes.LOAD_FRAMES_SUCCESS,
                  payload: frames,
                };
              } else {
                return {
                  type: FramesActionTypes.LOAD_MORE_FRAMES_SUCCESS,
                  payload: frames,
                };
              }
            }),
            catchError(err =>
              of({ type: FramesActionTypes.LOAD_FRAMES_ERROR, payload: err }),
            ),
          );
      }),
    ),
  );
}
