import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { HttpClient } from '@angular/common/http';
import { Brands } from '@app/core/models/brands.model';
import { ApiService } from '@app/core/services/api.service';
import { State } from '@ngrx/store';
import { AppState } from '@app/core/ngrx';

/**
 * BrandsService
 */
@Injectable({ providedIn: 'root' })
export class BrandsService {
  /**
   * url
   */
  public url;

  /**
   * constructor
   * @param {ApiService} apiService
   * @param {HttpClient} http
   */
  constructor(
    private apiService: ApiService,
    private http: HttpClient,
    private state: State<AppState>,
  ) {}

  /**
   * getBrands
   * @returns {Observable<Brands>}
   */
  getBrands(): Observable<Brands> {
    const options = {
      headers: {
        programKey: this.state.getValue().user.profile.program.programKey,
      },
    };
    return this.apiService.get('/brands', options);
  }

  /**
   * getPatientBrands
   * @returns {Observable<Brands>}
   */
  getPatientBrands(code: string): Observable<Brands> {
    return this.apiService.get(`/brands/${code}`);
  }
}
