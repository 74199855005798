import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { HttpClient } from '@angular/common/http';
import { MyCollection } from '@app/core/models/my-collection.model';
import { ApiService } from '@app/core/services/api.service';
import { State } from '@ngrx/store';
import { AppState } from '@app/core/ngrx';

/**
 * BrandsService
 */
@Injectable({ providedIn: 'root' })
export class MyCollectionService {
  /**
   * url
   */
  public url;

  /**
   * constructor
   * @param {ApiService} apiService
   * @param {HttpClient} http
   */
  constructor(
    private apiService: ApiService,
    private http: HttpClient,
    private state: State<AppState>,
  ) {}

  /**
   * getMyCollection
   * @returns {Observable<MyCollection[]>}
   */
  getMyCollection(): Observable<MyCollection[]> {
    const options = {
      headers: {
        programKey: this.state.getValue().user.profile.program.programKey,
      },
    };
    return this.apiService.get('/mycollection');
  }

  /**
   * getMyCollection
   * @returns {Observable<MyCollection[]>}
   */
  getMyNewFrames(): Observable<any> {
    const options = {
      headers: {
        programKey: this.state.getValue().user.profile.program.programKey,
      },
    };
    return this.apiService.get('/mycollection/download');
  }

  saveRetailPrices(payload) {
    const options = {
      headers: {
        programKey: this.state.getValue().user.profile.program.programKey,
      },
    };
    return this.apiService.post('/retailpricing', payload, options);
  }

  saveSlowFrames(payload) {
    const options = {
      headers: {
        programKey: this.state.getValue().user.profile.program.programKey,
      },
    };
    return this.apiService.post('/mycollection/slowframes', payload, options);
  }
}
