import { Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import {
  AppState,
  CatalogActionTypes,
  Filter,
  FramesActionTypes,
  selectPatientId,
} from '@app/core/ngrx';
import { SearchService } from '@app/core/services/search.service';
import { Store, select } from '@ngrx/store';

@Component({
  selector: 'ecp-browse-catalog',
  templateUrl: './browse-catalog.component.html',
  styleUrls: ['./browse-catalog.component.scss'],
})
export class BrowseCatalogComponent implements OnInit {
  public frames$: Observable<any>;
  public framesPending$: Observable<any>;
  public totalResults$: Observable<number>;
  public searchChips$: Observable<Array<string>>;
  public searchBrands$: Observable<Array<any>>;
  public filter$: Observable<Filter>;
  public framesLoaded$: Observable<boolean>;
  public patientCode$: Observable<string>;
  public code: string;

  constructor(
    private store: Store<AppState>,
    private searchService: SearchService,
  ) {}

  /**
   * ngOnInit
   * @description We will subscribe to the frames and the totalResults to send to the catalog components
   */
  ngOnInit(): void {
    this.frames$ = this.store.pipe(select(state => state.frames.list.frames));
    this.framesPending$ = this.store.pipe(
      select(state => state.frames.pending),
    );
    this.totalResults$ = this.store.pipe(
      select(state => state.frames.list.totalResults),
    );
    this.searchChips$ = this.store.pipe(
      select(state => state.catalog.filter.searchTerms),
    );
    this.searchBrands$ = this.store.pipe(
      select(state => state.catalog.filter.selectedBrands),
    );

    this.patientCode$ = this.store.pipe(select(selectPatientId));
    this.filter$ = this.store.pipe(select(state => state.catalog.filter));
    this.framesLoaded$ = this.store.pipe(select(state => state.frames.loaded));

    this.frames$.pipe().subscribe(frames => {});
    this.totalResults$.pipe().subscribe(totalResults => {});

    this.patientCode$.subscribe(code => {
      this.code = code;
    });
  }

  /**
   * browseEntireCatalog
   * @description This will invoke the API call to show the entire user's catalog but will only return the first 16 frames back to the user
   * If the user wants more they will click the "View More Frames" button.
   */
  browseEntireCatalog(): void {
    this.store.dispatch({
      type: CatalogActionTypes.CLEAR_ALL_FILTERS,
    });
    if (this.code) {
      this.store.dispatch({
        type: FramesActionTypes.LOAD_PATIENT_FRAME_SEARCH,
        payload: {
          filters: {
            terms: [],
            brands: [],
            sizes: [],
            skip: 0,
          },
          id: this.code,
        },
      });
    } else {
      this.store.dispatch({
        type: FramesActionTypes.LOAD_FRAME_SEARCH,
        payload: {
          terms: [],
          brands: [],
          sizes: [],
          skip: 0,
        },
      });
    }
  }

  /**
   * viewMoreFrames
   * @description When the user wants to see more than 16 frames they will click this and it will append 16 more frames.
   */
  viewMoreFrames(): void {
    this.store.dispatch({
      type: CatalogActionTypes.LOAD_MORE_FRAMES,
    });
  }
}
