<div class="cart">
  <ng-container *ngIf="(framesCart$ | async).length === 0; else cart">
    <h1>"My Favorites" is empty</h1>
    <h4>Please add frames to "My Favorites".</h4>
  </ng-container>
  <ng-template #cart>
    <h1>My Favorites</h1>
    <div class="cart--two-col" *ngFor="let frame of framesCart$ | async">
      <img src="{{ frame.smallImageUrl }}" />
      <div class="frame-detail">
        <p class="heading--1">{{ frame.styleName }}</p>
        <p class="heading--2">{{ frame.brandName }}</p>
        <!--        <p class="heading&#45;&#45;1">{{ frame.purchasePrice }}</p>-->
      </div>
    </div>
    <div>
      <button class="btn btn-primary" routerLink="{{ patientId }}/shopping-cart">View "My Favorites"</button>
    </div>
  </ng-template>
</div>
