import { AuthActions, AuthActionTypes } from '../actions';

/**
 * AuthState
 */
export interface AuthState {
  authenticated: boolean;
  pending: boolean;
  error: any;
}

/**
 * AUTH_INITIAL_STATE
 * @type {{authenticated: boolean; pending: boolean; error: null}}
 */
export const AUTH_INITIAL_STATE: AuthState = {
  authenticated: false,
  pending: false,
  error: null,
};

/**
 * authReducer
 * @param {AuthState} state
 * @param {AuthActions} action
 * @returns {AuthState}
 */
export const authReducer = (
  state: AuthState = AUTH_INITIAL_STATE,
  action: AuthActions,
): AuthState => {
  switch (action.type) {
    case AuthActionTypes.LOGIN:
      return {
        ...state,
        pending: true,
      };
    case AuthActionTypes.LOGIN_SUCCESS:
      return {
        ...state,
        authenticated: true,
        pending: false,
      };
    case AuthActionTypes.LOGIN_ERROR:
      return {
        ...state,
        error: action.payload,
        authenticated: false,
        pending: false,
      };
    case AuthActionTypes.LOGOUT:
      if (action.payload) {
        window.localStorage.setItem('returnUrl', action.payload);
      }
      return {
        ...state,
        authenticated: false,
        error: null,
        pending: false,
      };
    default:
      return state;
  }
};
