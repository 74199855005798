import { Component, Input, Output, EventEmitter } from '@angular/core';
import { MenuItem } from '@app/core/models/menu.model';

@Component({
  selector: 'ecp-sidenav-link',
  templateUrl: './sidenav-link.component.html',
  styleUrls: ['./sidenav-link.component.scss'],
})
export class SidenavLinkComponent {
  @Input() link: MenuItem;
  @Input() red: boolean;
}
