import { User } from '@app/core/models';
import { UserActions, UserActionTypes } from '../actions';

/**
 * UserState
 */
export interface UserState {
  loaded: boolean;
  pending: boolean;
  error: any;
  profile: User;
  patientID: string;
}

/**
 * USER_INITIAL_STATE
 * @type {{loaded: boolean; pending: boolean; error: null; profile: null}}
 */
export const USER_INITIAL_STATE: UserState = {
  loaded: false,
  pending: false,
  error: null,
  profile: {
    userName: null,
    businessName: null,
    firstName: null,
    lastName: null,
    program: {
      programKey: null,
      programName: null,
      isOrderable: false,
      isCatalogVisible: false,
      logoPath: 'assets/images/essilor-logo-white.svg',
      contactNumber: null,
      newsletterTitle: null,
      resourcesTitle: null,
      programOverviewTitle: null,
      assignedLab: null,
      hidePricing: null,
    },
    token: null,
    account: null,
    isPremiumMember: false,
    featureFlags: null,
    patientPreviewUrl: null,
    primaryLab: null,
    labAccountNumber: null,
    address: null,
    cityState: null,
    email: null,
    phoneNumber: null,
  },
  patientID: '',
};
/**
 * userReducer
 * @param {UserState} state
 * @param {UserActions} action
 * @returns {UserState}
 */
export const userReducer = (
  state: UserState = USER_INITIAL_STATE,
  action: UserActions,
): UserState => {
  switch (action.type) {
    case UserActionTypes.LOAD_USER:
      return {
        ...state,
        error: null,
        loaded: false,
        pending: true,
      };
    case UserActionTypes.LOAD_USER_SUCCESS:
      return {
        ...state,
        loaded: true,
        pending: false,
        profile: { ...state.profile, ...action.payload },
      };
    case UserActionTypes.LOAD_USER_ERROR:
    case UserActionTypes.LOAD_PATIENT_ERROR:
      return { ...state, error: action.payload };
    case UserActionTypes.LOAD_PATIENT_ID:
      return {
        ...state,
        patientID: action.payload,
      };
      case UserActionTypes.CHANGE_USER_ACCOUNT:
        return {
          ...state,
          loaded: true,
          pending: false,
          profile: { ...state.profile},
        };
    default:
      return state;
  }
};
