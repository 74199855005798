<div class="header__toolbar">
  <div class="container header__container">
    <!-- Authed -->
    <ecp-nav *showAuthed="true">
      <ecp-nav-item
        *ngIf="(user$ | async)?.program.marketingLink"
        label="Marketing Help"
        link="{{ (user$ | async)?.program.marketingLink }}"
        ext="true"
      ></ecp-nav-item>
      <ecp-nav-item label="Contact Us" link="mailto:{{ (user$ | async)?.email }}" ext="true"></ecp-nav-item>
      <ecp-nav-item
        label="{{ (user$ | async)?.program.contactNumber | phonemask }}"
        link="tel:{{ (user$ | async)?.program.contactNumber }}"
        ext="true"
      ></ecp-nav-item>
      <ecp-nav-item
        label="Welcome {{ (user$ | async)?.firstName }} {{ (user$ | async)?.lastName }}"
        noLink="true"
      ></ecp-nav-item>
      <ecp-nav-item label="Logout" link="/auth/logout"></ecp-nav-item>
      <ecp-nav-item class="nav__item--user" title="My Account" icon="user-circle" ecpToggle="is-open">
        <div class="nav__item__dropdown-wrapper">
          <div>
            <div class="nav__item__dropdown">
              <div class="row">
                <div class="col s12 m6 l6">
                  <p><strong>Business:</strong> {{ (user$ | async)?.businessName }}</p>
                  <p><strong>Address:</strong> {{ (user$ | async)?.address }}<br />{{ (user$ | async)?.cityState }}</p>
                  <p><strong>Phone:</strong> {{ (user$ | async)?.phoneNumber }}</p>
                  <p><strong>Email:</strong> {{ (user$ | async)?.email }}</p>
                </div>
                <div class="col s12 m6 l6">
                  <p><strong>Frame Account # :</strong> {{ (user$ | async)?.account }}</p>
                  <p><strong>Primary Lab :</strong> {{ (user$ | async)?.primaryLab }}</p>
                  <p><strong>Lab Account # :</strong> {{ (user$ | async)?.labAccountNumber }}</p>
                </div>
              </div>
              <hr class="divider" />
              <div class="row">
                <div class="col s12 m12 l12">
                  <p><strong>Preview Url :</strong> {{ (user$ | async)?.patientPreviewUrl }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ecp-nav-item>
    </ecp-nav>
    <!-- Patient -->
    <ecp-nav *showPatient="true">
      <ecp-nav-item
        label="{{ (user$ | async)?.businessName }}, {{ (user$ | async)?.address }}"
        noLink="true"
      ></ecp-nav-item>
      <ecp-nav-item *ngIf="(user$ | async)?.isPremiumMember === true" label="My Favorites" noLink="true"></ecp-nav-item>
      <ecp-nav-item
        *ngIf="(user$ | async)?.isPremiumMember === true"
        class="nav__item--user"
        title="My Account"
        icon="shopping-basket"
        ecpHover
        hoverClass="is-open"
      >
        <div class="nav__item__dropdown-wrapper">
          <div>
            <div class="nav__item__dropdown">
              <hr class="divider" />
              <ecp-cart-dropdown [patientId]="patientId"></ecp-cart-dropdown>
            </div>
          </div>
        </div>
      </ecp-nav-item>
    </ecp-nav>
  </div>
</div>

<div class="header__main">
  <div class="container">
    <a
      *ngIf="defaultLogo !== null && (authenticated$ | async)"
      [routerLink]="(authenticated$ | async) ? '/catalog' : '/'"
      class="header__logo"
    >
      <picture>
        <source media="(min-width: 900px)" [srcset]="defaultLogo" type="image/svg+xml" />
        <img [src]="defaultLogo" alt="MyFrameBoard®" />
      </picture>
    </a>

    <div class="header__navbar">
      <ng-content></ng-content>
    </div>

    <div class="header__mobile" *showAuthed="true">
      <button
        (click)="toggleUserMenu()"
        [ngClass]="{ 'is-active': (sideNav$ | async)?.open && (sideNav$ | async)?.target === 'user' }"
      >
        <mat-icon
          svgIcon="close"
          *ngIf="(sideNav$ | async)?.open && (sideNav$ | async)?.target === 'user'; else userIcon"
        >
        </mat-icon>
        <ng-template #userIcon>
          <fa-icon icon="user-circle"></fa-icon>
        </ng-template>
      </button>
      <button
        (click)="toggleMainMenu()"
        [ngClass]="{ 'is-active': (sideNav$ | async)?.open && (sideNav$ | async)?.target === 'menu' }"
      >
        <mat-icon
          svgIcon="close"
          *ngIf="(sideNav$ | async)?.open && (sideNav$ | async)?.target === 'menu'; else menuIcon"
        >
        </mat-icon>
        <ng-template #menuIcon>
          <mat-icon svgIcon="menu"></mat-icon>
        </ng-template>
      </button>
    </div>
    <div class="header__mobile" *ngIf="patientId$ | async">
      <button
        (click)="toggleMainMenu()"
        [ngClass]="{ 'is-active': (sideNav$ | async)?.open && (sideNav$ | async)?.target === 'menu' }"
      >
        <mat-icon
          svgIcon="close"
          *ngIf="(sideNav$ | async)?.open && (sideNav$ | async)?.target === 'menu'; else menuIcon"
        >
        </mat-icon>
        <ng-template #menuIcon>
          <mat-icon svgIcon="menu"></mat-icon>
        </ng-template>
      </button>
    </div>
  </div>
</div>
