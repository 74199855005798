import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap, takeUntil } from 'rxjs/operators';

import { Router } from '@angular/router';
import { MyOrdersService } from '@app/core/services';
import { Effect, Actions, ofType, createEffect } from '@ngrx/effects';
import { MyOrdersActionTypes, AuthActionTypes } from '../actions';

/**
 * MyOrdersEpics
 */
@Injectable()
export class MyOrdersEffects {
  /**
   * constructor
   * @param {MyOrdersService} myOrdersService
   */
  constructor(
    private myOrdersService: MyOrdersService,
    private router: Router,
    private actions$: Actions,
  ) {}

  /**
   * loadMyOrders
   * @param {ActionsObservable<any>} action$
   * @returns {Observable<any>}
   */
  loadMyOrders = createEffect(() =>
    this.actions$.pipe(
      ofType(MyOrdersActionTypes.LOAD_MY_ORDERS),
      switchMap(() => {
        return this.myOrdersService.getMyOrders().pipe(
          map(orders => ({
            type: MyOrdersActionTypes.LOAD_MY_ORDERS_SUCCESS,
            payload: orders,
          })),
          catchError(err =>
            of({
              type: MyOrdersActionTypes.LOAD_MY_ORDERS_ERROR,
              payload: err,
            }),
          ),
        );
      }),
    ),
  );

  /**
   * loadMyOrdersError
   * @param {ActionsObservable<any>} action$
   * @returns {Observable<any>}
   */
  loadMyOrdersError = createEffect(() =>
    this.actions$.pipe(
      ofType(MyOrdersActionTypes.LOAD_MY_ORDERS_ERROR),
      map(() => ({ type: AuthActionTypes.LOGOUT })),
    ),
  );
}
