import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { HttpClient } from '@angular/common/http';
import { ApiService } from '@app/core/services/api.service';
import { State } from '@ngrx/store';
import { AppState } from '@app/core/ngrx';
import { BulkUpload, BulkUploadResponse } from '../models/bulk-upload.model';

/**
 * FrameToComeService
 */
@Injectable({ providedIn: 'root' })
export class FrameToComeService {
  /**
   * url
   */
  public url;

  /**
   * constructor
   * @param {ApiService} apiService
   * @param {HttpClient} http
   */
  constructor(private apiService: ApiService, private http: HttpClient, private state: State<AppState>) {}

  /**
   * ValidateOrder
   * @returns {Observable<any>}
   */
  searchFrameByUPC(upc: string) {
    const options = {
      headers: {
        programKey: this.state.getValue().user.profile.program.programKey,
      },
    };
    return this.apiService.get(`/shippingframe/search?upc=${upc}`, options);
  }
  addCartDetails(payload: any) {
    const options = {
      headers: {
        programKey: this.state.getValue().user.profile.program.programKey,
      },
    };
    return this.apiService.post('/shippingframe', payload, options);
  }
  getCartDetails(cartId:any) {
    const options = {
      headers: {
        programKey: this.state.getValue().user.profile.program.programKey,
      },
    };
    return this.apiService.get(`/shippingframe/${cartId}`, options);
  }
  removeCartDetails(cartItemId:any) {
    const options = {
      headers: {
        programKey: this.state.getValue().user.profile.program.programKey,
      },
      body: {
        cartItemId: cartItemId
      }
    };
    return this.apiService.delete(`/shippingframe`, options);
  }
  generateShippingLabel(payload: any) {
    const options = {
      headers: {
        programKey: this.state.getValue().user.profile.program.programKey,
      },
    };
    return this.apiService.post('/shippingframe/label', payload, options);
  }
  getShippingLabelDetails(cartId: any) {
    const options = {
      headers: {
        programKey: this.state.getValue().user.profile.program.programKey,
      },
    };
    return this.apiService.get(`/shippingframe/tracking?cartId=${cartId}`, options);
  }
  getLabAddressDetails() {
    const options = {
      headers: {
        programKey: this.state.getValue().user.profile.program.programKey,
      },
    };
    return this.apiService.get(`/shippingframe/lab-addresses`, options);
  }
  getFrameToComeOrders() {
    const options = {
      headers: {
        programKey: this.state.getValue().user.profile.program.programKey,
      },
    };
    return this.apiService.get(`/shippingframe`, options);
  }
  getShippingUrl(trackingNumber:string){
    const options = {
      headers: {
        programKey: this.state.getValue().user.profile.program.programKey,
      },
    };
    return this.apiService.delete(`/shippingframe/shipping-label-url/${trackingNumber}`, options);
  }
  getShippingLabelFile(trackingNumber: any) {
    const options = {
      headers: {
        programKey: this.state.getValue().user.profile.program.programKey,
      },
    };
    return this.apiService.get(`/shippingframe/label/${trackingNumber}`, options);
  }
}
