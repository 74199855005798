import { Component, Input, Output, EventEmitter } from '@angular/core';
import { Menu } from '@app/core/models/menu.model';

@Component({
  selector: 'ecp-sidenav-list',
  templateUrl: './sidenav-list.component.html',
  styleUrls: ['./sidenav-list.component.scss'],
})
export class SidenavListComponent {
  @Input() links: Menu[];
}
