import { Pipe, PipeTransform } from '@angular/core';

/**
 * getObject
 * @param theObject
 * @param searchTerm
 * @returns {boolean}
 */
let getObject = (theObject, searchTerm) => {
  let result = false;
  if (theObject instanceof Array) {
    for (let i = 0; i < theObject.length; i++) {
      result = getObject(theObject[i], searchTerm);
      if (result) {
        break;
      }
    }
  } else {
    // tslint:disable-next-line:forin
    for (let prop in theObject) {
      // console.log(prop + ': ' + theObject[prop]);
      if (
        theObject[prop] &&
        theObject[prop]
          .toString()
          .toLowerCase()
          .indexOf(searchTerm.toLowerCase()) !== -1
      ) {
        return true;
      }
      if (
        theObject[prop] instanceof Object ||
        theObject[prop] instanceof Array
      ) {
        result = getObject(theObject[prop], searchTerm);
        if (result) {
          break;
        }
      }
    }
  }
  return result;
};

@Pipe({
  name: 'searchList',
  pure: true,
})
export class SearchList implements PipeTransform {
  /**
   * transform
   * @param {Array<any>} value
   * @param {string} searchTerm
   * @returns {any}
   */
  transform(value: Array<any>, searchTerm?: string): any {
    if (!value || !Array.isArray(value) || !searchTerm) return value;
    let newValue = [...value];
    let searchedArray = newValue.filter(searchObj =>
      getObject(searchObj, searchTerm),
    );
    return searchedArray;
  }
}
