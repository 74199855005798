<div [class]="theme ? theme : 'one-portal'">
  <h2>Export Brand Assortment</h2>
  <p>In order to export a digital assortment, please select one brand</p>
  <form [formGroup]="exportBrandForm" class="collection-price">
    <mat-form-field>
      <mat-select matInput formControlName="exportSelectedBrand" required>
        <mat-option role="menuitem" *ngFor="let item of brands$ | async" [value]="item.name | uppercase">
          {{ item.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <div class="button-container btn-group">
      <button mat-button class="btn btn-secondary" (click)="closeDialog()">Cancel</button>
      <button
        mat-button
        class="btn btn-primary"
        type="submit"
        (click)="exportBrand()"
        [disabled]="!exportBrandForm.valid || isExportingBrand"
        [ngClass]="{ disabled: !exportBrandForm.valid, success: exportBrandForm.valid }"
      >
        Generate PDF Document
        <fa-icon *ngIf="isExportingBrand" icon="spinner" [spin]="true"></fa-icon>
      </button>
    </div>
  </form>
</div>
