<div class="item-container">
  <div (click)="goToFrameOrder()">
    <div class="product-item__image">
      <img [src]="image" [alt]="name" class="img-responsive" onError="this.src='/assets/images/frame-default-lg.png'" />
    </div>

    <strong class="product-item__title">{{ title }}</strong>
    <div class="product-item__desc1">{{ desc1 }}</div>
    <div class="product-item__desc2">{{ desc2 }}</div>
    <!-- <div class="product-item__desc2" *ngIf="clusterName != null">{{ clusterName }}</div> -->
    <div class="product-item__size">{{ size }}</div>
  </div>
  <div class="product-item__button">
    <!-- ECP View -->
    <button
      *ngIf="!patientId && isOrderable && user?.program.isOrderable; else emptySpan"
      class="btn btn-outline"
      type="bustton"
      [routerLink]="patientId ? '/order/' + upc + '/patient/' + patientId : '/order/' + upc"
    >
      Order This Frame
    </button>
    <!-- Patient View -->
    <div class="btn-group-vertical">
      <button
        *ngIf="patientId && isOrderable && user?.program.isOrderable; else emptySpan"
        class="btn btn-outline"
        type="bustton"
        [routerLink]="patientId ? '/order/' + upc + '/patient/' + patientId : '/order/' + upc"
      >
        View This Frame
      </button>
      <ecp-add-to-cart-btn
        *ngIf="frameDetail.collectionContext === 'InModule' && user?.isPremiumMember"
        [patientId]="patientId"
        [frameDetail]="frameDetail"
      ></ecp-add-to-cart-btn>
    </div>
    <div
      class="container mt-20"
      *ngIf="frameDetail.collectionContext !== 'InModule' && frameDetail.collectionContext !== 'InCluster' && patientId"
    >
      This frame is not available for try on in the office.
    </div>
    <ng-template #emptySpan>
      <span>&nbsp;</span>
    </ng-template>
  </div>
</div>
