import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap, takeUntil } from 'rxjs/operators';
import { Router } from '@angular/router';
import { MediaService } from '@app/core/services/media.service';
import { Actions, ofType, Effect, createEffect } from '@ngrx/effects';
import { MediaActionTypes, AuthActionTypes } from '../actions';

/**
 * MediaEpics
 */
@Injectable()
export class MediaEffects {
  /**
   * constructor
   * @param {MediaService} mediaService
   */
  constructor(
    private mediaService: MediaService,
    private router: Router,
    private actions$: Actions,
  ) {}

  /**
   * loadMedia
   * @param {ActionsObservable<any>} action$
   * @returns {Observable<any>}
   */
  loadMedia = createEffect(() =>
    this.actions$.pipe(
      ofType(MediaActionTypes.LOAD_MEDIA),
      switchMap(() => {
        // TODO - replace w. getOktaMedia() when integration of Okta SSO
        return this.mediaService.getMedia().pipe(
          map(user => ({
            type: MediaActionTypes.LOAD_MEDIA_SUCCESS,
            payload: user,
          })),
          catchError(err =>
            of({ type: MediaActionTypes.LOAD_MEDIA_ERROR, payload: err }),
          ),
        );
      }),
    ),
  );

  /**
   * loadMediaError
   * @param {ActionsObservable<any>} action$
   * @returns {Observable<any>}
   */
  loadMediaError = createEffect(() =>
    this.actions$.pipe(
      ofType(MediaActionTypes.LOAD_MEDIA_ERROR),
      map(() => ({ type: AuthActionTypes.LOGOUT })),
    ),
  );
}
