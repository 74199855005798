<form class="form hide-mobile hide-tablet" [formGroup]="loginForm" (submit)="onSubmit()">
  <div class="container">
    <!-- FORM INPUTS -->
    <div class="row is-flex">
      <div class="col s12 push-l2">
        <div class="row is-flex">
          <div class="col s4">
            <mat-form-field floatLabel="never">
              <input matInput name="username" placeholder="Email Address" formControlName="username" required />
              <mat-hint>&nbsp;</mat-hint>
              <mat-error *ngIf="username.errors?.required">
                <fa-icon icon="exclamation-triangle"></fa-icon> Email address is required.
              </mat-error>
              <mat-error *ngIf="!username.errors?.required && username.errors?.email">
                <fa-icon icon="exclamation-triangle"></fa-icon> Invalid Email address.
              </mat-error>
            </mat-form-field>
          </div>

          <div class="col s4">
            <mat-form-field floatLabel="never">
              <input
                matInput
                type="password"
                name="password"
                placeholder="Password"
                formControlName="password"
                required
              />
              <!-- <mat-hint><span routerLink="/reset-password">Forgot Username or Password?</span></mat-hint> -->
              <mat-error *ngIf="password.errors?.required">
                <fa-icon icon="exclamation-triangle"></fa-icon> Password is required.
              </mat-error>
            </mat-form-field>
          </div>

          <div class="col s4">
            <button
              cdkFocusInitial
              class="btn btn-primary"
              name="login"
              type="submit"
              [disabled]="pending$ | async"
              style="margin-bottom: 12px;"
            >
              <!-- style="width:  30px; margin-bottom: 12px;"-->
              Login
              <fa-icon *ngIf="pending$ | async" icon="spinner" [spin]="true"></fa-icon>
            </button>
          </div>
          <div class="col l2">&nbsp;</div>
        </div>
      </div>
    </div>
    <!-- INVALID CREDENTIALS MESSAGE -->
    <ng-container *ngIf="(error$ | async) && (error$ | async) !== 'PASSWORD_EXPIRED'">
      <div class="row is-flex">
        <div class="col m4 s10">
          <span class="text-semibold text-block text-warn mb-20 error-message">
            <fa-icon icon="exclamation-triangle"></fa-icon> Email or password are invalid, please try again.
          </span>
        </div>
      </div>
    </ng-container>
  </div>
</form>
