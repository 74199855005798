import { Component, OnInit } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';
import {
  AppState,
  CatalogActionTypes,
  Filter,
  FramesActionTypes,
} from '@app/core/ngrx';
@Component({
  selector: 'ecp-search-chips',
  templateUrl: './search-chips.component.html',
  styleUrls: ['./search-chips.component.scss'],
})
export class SearchChipsComponent implements OnInit {
  public searchChips$: Observable<Array<string>>;
  public selectedFrameSize$: Observable<any[]>;
  public selectedBrands$: Observable<any[]>;
  public filter$: Observable<Filter>;

  constructor(private store: Store<AppState>) {}

  ngOnInit() {
    this.searchChips$ = this.store.pipe(
      select(state => state.catalog.filter.searchTerms),
    );
    this.selectedFrameSize$ = this.store.pipe(
      select(state => state.catalog.filter.selectedFrameSize),
    );
    this.selectedBrands$ = this.store.pipe(
      select(state => state.frames.selectedBrands),
    );
    this.filter$ = this.store.pipe(select(state => state.catalog.filter));
  }

  /**
   * removeSearchChip
   * @description remove the search chip term and refresh the data results
   * @param searchChip
   */
  removeSearchChip(searchChip) {
    this.store.dispatch({
      type: CatalogActionTypes.REMOVE_SEARCH_CHIP,
      payload: searchChip,
    });
  }
  removeFrameSize(frameSize) {
    this.store.dispatch({
      type: CatalogActionTypes.REMOVE_SELECTED_FRAMESIZE,
      payload: frameSize,
    });
  }
  removeBrand(brands) {
    this.store.dispatch({
      type: CatalogActionTypes.REMOVE_SELECTED_BRANDS,
      payload: brands,
    });
  }
  /**
   * clearSearchChips
   * @description This will first clear the chip search and then invoke the API call to show the entire user's catalog but will only return
   * the first 16 frames back to the user
   */
  clearSearchChips() {
    this.store.dispatch({
      type: CatalogActionTypes.CLEAR_ALL_FILTERS,
    });
  }
}
