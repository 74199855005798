import { Store } from '@ngrx/store';
import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { AuthService } from '@app/auth/auth.service';
import { AppState, AuthActionTypes } from '@app/core/ngrx';
import { Observable } from 'rxjs';

/**
 * AuthProtectedGuard
 */
@Injectable({ providedIn: 'root' })
export class AuthProtectedGuard implements CanActivate, CanActivateChild {
  /**
   * constructor
   * @param {Router} router
   * @param {Store<AppState>} store
   * @param {AuthService} authService
   */
  constructor(
    private router: Router,
    private store: Store<AppState>,
    private authService: AuthService,
  ) {}

  /**
   * validateUserAuthentication
   * @param {RouterStateSnapshot} state
   * @returns {Promise<boolean | UrlTree>}
   */
  async validateUserAuthentication(
    state: RouterStateSnapshot,
  ): Promise<boolean | UrlTree> {
    const authed = await this.authService.getAccessToken();

    if (!authed) {
      // try to obtain the token from the session
      this.router.navigateByUrl('/auth/sso');
      // this.store.dispatch({ type: AuthActionTypes.LOGOUT, payload: state.url });
      // // for local development we need a login instead of using AEM public
      // if (environment.envName === 'local') {
      //   return this.router.parseUrl('login');
      // }
      // return this.router.parseUrl('logout-redirect');
    }

    return true;
  }

  /**
   * canActivate
   * @param {ActivatedRouteSnapshot} route
   * @param {RouterStateSnapshot} state
   * @returns {Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean}
   */
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean {
    return this.validateUserAuthentication(state);
  }

  /**
   * canActivateChild
   * @param {ActivatedRouteSnapshot} route
   * @param {RouterStateSnapshot} state
   * @returns {Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean}
   */
  canActivateChild(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean {
    return this.validateUserAuthentication(state);
  }
}
