import { PatientCartActions, PatientCartActionTypes } from '../actions';

/**
 * ProgramsState
 */
export interface PatientCartState {
  loaded: boolean;
  pending: boolean;
  error: any;
  cart: any;
  searchTerm: string;
  printState: any;
}

/**
 * PROGRAMS_INITIAL_STATE
 * @type {{loaded: boolean; pending: boolean; error: null; profile: null}}
 */
export const PATIENT_CART_INITIAL_STATE: PatientCartState = {
  loaded: false,
  pending: false,
  error: null,
  cart: null,
  searchTerm: null,
  printState: {
    isDone: false,
    processedImage: 0,
    totalFrames: 0,
  },
};
/**
 * programsReducer
 * @param {ProgramsState} state
 * @param {ProgramsActions} action
 * @returns {ProgramsState}
 */
export const patientCartReducer = (
  state: PatientCartState = PATIENT_CART_INITIAL_STATE,
  action: PatientCartActions,
): PatientCartState => {
  switch (action.type) {
    case PatientCartActionTypes.LOAD_PATIENT_CART:
      return {
        ...state,
        error: null,
        loaded: false,
        pending: true,
      };
    case PatientCartActionTypes.LOAD_PATIENT_CART_SUCCESS:
      return {
        ...state,
        loaded: true,
        pending: false,
        cart: action.payload,
      };
    case PatientCartActionTypes.UPDATE_PRINT_TOTAL_FRAMES:
      return {
        ...state,
        printState: {
          ...state.printState,
          totalFrames: action.payload,
        },
      };
    case PatientCartActionTypes.UPDATE_PRINT_PROCESSED_IMAGE:
      return {
        ...state,
        printState: {
          ...state.printState,
          processedImage: action.payload,
        },
      };
    case PatientCartActionTypes.UPDATE_PRINT_DONE:
      return {
        ...state,
        printState: {
          isDone: action.payload,
        },
      };
    case PatientCartActionTypes.LOAD_PATIENT_CART_ERROR:
      return { ...state, error: action.payload };
    case PatientCartActionTypes.LOAD_PATIENT_CART_SEARCH:
      return { ...state, searchTerm: action.payload };
    case PatientCartActionTypes.CLEAR_PATIENT_CART_SEARCH:
      return { ...state, searchTerm: '' };
    default:
      return state;
  }
};
