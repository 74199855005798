/**
 * MyOrdersState
 */
import { MyOrders } from '@app/core/models';
import { MyOrdersActions, MyOrdersActionTypes } from '../actions';

export interface MyOrdersState {
  loaded: boolean;
  pending: boolean;
  error: any;
  list: MyOrders;
  searchTerm: string;
}

/**
 * MY_ORDERS_INITIAL_STATE
 * @type {{loaded: boolean; pending: boolean; error: null; profile: null}}
 */
export const MY_ORDERS_INITIAL_STATE: MyOrdersState = {
  loaded: false,
  pending: false,
  error: null,
  list: null,
  searchTerm: '',
};
/**
 * myOrdersReducer
 * @param {MyOrdersState} state
 * @param {MyOrdersActions} action
 * @returns {MyOrdersState}
 */
export const myOrdersReducer = (
  state: MyOrdersState = MY_ORDERS_INITIAL_STATE,
  action: MyOrdersActions,
): MyOrdersState => {
  switch (action.type) {
    case MyOrdersActionTypes.LOAD_MY_ORDERS:
      return {
        ...state,
        error: null,
        loaded: false,
        pending: true,
      };
    case MyOrdersActionTypes.LOAD_MY_ORDERS_SUCCESS:
      return {
        ...state,
        loaded: true,
        pending: false,
        list: action.payload,
      };
    case MyOrdersActionTypes.LOAD_MY_ORDERS_ERROR:
      return { ...state, error: action.payload };
    case MyOrdersActionTypes.SEARCH_MY_ORDERS:
      return {
        ...state,
        searchTerm: action.payload,
      };
    default:
      return state;
  }
};
