import { Injectable } from '@angular/core';
import { ImageService } from './image.service';
import * as pdfMake from 'pdfmake/build/pdfmake';
import * as pdfFonts from 'pdfmake/build/vfs_fonts';
import { PrintPdfModel, TableBody } from '@app/core/models/print-pdf.model';
import { Observable, Observer } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState, PatientCartActionTypes } from '../ngrx';
(<any>pdfMake).vfs = pdfFonts.pdfMake.vfs;

@Injectable({ providedIn: 'root' })
export class CartPrintService {
  processedImage = 0;
  totalFrames: any = 0;

  constructor(private imageService: ImageService, private store: Store<AppState>) {}

  printCart(cart, user) {
    cart = JSON.parse(JSON.stringify(cart));

    let reportHeaderImage;
    let reportFooterImage;
    /**
     * FMT-73 - R023 - (Customer Facing) Brand Export
     */
    switch (user.program.programKey) {
      case 'PERC':
        reportHeaderImage = '/assets/images/reports/report-header-perc.png';
        reportFooterImage = '/assets/images/reports/report-footer-perc.png';
        break;
      case 'VA':
        reportHeaderImage = '/assets/images/reports/report-header-va.png';
        reportFooterImage = '/assets/images/reports/report-footer-va.png';
        break;
      case 'PIV':
        reportHeaderImage = '/assets/images/reports/report-header-essilor.png';
        reportFooterImage = '/assets/images/reports/report-footer-essilor.png';
        break;
      case 'VWFD':
        reportHeaderImage = '/assets/images/reports/report-header-fd.png';
        reportFooterImage = '/assets/images/reports/report-footer-fd.png';
        break;
      case 'KPLR':
        reportHeaderImage = '/assets/images/reports/report-header-kp.png';
        reportFooterImage = '/assets/images/reports/report-footer-kp.png';
        break;
      default:
        reportHeaderImage = '/assets/images/reports/report-header-essilor.png';
        reportFooterImage = '/assets/images/reports/report-footer-essilor.png';
    }
    let date = new Date();
    let localDate = date.toLocaleDateString();
    try {
      this.setBase64(cart).then((framesWithBase64: Array<any>) => {
        this.getBase64ImageFromURL(reportHeaderImage).subscribe(base64data => {
          let headerImage = 'data:image/jpg;base64,' + base64data;
          this.getBase64ImageFromURL(reportFooterImage).subscribe(base64data2 => {
            let footerImage = 'data:image/jpg;base64,' + base64data2;
            const documentDefinition: any = {
              pageMargins: [40, 70, 40, 45],
              header: function(currentPage, pageCount) {
                return {
                  table: {
                    body: [
                      [
                        {
                          image: headerImage,
                          width: 600,
                          margin: [0, -2, 0, 0],
                        },
                      ],
                      [
                        {
                          text: localDate,
                          alignment: 'right',
                          bold: true,
                          style: 'normalText',
                          margin: [0, -40, 30, 0],
                        },
                      ],
                    ],
                  },
                  layout: 'noBorders',
                };
              },
              footer: function(currentPage, pageCount) {
                return {
                  table: {
                    body: [
                      [
                        {
                          image: footerImage,
                          width: 600,
                        },
                      ],
                      [
                        {
                          text: 'Page ' + currentPage.toString() + ' of ' + pageCount,
                          alignment: 'left',
                          style: 'normalText',
                          margin: [10, -40, 0, 0],
                        },
                      ],
                    ],
                  },
                  layout: 'noBorders',
                };
              },
              content: this.setContent(framesWithBase64, user),
              styles: {
                headerOneLine: {
                  color: 'black',
                  margin: [5, 7, 5, 2],
                  hLineColor: 'red',
                },
                headerTwoLine: {
                  color: 'black',
                  margin: [5, 2, 5, 2],
                },
                lineOneBGcolor: {
                  fillColor: '#f2f6f7',
                  margin: [5, 10, 5, 10],
                  bold: false,
                },
                lineTwoBGcolor: {
                  fillColor: '#edf1f2',
                  margin: [5, 10, 5, 10],
                  bold: false,
                },
                image: {
                  height: 27,
                  width: 50,
                },
              },
            };

            pdfMake.createPdf(documentDefinition).download(user.program.programName + '_collection.pdf');
            this.processedImage = 0;
            this.totalFrames = 0;
            this.store.dispatch({
              type: PatientCartActionTypes.UPDATE_PRINT_TOTAL_FRAMES,
              payload: 0,
            });
            this.store.dispatch({
              type: PatientCartActionTypes.UPDATE_PRINT_PROCESSED_IMAGE,
              payload: 0,
            });
          });
        });
      });
    } catch (e) {
      throw e;
    }
  }

  async setBase64(cart: Array<any>) {
    let frameTotal = 0;
    cart.forEach(data => {
      data.frames.forEach(frame => {
        frameTotal++;
      });
    });
    let frameCount = 0;
    this.totalFrames = frameTotal;
    this.store.dispatch({
      type: PatientCartActionTypes.UPDATE_PRINT_TOTAL_FRAMES,
      payload: frameTotal,
    });

    for (let collection of cart) {
      for (let frame of collection.frames) {
        frame['base64'] = await this.getBase64FromUrl(frame.smallImageUrl);
        frameCount++;
        this.processedImage = frameCount;
        this.store.dispatch({
          type: PatientCartActionTypes.UPDATE_PRINT_PROCESSED_IMAGE,
          payload: frameCount,
        });
      }
    }

    return cart;
  }

  getBase64ImageFromURL(url: string) {
    return new Observable((observer: Observer<string>) => {
      let img = new Image();
      img.crossOrigin = 'Anonymous';
      img.src = url;
      if (!img.complete) {
        img.onload = () => {
          observer.next(this.getBase64Image(img));
          observer.complete();
        };
        img.onerror = err => {
          observer.error(err);
        };
      } else {
        observer.next(this.getBase64Image(img));
        observer.complete();
      }
    });
  }
  async getBase64FromUrl(url: string) {
    try {
      let imageExt = url.split('/');
      let image = imageExt[6].split('.');
      let fpc = image[0];
      let imageData = await this.imageService.getImageData(fpc).toPromise();
      return imageData;
    } catch (e) {
      throw e;
    }
  }

  getBase64Image(img: HTMLImageElement) {
    let canvas = document.createElement('canvas');
    canvas.width = img.width;
    canvas.height = img.height;
    let ctx = canvas.getContext('2d');
    ctx.drawImage(img, 0, 0);
    let dataURL = canvas.toDataURL('image/png');
    return dataURL.replace(/^data:image\/(png|jpg);base64,/, '');
  }

  setContent(cart, user) {
    let _pdf = this;
    let content = [];
    let rowCount = 10;
    let tempRowCount = 0;

    cart.forEach(data => {
      let totalQty = 0;
      if (cart.indexOf(data) > 0) {
        content.push(_pdf.setPageBreak());
      }
      content.push(_pdf.setBrand(data));
      let remainingQty = totalQty % rowCount;
      let framesPerPage = _pdf.chunkFrames(data.frames, remainingQty, rowCount);
      framesPerPage.forEach(frames => {
        if (totalQty % 10 === 0 && totalQty !== 0) {
          _pdf.newPage(content, data);
        }
        //_pdf.newModule(content, moduleArr.categoryName, moduleArr.moduleName);
        content.push(_pdf.setTable(frames));
        totalQty += frames.length;
      });
    });
    return content;
  }

  newModule(content, category, moduleName) {
    let _pdf = this;
    content.push(_pdf.setCategory(category));
    content.push(_pdf.setModule(moduleName));
    return 0;
  }

  newPage(content, cart) {
    let _pdf = this;
    content.push(_pdf.setPageBreak());
    content.push(_pdf.setBrand(cart));
    return 0;
  }

  chunkFrames(frames, remainingQty, rowCount) {
    let qty = rowCount - remainingQty;
    let i,
      j,
      tempFrame,
      chunkFrames = [],
      chunk = rowCount;
    if (qty !== 0) {
      let _tempFrames = frames.slice(0, qty);
      chunkFrames.push(_tempFrames);
    }
    for (i = qty, j = frames.length; i < j; i += chunk) {
      tempFrame = frames.slice(i, i + chunk);
      chunkFrames.push(tempFrame);
    }
    return chunkFrames;
  }

  setPageBreak() {
    return {
      text: '',
      pageBreak: 'after',
    };
  }

  setBrand(val) {
    const d = new Date(val.createdOn);
    return {
      columns: [
        {
          stack: [
            {
              text: val.firstName + ' ' + val.lastName,
              bold: true,
              fontSize: 14,
            },
          ],
          alignment: 'left',
          width: '30%',
        },
        {
          text: d.toLocaleDateString(),
          alignment: 'right',
          fontSize: 15,
          bold: true,
          width: '70%',
        },
      ],
      columnGap: 10,
    };
  }

  setCategory(val) {
    return {
      text: val,
      alignment: 'center',
      fontSize: 12,
      bold: true,
      margin: [5, 15, 5, 5],
    };
  }

  setModule(val) {
    return {
      text: val,
      color: 'black',
      alignment: 'center',
      bold: true,
      fontSize: 10,
      margin: [5, 5, 5, 5],
    };
  }

  setRow(frame, backgroundColor): Array<TableBody> {
    let stack: Array<TableBody>;
    stack = [
      {
        stack: [
          {
            image: 'data:image/jpg;base64,' + frame.base64,
            height: 27,
            width: 50,
          },
        ],
        style: backgroundColor,
      },
      { text: frame.styleName, style: backgroundColor },
      { text: frame.colorDescription, style: backgroundColor },
      { text: frame.eyeSize + '-' + frame.temple, style: backgroundColor },
      { text: frame.upc, style: backgroundColor },
    ];
    return stack;
  }

  setTable(frames): PrintPdfModel {
    let data: PrintPdfModel = {
      // layout: 'lightHorizontalLines', // optional
      fontSize: 8,
      table: {
        // headers are automatically repeated if the table spans over multiple pages
        // you can declare how many rows should be treated as headers
        headerRows: 1,
        widths: ['*', '*', '*', '*', '*'],

        body: [
          [
            { text: 'Image', style: 'headerOneLine' },
            { text: 'Style', style: 'headerOneLine' },
            { text: 'Color', style: 'headerOneLine' },
            { text: 'Size', style: 'headerOneLine' },
            { text: 'UPC', style: 'headerOneLine' },
          ],
        ],
      },
      layout: {
        hLineWidth: function(i, node) {
          return i <= 1 ? 1 : 0;
        },
        vLineWidth: function(i, node) {
          return 0;
        },
        hLineColor: function(i, node) {
          return i <= 1 ? '#ccc' : 'gray';
        },
      },
    };
    frames.forEach((frame, index) => {
      let color = 'lineOneBGcolor';
      if (index % 2 === 0) color = 'lineOneBGcolor';
      else color = 'lineTwoBGcolor';
      let el = this.setRow(frame, color);
      data.table.body.push(el);
    });
    return data;
  }
}
