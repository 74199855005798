import { LayoutActions, LayoutActionTypes } from '../actions';

/**
 * LayoutState
 */
export interface LayoutState {
  sidenav: {
    open: boolean;
    target: string;
  };
}

/**
 * LAYOUT_INITIAL_STATE
 * @type {{sidenav: {open: boolean; target: string}}}
 */
export const LAYOUT_INITIAL_STATE: LayoutState = {
  sidenav: {
    open: false,
    target: '',
  },
};

/**
 * layoutReducer
 * @param {LayoutState} state
 * @param {LayoutActions} action
 * @returns {LayoutState}
 */
export const layoutReducer = (
  state: LayoutState = LAYOUT_INITIAL_STATE,
  action: LayoutActions,
): LayoutState => {
  switch (action.type) {
    case LayoutActionTypes.TOGGLE_SIDENAV:
      return {
        ...state,
        sidenav: {
          open: !state.sidenav.open,
          target: action.target,
        },
      };
    case LayoutActionTypes.OPEN_SIDENAV:
      return {
        ...state,
        sidenav: {
          open: true,
          target: action.target,
        },
      };
    case LayoutActionTypes.CLOSE_SIDENAV:
      return {
        ...state,
        sidenav: {
          open: false,
          target: '',
        },
      };
    default:
      return state;
  }
};
