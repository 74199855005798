import { State, Store } from '@ngrx/store';
import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError, tap, timeout } from 'rxjs/operators';
import { AppState, UserActionTypes } from '@app/core/ngrx';
import { UserService } from '../services';

/**
 * UserResolver
 */
@Injectable({ providedIn: 'root' })
export class UserResolver implements Resolve<Observable<any>> {
  /**
   * constructor
   * @param {Store<AppState>} store
   * @param {UserService} userService
   * @param state
   */
  constructor(
    private store: Store<AppState>,
    private userService: UserService,
    private state: State<AppState>,
  ) {}

  /**
   * resolve
   * @returns {Observable<any>}
   */
  resolve(): Observable<any> {
    return this.userService.getUser().pipe(
      timeout(5000),
      tap(res => {
        this.store.dispatch({
          type: UserActionTypes.LOAD_USER_SUCCESS,
          payload: res,
        });
      }),
      catchError(err => {
        this.store.dispatch({
          type: UserActionTypes.LOAD_USER_ERROR,
          payload: err,
        });

        return of(null);
      }),
    );
  }
}
