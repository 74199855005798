<div [class]="theme ? theme : 'one-portal'">
  <ecp-portal>
    <router-outlet></router-outlet>
  </ecp-portal>
</div>

<div *ngIf="tenant" ecpDynamicChatScript>
  <!-- most tags work, but Angular strips script tags, so don't try it. -->
  <div>
    {{ tenant }}
  </div>
</div>
