import { Component, Input, OnInit } from '@angular/core';
import { AppState, FramesCartActionTypes } from '@app/core/ngrx';

import { Store, State } from '@ngrx/store';
import { MatDialog } from '@angular/material/dialog';
import { Frame } from '@app/core/models';
import { AddToCartNotificationsComponent } from '@app/shared/components/add-to-cart-notifications/add-to-cart-notifications.component';
import { NotificationService } from '@app/core/services/notification.service';

@Component({
  selector: 'ecp-add-to-cart-btn',
  templateUrl: './add-to-cart-btn.component.html',
  styleUrls: ['./add-to-cart-btn.component.scss'],
})
export class AddToCartBtnComponent implements OnInit {
  @Input() patientId;
  @Input() frameDetail: Frame;
  constructor(
    private store: Store<AppState>,
    private dialog: MatDialog,
    private state: State<AppState>,
    private notificationService: NotificationService,
  ) {}

  ngOnInit() {}

  get isButtonDisabled() {
    return (
      this.state
        .getValue()
        .framesCart.framesCart.filter(cart => cart.fpc === this.frameDetail.fpc)
        .length > 0
    );
  }

  addFrameToCart() {
    if (
      this.state.getValue().framesCart &&
      this.state.getValue().framesCart.framesCart.length < 10
    ) {
      this.store.dispatch({
        type: FramesCartActionTypes.ADD_FRAMES_TO_CART,
        payload: {
          brandName: this.frameDetail.brandName,
          smallImageUrl: this.frameDetail.smallImageUrl,
          largeImageUrl: this.frameDetail.largeImageUrl,
          styleName: this.frameDetail.styleName,
          colorDescription: this.frameDetail.colorDescription,
          eyeSize: this.frameDetail.eyeSize,
          temple: this.frameDetail.temple,
          upc: this.frameDetail.upc,
          fpc: this.frameDetail.fpc,
        },
      });
      this.dialog.open(AddToCartNotificationsComponent, {
        maxWidth: '400px',
        data: {
          patientId: this.patientId,
        },
      });
    } else {
      let notificationOptions = {
        title: 'Notification',
        contentHtml: 'Only 10 frames per cart is allowed.',
        confirmBtnText: 'Close',
      };
      this.notificationService.error(notificationOptions);
    }
  }
}
