import { Component, OnInit } from '@angular/core';
import { AuthService } from '../auth.service';
import { AppState, AuthActionTypes } from '@app/core/ngrx';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { OktaAuth, OktaAuthOptions } from '@okta/okta-auth-js';
import { environment } from '@env/environment';

@Component({
  selector: 'ecp-sso',
  template: `
    <div class="loader">
      <div class="loader-icon">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  `,
})
export class AuthSSOComponent implements OnInit {
  config: OktaAuthOptions = environment.okta.client;
  authClient = new OktaAuth(this.config);
  constructor(
    private authService: AuthService,
    private store: Store<AppState>,
    private router: Router,
  ) {}

  ngOnInit(): void {
    this.authClient.token
      .getWithoutPrompt({
        responseType: 'id_token', // or array of types
        sessionToken: 'testSessionToken', // optional if the user has an existing Okta session
      })
      .then(res => {
        let tokens = res.tokens;

        // Do something with tokens, such as
        this.authClient.tokenManager.add('id_token', tokens.idToken);
        this.authClient.tokenManager.add('access_token', tokens.accessToken);

        this.router.navigateByUrl(
          window.localStorage.getItem('returnUrl') || 'dashboard',
          { replaceUrl: true },
        );

        this.store.dispatch({ type: AuthActionTypes.LOGIN_SUCCESS });
        window.localStorage.removeItem('returnUrl');
      })
      .catch(err => {
        this.store.dispatch({
          type: AuthActionTypes.LOGIN_ERROR,
          payload: err,
        });
        this.store.dispatch({ type: AuthActionTypes.LOGOUT });
      });
  }
}
