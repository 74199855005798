import { Catalog } from '@app/core/models/catalog.model';
import { FramesActions, FramesActionTypes } from '../actions';

/**
 * FramesState
 */
export interface RelatedFramesState {
  loaded: boolean;
  pending: boolean;
  error: any;
  list: Catalog;
}

/**
 * FRAMES_INITIAL_STATE
 * @type {{loaded: boolean; pending: boolean; error: null; profile: null}}
 */
export const RELATED_FRAMES_INITIAL_STATE: RelatedFramesState = {
  loaded: false,
  pending: false,
  error: null,
  list: {
    frames: [],
    totalResults: 0,
  },
};
/**
 * framesReducer
 * @param {FramesState} state
 * @param {FramesActions} action
 * @returns {FramesState}
 */
export const relatedFramesReducer = (
  state: RelatedFramesState = RELATED_FRAMES_INITIAL_STATE,
  action: FramesActions,
): RelatedFramesState => {
  switch (action.type) {
    case FramesActionTypes.LOAD_RELATED_FRAMES:
    case FramesActionTypes.LOAD_PATIENT_RELATED_FRAMES:
      return {
        ...state,
        error: null,
        loaded: false,
        pending: true,
      };
    case FramesActionTypes.LOAD_RELATED_FRAMES_SUCCESS:
      return {
        ...state,
        loaded: true,
        pending: false,
        list: {
          frames: action.payload['frames'],
          totalResults: action.payload['totalResults'],
        },
      };
    case FramesActionTypes.CLEAR_RELATED_FRAMES:
      return {
        ...state,
        list: {
          frames: [],
          totalResults: 0,
        },
      };
    default:
      return state;
  }
};
