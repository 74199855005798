import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { Router, NavigationStart } from '@angular/router';
import { filter } from 'rxjs/operators';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs/internal/Observable';
import { VWProgram } from '@app/core/models';
import { AppState, selectProgram } from '@app/core/ngrx';

@Component({
  selector: 'ecp-login-dialog',
  templateUrl: './login-dialog.component.html',
  styleUrls: ['./login-dialog.component.scss'],
})
export class LoginDialogComponent implements OnInit, OnDestroy {
  // @select(['user', 'profile', 'theme'])
  public theme$: Observable<string>;
  subscription: Subscription;
  // @select(['user', 'profile', 'program'])
  public program$: Observable<VWProgram>;
  theme = 'one-portal';

  constructor(
    public dialogRef: MatDialogRef<LoginDialogComponent>,
    private router: Router,
    private store: Store<AppState>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {}

  ngOnInit(): void {
    this.theme$ = this.store.pipe(
      select((state: any) => state.user.profile.theme),
    );
    this.program$ = this.store.pipe(select(selectProgram));
    this.program$.pipe().subscribe(program => {
      if (program) {
        this.theme = program.programKey;
      }
    });
    this.subscription = this.router.events
      .pipe(filter(event => event instanceof NavigationStart))
      .subscribe(() => {
        this.dialogRef.close();
      });
  }

  ngOnDestroy(): void {
    if (this.subscription) this.subscription.unsubscribe();
  }
}
