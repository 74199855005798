import { Injectable } from '@angular/core';

/**
 * MyGlobal
 */
export interface MyGlobal {
  dataLayer: object[];
}

/**
 * GlobalRef
 */
export abstract class GlobalRef {
  abstract get nativeGlobal(): MyGlobal;
}

/**
 * BrowserGlobalRef
 */
@Injectable()
export class BrowserGlobalRef extends GlobalRef {
  /**
   * window
   */
  window: any;

  /**
   * constructor
   */
  constructor() {
    super();
    this.window = window;
  }

  /**
   * nativeGlobal
   * @returns {MyGlobal}
   */
  get nativeGlobal(): MyGlobal {
    return this.window as MyGlobal;
  }
}
