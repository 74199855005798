import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { AuthService } from '@app/auth/auth.service';
import { Observable } from 'rxjs';
import { environment } from '@env/environment';

/**
 * AuthPublicGuard
 */
@Injectable({ providedIn: 'root' })
export class AuthPublicGuard implements CanActivate, CanActivateChild {
  /**
   * constructor
   * @param {AuthService} authService
   * @param {Router} router
   */
  constructor(private authService: AuthService, private router: Router) {}

  /**
   * validate
   * @returns {Promise<boolean | UrlTree>}
   */
  private async validate(): Promise<boolean | UrlTree> {
    const authed = await this.authService.getAccessToken();
    if (authed) return this.router.parseUrl(environment.okta.authRedirectUrl);
    return true;
  }

  /**
   * canActivate
   * @param {ActivatedRouteSnapshot} route
   * @param {RouterStateSnapshot} state
   * @returns {Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean}
   */
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean {
    return this.validate();
  }

  /**
   * canActivateChild
   * @param {ActivatedRouteSnapshot} route
   * @param {RouterStateSnapshot} state
   * @returns {Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean}
   */
  canActivateChild(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean {
    return this.validate();
  }
}
