import { Injectable } from '@angular/core';
import { MenuItem } from '@app/core/models';

@Injectable({ providedIn: 'root' })
export class NavigationService {
  constructor() {}

  filter(items: MenuItem[]): MenuItem[] {
    return items.reduce((acc, item) => {
      const { entitlement } = item;
      return [...acc, item];
    }, []);
  }
}
