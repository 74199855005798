/**
 * RealignmentOrdersState
 */
import { RealignmentOrders } from '@app/core/models';
import { RealignmentOrdersActions, RealignmentOrdersActionTypes } from '../actions';

export interface RealignmentOrdersState {
  loaded: boolean;
  pending: boolean;
  error: any;
  list: RealignmentOrders;
  searchTerm: string;
}

/**
 * MY_ORDERS_INITIAL_STATE
 * @type {{loaded: boolean; pending: boolean; error: null; profile: null}}
 */
export const REALIGNMENT_ORDERS_INITIAL_STATE: RealignmentOrdersState = {
  loaded: false,
  pending: false,
  error: null,
  list: null,
  searchTerm: '',
};
/**
 * RealignmentOrdersReducer
 * @param {RealignmentOrdersState} state
 * @param {RealignmentOrdersActions} action
 * @returns {RealignmentOrdersState}
 */
export const realignmentOrdersReducer = (
  state: RealignmentOrdersState = REALIGNMENT_ORDERS_INITIAL_STATE,
  action: RealignmentOrdersActions,
): RealignmentOrdersState => {
  window.console.log(action.type);
  switch (action.type) {
    case RealignmentOrdersActionTypes.LOAD_REALIGNMENT_ORDERS:
      return {
        ...state,
        error: null,
        loaded: false,
        pending: true,
      };
    case RealignmentOrdersActionTypes.LOAD_REALIGNMENT_ORDERS_SUCCESS:
      return {
        ...state,
        loaded: true,
        pending: false,
        list: action.payload,
      };
    case RealignmentOrdersActionTypes.LOAD_REALIGNMENT_ORDERS_ERROR:
      return { ...state, error: action.payload };
    case RealignmentOrdersActionTypes.SEARCH_REALIGNMENT_ORDERS:
      return {
        ...state,
        searchTerm: action.payload,
      };
    default:
      return state;
  }
};
