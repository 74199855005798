import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { filter, map, mergeMap } from 'rxjs/operators';
import { Store, select, State } from '@ngrx/store';

import { AppState, AuthActionTypes, selectProgram } from './core/ngrx';
import { AuthService } from './auth/auth.service';
import { VWProgram } from '@app/core/models';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'ecp-root',
  templateUrl: './app.component.html',
})
export class AppComponent implements OnInit {
  // @select(['auth', 'error'])
  authError$: Observable<any>;
  /**
   * loading$
   */
  // @select(['user', 'pending'])
  loading$: Observable<boolean>;
  // @select(['user', 'profile', 'program'])
  public program$: Observable<VWProgram>;
  // @select(['user', 'error'])
  error$: Observable<any>;
  theme = 'one-portal';
  tenant: string | null;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private titleService: Title,
    private authService: AuthService,
    private store: Store<AppState>,
    private _snackBar: MatSnackBar,
    private state: State<AppState>,
  ) {
    this.error$ = this.store.pipe(select(state$ => state$.user.error));
    this.error$.subscribe(err => {
      if (err) {
        // if (err.name === 'TimeoutError') {
        //   this.openSnackBar(
        //     'There was a problem loading the user profile. Please try again later.',
        //     'Close',
        //   );
        // }
      }
    });
  }

  ngOnInit(): void {
    this.authError$ = this.store.pipe(select(state => state.auth.error));
    this.loading$ = this.store.pipe(select(state => state.user.pending));
    this.program$ = this.store.pipe(select(selectProgram));
    this.error$ = this.store.pipe(select(state => state.user.error));
    this.store.pipe(select(state => state.user)).subscribe(user => {
      const tenant = user?.profile?.program?.programKey;
      this.tenant = tenant;
    });
    this.program$.pipe().subscribe(program => {
      if (this.state.getValue().user.patientID !== '') {
        this.theme = 'VWFD';
        return;
      }
      if (program) {
        this.theme = program.programKey;
      }
    });
    this.listenToNavigationEnd().subscribe(data => {
      window.scrollTo(0, 0);
      this.updatePageTitle(data['title']);
    });

    this.authService.getAccessToken().then(token => {
      if (token) this.store.dispatch({ type: AuthActionTypes.LOGIN_SUCCESS });
    });
  }

  private openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action);
    setTimeout(() => {
      this.router.navigateByUrl('/auth/logout');
    }, 50);
  }

  private updatePageTitle(title: string): void {
    if (!title) return this.titleService.setTitle(`MyFrameBoard® Portal`);
    this.titleService.setTitle(`${title} | MyFrameBoard® Portal`);
  }

  private listenToNavigationEnd(): Observable<any> {
    return this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      map(() => this.activatedRoute),
      map((route: ActivatedRoute) => {
        while (route.firstChild) route = route.firstChild;
        return route;
      }),
      filter(route => route.outlet === 'primary'),
      mergeMap(route => route.data),
    );
  }
}
