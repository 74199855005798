import { Store } from '@ngrx/store';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@app/auth/auth.service';
import { AppState, AuthActionTypes } from '@app/core/ngrx';
import { environment } from '@env/environment';
import { from, Observable } from 'rxjs';
import { catchError, switchMap, tap } from 'rxjs/operators';
import { VwService } from '@app/core/services';
import { throwError } from 'rxjs/internal/observable/throwError';

/**
 * AuthInterceptor
 */
@Injectable({ providedIn: 'root' })
export class AuthInterceptor implements HttpInterceptor {
  /**
   * constructor
   * @param {AuthService} authService
   * @param {Store<AppState>} store
   * @param {Router} router
   */
  constructor(
    private authService: AuthService,
    private vwService: VwService,
    private store: Store<AppState>,
    private router: Router,
  ) {}

  /**
   * Intercept all (Okta/Mock API) request and check if matches base API url,
   * if yes, the it will set http headers to send over the
   * access token necessary to fulfill the api requests.
   *
   * @param req HttpRequest
   */
  async setAuthenticationToken(req: HttpRequest<any>, next: HttpHandler): Promise<HttpRequest<any>> {
    const token: any = await this.authService.getAccessToken();
    const vwToken = window.localStorage.getItem('token');
    if (token && vwToken) {
      const reqWithAuthorization = req.clone({
        setHeaders: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: `Bearer ${token.accessToken}`,
          Token: `${vwToken}`,
        },
      });

      return reqWithAuthorization;
    } else if (token) {
      const reqWithAuthorization = req.clone({
        setHeaders: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: `Bearer ${token.accessToken}`,
        },
      });
      return reqWithAuthorization;
    }

    return req;
  }

  /**
   * intercept
   * @param {HttpRequest<any>} request
   * @param {HttpHandler} next
   * @returns {Observable<HttpEvent<any>>}
   */
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // console.log('* AuthInterceptor *');
    // console.log('* AuthInterceptor - request.url => ', request.url);
    // console.log('* AuthInterceptor - environment.apiUrl => ', environment.apiUrl);
    // console.log(
    //   '* AuthInterceptor - request.url.startsWith(environment.apiUrl) => ',
    //   request.url.startsWith(environment.apiUrl),
    // );
    if (request.url.startsWith(environment.apiUrl) && !request.url.endsWith('/user/resetpassword')) {
      return from(this.setAuthenticationToken(request, next)).pipe(
        tap(res => console.log('------- res => ', res)),
        switchMap((req: HttpRequest<any>) => next.handle(req)),
        catchError((err: any) => {
          // if any API returns a 401 we will log out the user and redirect them to log in again
          if (err instanceof HttpErrorResponse) {
            if (err.status === 401) {
              this.store.dispatch({
                type: AuthActionTypes.LOGOUT,
                payload: this.router.url,
              });
              this.authService.logout();
              return throwError(err);
            }
          }
        }),
      );
    }
    return next.handle(request);
  }
}
