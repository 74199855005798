import { Component, OnInit, HostBinding, HostListener } from '@angular/core';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { Store, select, State } from '@ngrx/store';
import { ChatService } from '@app/core/services';
import { AppState, LayoutActionTypes, selectPatientId, selectProfile } from '@app/core/ngrx';
import { User } from '@app/core/models';

@Component({
  selector: 'ecp-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  @HostBinding('class.header') header_ = true;
  @HostBinding('class.shrink') shrink = false;

  private breakpoint = '(max-width: 899px)';

  // @select(['layout', 'sidenav'])
  sideNav$: Observable<any>;

  // @select(['auth', 'authenticated'])
  authenticated$: Observable<any>;

  // @select(['user', 'profile'])
  user$: Observable<User>;
  patientId$: Observable<string>;

  defaultLogo = 'assets/images/essilor-logo-white.svg';
  patientId;
  chatStatus = 'Offline';
  constructor(
    private breakpointObserver: BreakpointObserver,
    private store: Store<AppState>,
    private state: State<AppState>,
    private chatService: ChatService,
  ) {
    // tslint:disable-next-line:no-shadowed-variable
    this.user$ = this.store.pipe(select(selectProfile));
    this.user$.subscribe(user => {
      if (user) {
        this.defaultLogo = user.program?.logoPath;
      }
      this.patientId = this.state.getValue().user.patientID;
    });
    this.patientId$ = this.store.select(selectPatientId);
  }

  ngOnInit(): void {
    this.sideNav$ = this.store.pipe(select(state => state.layout.sidenav));
    this.authenticated$ = this.store.pipe(select(state => state.auth.authenticated));
    this.user$ = this.store.pipe(select(selectProfile));
    this.initBreakpointObserver();
  }

  initBreakpointObserver(): void {
    this.breakpointObserver.observe([this.breakpoint]).subscribe((state: BreakpointState) => {
      if (!state.matches) {
        this.store.dispatch({ type: LayoutActionTypes.CLOSE_SIDENAV });
      }
    });
  }

  @HostListener('window:scroll', ['$event'])
  onPageScroll(): void {
    this.shrink = document.documentElement.scrollTop >= 70;
  }

  toggleMainMenu(): void {
    this.store.dispatch({
      type: LayoutActionTypes.TOGGLE_SIDENAV,
      target: 'menu',
    });
  }

  toggleUserMenu(): void {
    this.store.dispatch({
      type: LayoutActionTypes.TOGGLE_SIDENAV,
      target: 'user',
    });
  }
}
