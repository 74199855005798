<ng-container *ngIf="link; else linkless">
  <ng-container *ngIf="fragment; else defaultTpl">
    <a [title]="title || label" [attr.aria-label]="title || label" [routerLink]="link" [fragment]="fragment">
      <fa-icon *ngIf="icon" [icon]="icon"></fa-icon>
      <span *ngIf="label">{{ label }}</span>
    </a>
  </ng-container>
  <ng-template #defaultTpl>
    <a
      *ngIf="!ext"
      [title]="title || label"
      [attr.aria-label]="title || label"
      [routerLink]="link"
      routerLinkActive="is-active"
      [routerLinkActiveOptions]="{ exact: true }"
    >
      <fa-icon *ngIf="icon" [icon]="icon"></fa-icon>
      <span *ngIf="label">{{ label }}</span>
    </a>
    <a *ngIf="ext" [title]="title || label" [attr.aria-label]="title || label" [href]="link" target="_blank">
      <fa-icon *ngIf="icon" [icon]="icon"></fa-icon>
      <span *ngIf="label">&nbsp;{{ label }}</span>
    </a>
  </ng-template>
</ng-container>

<ng-template #linkless>
  <ng-container *ngIf="noLink; else linked">
    <div class="noLink">
      <fa-icon *ngIf="icon" [icon]="icon"></fa-icon>
      <span *ngIf="label">{{ label }}</span>
    </div>
  </ng-container>
  <ng-template #linked>
    <a *ngIf="!link" [title]="title || label" [attr.aria-label]="title || label" href="javascript:;">
      <fa-icon *ngIf="icon" [icon]="icon"></fa-icon>
      <span *ngIf="label">{{ label }}</span>
      <span *ngIf="(framesCart$ | async).length" class="count">{{ (framesCart$ | async).length }}</span>
    </a>
  </ng-template>
</ng-template>

<ng-content *ngIf="!children?.length"></ng-content>

<div class="nav__item__dropdown-wrapper" *ngIf="children?.length">
  <div>
    <ng-container *ngIf="grouped; else default">
      <div *ngFor="let item of children" class="nav__item__dropdown">
        <span>{{ item.label }}</span>
        <ng-container *ngFor="let subitem of item.children">
          <a *ngIf="subitem.ext" [href]="subitem.link" target="_blank">{{ subitem.label }}</a>
          <a
            ecpNavLink
            *ngIf="!subitem.ext"
            [attr.aria-label]="subitem.label"
            [routerLink]="subitem.link"
            routerLinkActive="is-active"
            [routerLinkActiveOptions]="{ exact: true }"
          >
            {{ subitem.label }}
          </a>
        </ng-container>
      </div>
    </ng-container>

    <ng-template #default>
      <div class="nav__item__dropdown">
        <ng-container *ngFor="let item of children">
          <a *ngIf="item.ext" [href]="item.link" target="_blank">{{ item.label }}</a>
          <a
            ecpNavLink
            *ngIf="!item.ext"
            [title]="item.label"
            [attr.aria-label]="item.label"
            [routerLink]="item.link"
            routerLinkActive="is-active"
            [routerLinkActiveOptions]="{ exact: true }"
          >
            {{ item.label }}
          </a>
        </ng-container>
      </div>
    </ng-template>
  </div>
</div>
