import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AlertDialogComponent } from '@app/shared/components/dialogs/alert-dialog/alert-dialog.component';

/**
 * NotifyOptions
 */
interface NotifyOptions {
  icon?: string;
  title?: string;
  content?: string;
  contentHtml?: string;
  confirmBtnText?: string;
}

/**
 * NotificationService
 */
@Injectable({ providedIn: 'root' })
export class NotificationService {
  /**
   * constructor
   * @param {MatDialog} dialog
   */
  constructor(private dialog: MatDialog) {}

  /**
   * error
   * @param {NotifyOptions} options
   */
  error(options: NotifyOptions): void {
    this.dialog.open(AlertDialogComponent, {
      disableClose: true,
      closeOnNavigation: false,
      id: 'error-alert-dialog',
      panelClass: 'error-alert-dialog',
      width: '364px',
      maxWidth: '90vw',
      data: {
        ...options,
      },
    });
  }
}
