import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { of } from 'rxjs';
import { environment } from '@env/environment';
//import * as txml from 'txml';
import { ChatApiService } from '@app/core/services/chat-api.service';
export interface AgentAvailbility {
  tagName: string;
  attributes: AgentAvailbilityAttributes;
  children: string[];
}
export interface AgentAvailbilityAttributes {
  'xmlns:ns5': string;
  'xmlns:ns2': string;
  'xmlns:ns4': string;
  'xmlns:ns3': string;
  available: string;
}
/**
 * UserService
 */
@Injectable({ providedIn: 'root' })
export class ChatService {
  xmlrespMOCK =
    '<agentAvailability xmlns:ns5="http://jabber.org/protocol/httpbind" xmlns:ns2="http://bindings.egain.com/chat" xmlns:ns4="urn:ietf:params:xml:ns:xmpp-stanzas" xmlns:ns3="jabber:client" available="false"/>';
  /**
   * constructor
   * @param {ChatApiService} apiService
   * @param {HttpClient} http
   */
  constructor(private apiService: ChatApiService, private http: HttpClient) {}
  /**
   * getUser
   * @returns {Observable<User>}
   */
}
