import { Component, Inject, HostListener } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs/internal/Observable';
import { VWProgram } from '@app/core/models';
import { AppState, selectProgram } from '@app/core/ngrx';

@Component({
  selector: 'ecp-alert-dialog',
  templateUrl: './alert-dialog.component.html',
  styleUrls: ['./alert-dialog.component.scss'],
})
export class AlertDialogComponent {
  // @select(['user', 'profile', 'program'])
  public program$: Observable<VWProgram>;
  theme = 'one-portal';
  constructor(
    private dialogRef: MatDialogRef<AlertDialogComponent>,
    private store: Store<AppState>,
    @Inject(MAT_DIALOG_DATA) public data,
  ) {
    this.program$ = this.store.pipe(select(selectProgram));
    this.program$.pipe().subscribe(program => {
      if (program) {
        this.theme = program.programKey;
      }
    });
  }

  @HostListener('document:keydown.escape')
  closeDialog() {
    this.dialogRef.close();
  }
}
