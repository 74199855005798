<mat-card class="mat-card--widget">
  <mat-card-content>
    <div class="container">
      <div class="row">
        <form (submit)="submitSearch()" [formGroup]="form">
          <div *ngIf="!isBrandsShown" class="col s12 m10">
            <mat-form-field>
              <input
                matInput
                autoFocus
                placeholder="Search"
                formControlName="searchText"
              /><!-- (keyup)="search$.next($event)" -->
              <mat-hint>Enter or scan a UPC to find a frame, or search on any criteria</mat-hint>
            </mat-form-field>
          </div>
          <div *ngIf="!isBrandsShown" class="col s12 m2 align-search">
            <button mat-icon-button class="btn btn-secondary align-left" aria-label="Search Catalog" type="submit">
              <mat-icon>search</mat-icon>
              Search
            </button>
          </div>
          <div class="col s12 m12 l12">
            <div *ngIf="isBrandsShown" class="brand__button">
              <!-- Filter display by Brand -->
              <ng-container>
                <div class="" *ngFor="let item of brands">
                  <button
                    style="margin: 2px;"
                    class="btn btn-primary"
                    [name]="item.name"
                    (click)="checkBrandList(item.name)"
                    [ngClass]="[brandsSelected.includes(item.name) ? 'btn-primary' : 'btn-tertiary']"
                  >
                    {{ item.name }}
                  </button>
                </div>
              </ng-container>
            </div>
          </div>
        </form>
      </div>
    </div>
  </mat-card-content>
</mat-card>
