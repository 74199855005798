import { Component, OnInit, Input, HostBinding, NgZone } from '@angular/core';
import { BrowserGlobalRef } from '@app/shared/global-ref';
import { Router } from '@angular/router';
import { AppState, CatalogActionTypes } from '@app/core/ngrx';
import { Store } from '@ngrx/store';
import { Frame, Frames } from '@app/core/models';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'ecp-browse-catalog-frame-item',
  templateUrl: 'catalog-frame-item.component.html',
  styleUrls: ['./catalog-frame-item.component.scss'],
})
export class CatalogFrameItemComponent implements OnInit {
  @HostBinding('class.product-item') product_item_ = true;
  @Input() upc: string;
  @Input() title: string;
  @Input() name: string;
  @Input() image: string;
  @Input() desc1: string;
  @Input() desc2: string;
  @Input() clusterName: string;
  @Input() size: string;
  @Input() isOrderable: boolean;
  @Input() user;
  @Input() patientId;
  @Input() frame: Frames;
  @Input() frameDetail: Frame;
  isAddingToFrameCart: boolean;
  @Input() eligiblaForCustomization : boolean

  constructor(
    private _ngZone: NgZone,
    private router: Router,
    private globalRef: BrowserGlobalRef,
    private store: Store<AppState>,
    private dialog: MatDialog,
  ) {}

  ngOnInit(): void {
    this.globalRef.nativeGlobal.dataLayer.push({
      event: 'product portfolio_displayed',
      description: this.title,
      name: this.name,
    });
  }

  goToFrameOrder() {
    let url = this.patientId ? '/order/' + this.upc + '/patient/' + this.patientId : '/order/' + this.upc;
    this._ngZone.run(() => this.router.navigateByUrl(url));
    this.store.dispatch({ type: CatalogActionTypes.HALT_SEARCH });
  }
}
