<form [formGroup]="form" (submit)="handleSubmit($event)">
  <div class="form-wrapper reset-password">
    <mat-form-field>
      <mat-label>Old Password or Temp Password</mat-label>
      <input
        matInput
        [type]="hideTemp ? 'text' : 'password'"
        name="tempPassword"
        formControlName="tempPassword"
        autocomplete="temp-password"
        required
      />
      <mat-hint>If your password expired, this should be your old password. If your forgot your password, this should be the
      temporary password from your email.</mat-hint>
      <mat-error *ngIf="tempPassword.errors?.required">
        <fa-icon icon="exclamation-triangle"></fa-icon>
        Password is required.
      </mat-error>

      <mat-error *ngIf="tempPassword.dirty && tempPassword.errors?.isShort">
        <fa-icon icon="exclamation-triangle"></fa-icon>
        The password must have at least 8 characters.
      </mat-error>

      <mat-error *ngIf="tempPassword.dirty && tempPassword.errors?.atLeastOneNumber">
        <fa-icon icon="exclamation-triangle"></fa-icon>
        The password must include at least 1 number.
      </mat-error>

      <mat-error *ngIf="tempPassword.dirty && tempPassword.errors?.atLeastOneUpperCase">
        <fa-icon icon="exclamation-triangle"></fa-icon>
        The password must include at least 1 upper case letter.
      </mat-error>

      <mat-error *ngIf="tempPassword.dirty && tempPassword.errors?.atLeastOneLowerCase">
        <fa-icon icon="exclamation-triangle"></fa-icon>
        The password must include at least 1 lower case letter.
      </mat-error>

      <mat-error *ngIf="tempPassword.dirty && tempPassword.errors?.notIncludeFromEmailAddress">
        <fa-icon icon="exclamation-triangle"></fa-icon>
        The password must not include a sequence of three or more characters from your email address (before the @
        symbol)
      </mat-error>

      <mat-error *ngIf="password.dirty && password.errors?.characterInvalid">
        <fa-icon icon="exclamation-triangle"></fa-icon>
        The password only can include (#,$,%,&,@,!) symbols.
      </mat-error>
      <mat-icon matSuffix (click)="hideTemp = !hideTemp">{{ hide ? 'visibility_off' : 'visibility' }}</mat-icon>
    </mat-form-field>

    <mat-form-field>
      <mat-label>New Password</mat-label>
      <input
        matInput
        [type]="hideNew ? 'text' : 'password'"
        name="newPassword"
        formControlName="password"
        (focus)="onPasswordFocused($event)"
        autocomplete="new-password"
        required
      />

      <mat-error *ngIf="password.errors?.required">
        <fa-icon icon="exclamation-triangle"></fa-icon>
        Password is required.
      </mat-error>

      <mat-error *ngIf="password.dirty && password.errors?.isShort">
        <fa-icon icon="exclamation-triangle"></fa-icon>
        The password must have at least 8 characters.
      </mat-error>

      <mat-error *ngIf="password.dirty && password.errors?.atLeastOneNumber">
        <fa-icon icon="exclamation-triangle"></fa-icon>
        The password must include at least 1 number.
      </mat-error>

      <mat-error *ngIf="password.dirty && password.errors?.atLeastOneUpperCase">
        <fa-icon icon="exclamation-triangle"></fa-icon>
        The password must include at least 1 upper case letter.
      </mat-error>

      <mat-error *ngIf="password.dirty && password.errors?.atLeastOneLowerCase">
        <fa-icon icon="exclamation-triangle"></fa-icon>
        The password must include at least 1 lower case letter.
      </mat-error>

      <mat-error *ngIf="password.dirty && password.errors?.notIncludeFromEmailAddress">
        <fa-icon icon="exclamation-triangle"></fa-icon>
        The password must not include a sequence of three or more characters from your email address (before the @
        symbol)
      </mat-error>

      <mat-error *ngIf="password.dirty && password.errors?.characterInvalid">
        <fa-icon icon="exclamation-triangle"></fa-icon>
        The password only can include (#,$,%,&,@,!) symbols.
      </mat-error>
      <fa-icon icon="check" *ngIf="form.get('password').valid"></fa-icon>
      <mat-icon matSuffix (click)="hideNew = !hideNew">{{ hide ? 'visibility_off' : 'visibility' }}</mat-icon>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Confirm Password</mat-label>
      <input
        matInput
        [type]="hideConfirm ? 'text' : 'password'"
        name="confirmPassword"
        autocomplete="new-password"
        formControlName="passwordConfirm"
        pattern="{{ password.value | escapeChars }}"
        required
      />

      <mat-error *ngIf="passwordConfirm.errors?.required">
        <fa-icon icon="exclamation-triangle"></fa-icon>
        Password is required.
      </mat-error>

      <mat-error *ngIf="password.dirty && password.touched && passwordConfirm.errors?.pattern">
        <fa-icon icon="exclamation-triangle"></fa-icon>
        The passwords must be the same.
      </mat-error>
      <fa-icon
        icon="check"
        *ngIf="form.value.password && form.get('password').valid && form.value.password === form.value.passwordConfirm"
      ></fa-icon>
      <mat-icon matSuffix (click)="hideConfirm = !hideConfirm">{{ hide ? 'visibility_off' : 'visibility' }}</mat-icon>
    </mat-form-field>

    <button type="submit" hidden></button>
  </div>
</form>
