import { VWProgram } from '@app/core/models';
import { ProgramsActions, ProgramsActionTypes } from '../actions';

/**
 * ProgramsState
 */
export interface ProgramsState {
  loaded: boolean;
  selectedLoaded: boolean;
  pending: boolean;
  error: any;
  list: any;
  selected: VWProgram;
}

/**
 * PROGRAMS_INITIAL_STATE
 * @type {{loaded: boolean; pending: boolean; error: null; profile: null}}
 */
export const PROGRAMS_INITIAL_STATE: ProgramsState = {
  loaded: false,
  pending: false,
  selectedLoaded: false,
  error: null,
  list: null,
  selected: null,
};
/**
 * programsReducer
 * @param {ProgramsState} state
 * @param {ProgramsActions} action
 * @returns {ProgramsState}
 */
export const programsReducer = (
  state: ProgramsState = PROGRAMS_INITIAL_STATE,
  action: ProgramsActions,
): ProgramsState => {
  switch (action.type) {
    case ProgramsActionTypes.LOAD_PROGRAMS:
      return {
        ...state,
        error: null,
        loaded: false,
        pending: true,
        selectedLoaded: false,
      };
    case ProgramsActionTypes.LOAD_PROGRAMS_SUCCESS:
      return {
        ...state,
        loaded: true,
        pending: false,
        list: action.payload,
      };
    case ProgramsActionTypes.LOAD_PROGRAMS_ERROR:
      return { ...state, error: action.payload };
    case ProgramsActionTypes.SELECTED_PROGRAM:
      return {
        ...state,
        selectedLoaded: true,
        selected: action.payload,
      };
    default:
      return state;
  }
};
