import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
} from '@angular/core';
import { ContentItem } from '@app/core/models';

@Component({
  selector: 'ecp-content-carousel-item',
  templateUrl: './content-carousel-item.component.html',
  styleUrls: ['./content-carousel-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContentCarouselItemComponent implements AfterViewInit {
  @Input() content: ContentItem;
  @Input() fallback = false;
  @Input() fallbackUrl: string;

  constructor(private cdr: ChangeDetectorRef) {}

  ngAfterViewInit(): void {
    this.cdr.detach();
  }
}
