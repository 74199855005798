/**
 * MyReturnState
 */
import { MyReturn, MyRefresh } from '@app/core/models/my-return.model';
import { MyReturnActions, MyReturnActionTypes } from '../actions';

export interface MyReturnState {
  loaded: boolean;
  pending: boolean;
  error: any;
  selectedReturn: MyReturn;
  selectedBrand: string;
  searchTerm: string;
  refreshNames: any;
  refreshList: MyRefresh;
}

/**
 * MY_RETURN_INITIAL_STATE
 * @type {{loaded: boolean; pending: boolean; error: null; profile: null}}
 */
export const MY_RETURN_INITIAL_STATE: MyReturnState = {
  loaded: false,
  pending: false,
  error: null,
  selectedReturn: null,
  selectedBrand: 'All Brands',
  searchTerm: '',
  //  refreshId: null,
  refreshNames: null,
  refreshList: null,
};
/**
 * brandsReducer
 * @param {MyReturnState} state
 * @param {MyReturnActions} action
 * @returns {MyReturnState}
 */
export const myReturnReducer = (
  state: MyReturnState = MY_RETURN_INITIAL_STATE,
  action: MyReturnActions,
): MyReturnState => {
  switch (action.type) {
    case MyReturnActionTypes.LOAD_MY_RETURN:
      return {
        ...state,
        error: null,
        loaded: false,
        pending: true,
      };
    case MyReturnActionTypes.LOAD_MY_RETURN_SUCCESS:
      return {
        ...state,
        loaded: true,
        pending: false,
        refreshList: action.payload,
        selectedReturn: action.payload.returns[0],
        refreshNames: action.payload.returns.map(a => a.refreshName),
      };
    case MyReturnActionTypes.LOAD_MY_RETURN_ERROR:
      return { ...state, error: action.payload };
    case MyReturnActionTypes.SET_MY_RETURN_SELECTED_BRAND:
      return {
        ...state,
        selectedBrand: action.payload,
      };
    case MyReturnActionTypes.SET_SELECTED_RETURN:
      return {
        ...state,
        selectedReturn: state.refreshList.returns[action.payload],
        selectedBrand: 'All Brands',
      };
    case MyReturnActionTypes.SEARCH_MY_RETURN:
      return {
        ...state,
        searchTerm: action.payload,
      };
    default:
      return state;
  }
};
