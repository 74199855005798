<mat-sidenav-container>
  <mat-sidenav [opened]="(state | async)?.open" position="end" disableClose>
    <div *ngIf="(state | async)?.target === 'menu'" class="mat-sidenav-container">
      <ng-container *ngFor="let item of appNav">
        <ecp-sidenav-link *ngIf="!item.children; else expansionPanel" [link]="item">
        </ecp-sidenav-link>

        <ng-template #expansionPanel>
          <mat-expansion-panel
            [expanded]="step === item.order && (state | async)?.target === 'menu'"
            (opened)="setStep(item.order)"
          >
            <mat-expansion-panel-header collapsedHeight="50px" expandedHeight="50px">
              {{ item.label }}
              <mat-icon class="mat-expansion-panel-icon" svgIcon="menu-expansion"></mat-icon>
            </mat-expansion-panel-header>

            <ng-container *ngIf="hasChildren(item); else expansionSubpanel">
              <ng-container *ngFor="let subPanel of item.children; let subPanelIndex=index">
                <mat-expansion-panel
                  [expanded]="subStep === subPanelIndex && (state | async)?.target === 'menu'"
                  (opened)="setSubStep(subPanelIndex)"
                >
                  <mat-expansion-panel-header collapsedHeight="50px" expandedHeight="50px">
                    {{ subPanel.label }}
                    <mat-icon class="mat-expansion-panel-icon" svgIcon="submenu-expansion"></mat-icon>
                  </mat-expansion-panel-header>

                  <ecp-sidenav-list [links]="subPanel.children"></ecp-sidenav-list>
                </mat-expansion-panel>
              </ng-container>
            </ng-container>

            <ng-template #expansionSubpanel>
              <ecp-sidenav-list [links]="item.children"></ecp-sidenav-list>
            </ng-template>
          </mat-expansion-panel>
        </ng-template>
      </ng-container>

      <ul class="mat-sidenav-secondary-list-links">
        <li>
          <a routerLink="/contact-us">Contact</a>
        </li>

        <li>
          <a href="https://www.essilorusa.com/terms-and-conditions" target="_blank" title="Terms and Conditions">Terms</a>
        </li>

        <li>
          <a href="https://www.essilorusa.com/privacy-policy" target="_blank" title="Privacy Policy">Legal</a>
        </li>
      </ul>
    </div>

    <div *ngIf="(state | async)?.target === 'user'" class="mat-sidenav-container">
      <ng-container *ngFor="let item of userNav">
        <ecp-sidenav-link
          *ngIf="!item.children; else userExpansionPanel"
          [red]="true"
          [link]="item"
        ></ecp-sidenav-link>

        <ng-template #userExpansionPanel>
          <mat-expansion-panel
            [expanded]="(step === item.order || hasOneItem(item)) && (state | async)?.target === 'user'"
            (opened)="setStep(item.order)">
            <mat-expansion-panel-header collapsedHeight="50px" expandedHeight="50px">
              {{ item.label }}
              <mat-icon class="mat-expansion-panel-icon" svgIcon="menu-expansion"></mat-icon>
            </mat-expansion-panel-header>

            <ng-container *ngIf="hasChildren(item); else expansionSubpanel">
              <ng-container *ngFor="let subPanel of item.children; let subPanelIndex=index">
                <mat-expansion-panel
                  [expanded]="(subStep === subPanelIndex || hasOneItem(subPanel)) && (state | async)?.target === 'user'"
                  (opened)="setSubStep(subPanelIndex)">
                  <mat-expansion-panel-header collapsedHeight="50px" expandedHeight="50px">
                    {{ subPanel.label }}
                    <mat-icon class="mat-expansion-panel-icon" svgIcon="submenu-expansion"></mat-icon>
                  </mat-expansion-panel-header>

                  <ecp-sidenav-list
                    [links]="subPanel.children"

                  ></ecp-sidenav-list>
                </mat-expansion-panel>
              </ng-container>
            </ng-container>

            <ng-template #expansionSubpanel>
              <ecp-sidenav-list
                [links]="item.children"

              ></ecp-sidenav-list>
            </ng-template>
          </mat-expansion-panel>
        </ng-template>
      </ng-container>
    </div>
  </mat-sidenav>

  <ng-content></ng-content>
</mat-sidenav-container>


<ng-template>
  <ng-container *ngFor="let item of appNav">
    <a *ngIf="!item.children; else expansible" class="sidenav__link" [routerLink]="link.link">
      {{ link.label }}
    </a>
  </ng-container>
  <div class="sidenav__aux-menu">
    <a href="javascript:;" title="Contact us">Contact</a>
    <a href="javascript:;" title="Terms">Terms</a>
    <a href="javascript:;" title="Legal">Legal</a>
  </div>
</ng-template>

<ng-template #expansible>
  <mat-expansion-panel>
    <mat-expansion-panel-header collapsedHeight="50px" expandedHeight="50px">
      {{ item.label }}
      <mat-icon class="mat-expansion-panel-icon" svgIcon="menu-expansion"></mat-icon>
    </mat-expansion-panel-header>
  </mat-expansion-panel>
</ng-template>
