<div #frameList class="frames-list" *ngIf="frames$ | async">
  <div class="frames-list-filterbar">
    <div class="row is-flex">
      <div class="col s12 m6 l6">
        <p class="frames-list-filterbar-pagination">
          Viewing {{ paginationFrom$ | async }}-{{ framesLength }} of {{ framesCount$ | async }}
        </p>
      </div>
      <div class="col s12 m6 l6 text-align-right" *ngIf="this.patientId === ''">
        <button class="btn btn-primary" style="margin-right:5px" type="button" mat-button (click)="DownloadNewFrames()">
          Download New Frames
        </button>
        <button class="btn btn-primary" type="button" mat-button (click)="openExportBrandDialog()">
          Export Brand
        </button>
      </div>
    </div>
  </div>

  <div class="row is-flex">
    <div class="col s12 m6 l3 mt10" *ngFor="let item of frames$ | async; let i = index">
      <ecp-browse-catalog-frame-item
        [attr.class]="item.brandName"
        class="frame-item-container col s12"
        [title]="item.brandName + ' ' + item.styleName"
        [name]="item.brandName"
        [desc1]="item.colorDescription"
        [desc2]="item.moduleName"
        [clusterName]="item.clusterName"
        [size]="item.eyeSize + '-' + item.temple"
        [image]="item.largeImageUrl"
        [upc]="item.upc"
        [isOrderable]="item.isOrderable"
        [user]="user$ | async"
        [patientId]="patientId$ | async"
        [frameDetail]="item"
      >
      </ecp-browse-catalog-frame-item>
    </div>
  </div>

  <div class="frames-list-filterbar mt10 mb10">
    <div class="row is-flex">
      <div class="col s12 m6 l6">
        <p class="frames-list-filterbar-pagination">
          Viewing {{ paginationFrom$ | async }}-{{ framesLength }} of {{ framesCount$ | async }}
        </p>
      </div>
    </div>
  </div>
</div>
