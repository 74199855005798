import { Frames } from '@app/core/models';
import { Catalog } from '@app/core/models/catalog.model';
import { FramesActions, FramesActionTypes } from '../actions';

/**
 * FramesState
 */
export interface FramesState {
  loaded: boolean;
  pending: boolean;
  error: any;
  searchTerm: string;
  list: Catalog;
  selectedBrands: Array<any>;
}

/**
 * FRAMES_INITIAL_STATE
 * @type {{loaded: boolean; pending: boolean; error: null; profile: null}}
 */
export const FRAMES_INITIAL_STATE: FramesState = {
  loaded: false,
  pending: false,
  error: null,
  searchTerm: '',
  list: {
    frames: [],
    totalResults: 0,
  },
  selectedBrands: null,
};
/**
 * framesReducer
 * @param {FramesState} state
 * @param {FramesActions} action
 * @returns {FramesState}
 */
export const framesReducer = (
  state: FramesState = FRAMES_INITIAL_STATE,
  action: FramesActions,
): FramesState => {
  switch (action.type) {
    case FramesActionTypes.LOAD_FRAME_SEARCH:
      return {
        ...state,
        error: null,
        loaded: false,
        pending: true,
        searchTerm: action.payload,
      };
    case FramesActionTypes.LOAD_PATIENT_FRAME_SEARCH:
      return {
        ...state,
        error: null,
        loaded: false,
        pending: true,
        searchTerm: action.payload.filters,
      };
    case FramesActionTypes.LOAD_FRAMES_SUCCESS:
      return {
        ...state,
        loaded: true,
        pending: false,
        list: {
          frames: action.payload['frames'],
          totalResults: action.payload['totalResults'],
        },
      };
    case FramesActionTypes.LOAD_MORE_FRAMES_SUCCESS:
      return {
        ...state,
        loaded: true,
        pending: false,
        list: {
          frames: [...state.list.frames, ...action.payload['frames']],
          totalResults: action.payload['totalResults'],
        },
      };
    case FramesActionTypes.LOAD_FRAMES_ERROR:
      return { ...state, error: action.payload };
    case FramesActionTypes.LOAD_SELECTED_BRANDS:
      return {
        ...state,
        selectedBrands: action.payload,
      };
    case FramesActionTypes.REMOVE_SELECTED_BRANDS:
      return {
        ...state,
        selectedBrands: state.selectedBrands.filter(brands => {
          return action.payload !== brands;
        }),
      };
    case FramesActionTypes.CLEAR_SELECTED_BRANDS:
      return {
        ...state,
        selectedBrands: [],
      };
    default:
      return state;
  }
};
