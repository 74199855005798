import { CarouselItemDirective } from './carousel/carousel-item.directive';
import { CarouselComponent, CarouselElementDirective } from './carousel/carousel.component';
import { ChangePasswordFormComponent } from './change-password-form/change-password-form.component';
import { AlertDialogComponent } from './dialogs/alert-dialog/alert-dialog.component';
import { ConfirmDialogComponent } from './dialogs/confirm-dialog/confirm-dialog.component';
import { FooterComponent } from './layout/footer/footer.component';
import { HeaderComponent } from './layout/header/header.component';
import { HeroToolbarComponent } from './layout/hero/hero-toolbar/hero-toolbar.component';
import { HeroComponent } from './layout/hero/hero.component';
import { LoaderComponent } from './loader/loader.component';
import { LoginDialogComponent } from './login-dialog/login-dialog.component';
import { NavItemLinkDirective } from './layout/nav/nav-item/nav-item-link.directive';
import { NavItemComponent } from './layout/nav/nav-item/nav-item.component';
import { NavComponent } from './layout/nav/nav.component';
import { SidenavLinkComponent } from './layout/sidenav/sidenav-link/sidenav-link.component';
import { SidenavListComponent } from './layout/sidenav/sidenav-list/sidenav-list.component';
import { LoginHeaderFormComponent } from '@app/shared/components/login-header-form/login-header-form.component';
import { SidenavComponent } from '@app/shared/components/layout/sidenav/sidenav.component';
import { LoginFormComponent } from '@app/shared/components/login-form/login-form.component';
import { AddToCartNotificationsComponent } from '@app/shared/components/add-to-cart-notifications/add-to-cart-notifications.component';
import { ResetPasswordFormComponent } from '@app/shared/components/reset-password-form/reset-password-form.component';
import { ContentCarouselItemComponent } from '@app/shared/components/content/content-carousel-item/content-carousel-item.component';
import { WidgetComponent } from '@app/shared/components/widget/widget.component';
import { PasswordRequirementsComponent } from './password-requirements/password-requirements.component';
import { CatalogSearchComponent } from '@app/shared/components/catalog-search/catalog-search.component';
import { BrowseCatalogComponent } from '@app/shared/components/browse-catalog/browse-catalog.component';
import { CatalogFrameListComponent } from '@app/shared/components/browse-catalog/catalog-frame-list/catalog-frame-list.component';
import { CatalogFrameItemComponent } from '@app/shared/components/browse-catalog/catalog-frame-item/catalog-frame-item.component';
import { SearchChipsComponent } from '@app/shared/components/search-chips/search-chips.component';
import { CatalogFilterComponent } from '@app/shared/components/catalog-filter/catalog-filter.component';
import { ExportBrandComponent } from '@app/shared/components/export-brand/export-brand.component';
import { BreadcrumbComponent } from '@app/shared/components/breadcrumb/breadcrumb.component';
import { CartDropdownComponent } from '@app/shared/components/cart-dropdown/cart-dropdown.component';
import { AddToCartBtnComponent } from '@app/shared/components/add-to-cart-btn/add-to-cart-btn.component';

export const components: any[] = [
  AlertDialogComponent,
  CarouselComponent,
  CarouselElementDirective,
  CarouselItemDirective,
  ChangePasswordFormComponent,
  ResetPasswordFormComponent,
  ConfirmDialogComponent,
  FooterComponent,
  HeaderComponent,
  HeroComponent,
  HeroToolbarComponent,
  LoaderComponent,
  LoginDialogComponent,
  LoginFormComponent,
  NavComponent,
  NavItemComponent,
  NavItemLinkDirective,
  SidenavComponent,
  SidenavLinkComponent,
  SidenavListComponent,
  LoginHeaderFormComponent,
  AddToCartNotificationsComponent,
  ContentCarouselItemComponent,
  WidgetComponent,
  PasswordRequirementsComponent,
  CatalogSearchComponent,
  BrowseCatalogComponent,
  CatalogFrameItemComponent,
  CatalogFrameListComponent,
  SearchChipsComponent,
  CatalogFilterComponent,
  ExportBrandComponent,
  BreadcrumbComponent,
  CartDropdownComponent,
  AddToCartBtnComponent,
];

export * from './carousel/carousel-item.directive';
export * from './carousel/carousel.component';
export * from './change-password-form/change-password-form.component';
export * from './reset-password-form/reset-password-form.component';
export * from './dialogs/alert-dialog/alert-dialog.component';
export * from './dialogs/confirm-dialog/confirm-dialog.component';
export * from './dialogs/session-timeout/session-timeout.component';
export * from './layout/footer/footer.component';
export * from './layout/header/header.component';
export * from './layout/hero/hero-toolbar/hero-toolbar.component';
export * from './layout/hero/hero.component';
export * from './loader/loader.component';
export * from './login-dialog/login-dialog.component';
export * from './login-form/login-form.component';
export * from './layout/nav/nav-item/nav-item-link.directive';
export * from './layout/nav/nav-item/nav-item.component';
export * from './layout/nav/nav.component';
export * from './layout/sidenav/sidenav-link/sidenav-link.component';
export * from './layout/sidenav/sidenav-list/sidenav-list.component';
export * from './layout/sidenav/sidenav.component';
export * from './login-header-form/login-header-form.component';
export * from './add-to-cart-notifications/add-to-cart-notifications.component';
export * from './content/content-carousel-item/content-carousel-item.component';
export * from './widget/widget.component';
export * from './password-requirements/password-requirements.component';
export * from './catalog-search/catalog-search.component';
export * from './browse-catalog/browse-catalog.component';
export * from './browse-catalog/catalog-frame-item/catalog-frame-item.component';
export * from './browse-catalog/catalog-frame-list/catalog-frame-list.component';
export * from './search-chips/search-chips.component';
export * from './catalog-filter/catalog-filter.component';
export * from './export-brand/export-brand.component';
export * from './breadcrumb/breadcrumb.component';
export * from './cart-dropdown/cart-dropdown.component';
export * from './add-to-cart-btn/add-to-cart-btn.component';
