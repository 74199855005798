import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ApiService } from './api.service';
import { Dropship, User } from '../models';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { of } from 'rxjs';
import { State } from '@ngrx/store';
import { AppState } from '@app/core/ngrx';

/**
 * UserService
 */
@Injectable({ providedIn: 'root' })
export class UserService {
  /**
   * url
   */
  public url;

  /**
   * constructor
   * @param {ApiService} apiService
   * @param {HttpClient} http
   */
  constructor(
    private apiService: ApiService,
    private http: HttpClient,
    private state: State<AppState>,
  ) {}

  /**
   * getUser
   * @returns {Observable<User>}
   */
  getUser(): Observable<User> {
    return this.apiService.get('/user/profile');
  }

  /**
   * update
   * @param {Object} data
   * @returns {Observable<User>}
   */
  update(data: Object = {}): Observable<User> {
    const options = {
      headers: {
        programKey: this.state.getValue().user.profile.program.programKey,
      },
    };
    return this.apiService.put('/user/update', data);
  }

  /**
   * updatePassword
   * @param {Object} data
   * @returns {Observable<any>}
   */
  updatePassword(data: Object = {}): Observable<any> {
    const options = {
      headers: {
        programKey: this.state.getValue().user.profile.program.programKey,
      },
    };
    return this.apiService.post('/user/change_password', data, options);
  }

  /**
   * resetPassword
   * @param {Object} data
   * @returns {Observable<any>}
   */
  resetPassword(data: Object = {}): Observable<any> {
    return this.apiService.post('/user/resetpassword', data);
  }

  /**
   * getDropship
   * @returns {Observable<Dropship>}
   */
  getDropship(): Observable<Dropship> {
    const options = {
      headers: {
        programKey: this.state.getValue().user.profile.program.programKey,
      },
    };
    return this.apiService.get('/user/settings/dropship', options);
  }

  /**
   * getPatientUser
   * @returns {Observable<User>}
   */
  getPatientUser(code: string, data: Object = {}): Observable<User> {
    return this.apiService.get(`/user/ecpprofile/${code}`, data);
  }

  /**
   * switchProfile
   * @returns {Observable<User>}
   */
  switchProfile( data: string): Observable<User> {
    return this.apiService.post(`/user/profile/switch`, data);
  }
}
