import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';
import { ApiService } from '@app/core/services/api.service';
import { of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class FrameReturnsService {
  constructor(private http: HttpClient, private apiService: ApiService) {}

  submitFrameReturns(payload) {
    return this.http.post(`${environment.apiUrl}/myreturn/ecpreturn`, payload);
  }

  GetFrameReturns() {
    return this.http.get(`${environment.apiUrl}/myreturn/ecpreturn`);
  }
}
