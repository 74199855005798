import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { HttpClient } from '@angular/common/http';
import { Media } from '@app/core/models';
import { ApiService } from '@app/core/services/api.service';
import { State } from '@ngrx/store';
import { AppState } from '@app/core/ngrx';

/**
 * MediaService
 */
@Injectable({ providedIn: 'root' })
export class MediaService {
  /**
   * url
   */
  public url;

  /**
   * constructor
   * @param {ApiService} apiService
   * @param {HttpClient} http
   */
  constructor(
    private apiService: ApiService,
    private http: HttpClient,
    private state: State<AppState>,
  ) {}

  /**
   * getMedia
   * @returns {Observable<Media>}
   */
  getMedia(): Observable<Media> {
    const options = {
      headers: {
        programKey: this.state.getValue().user.profile.program.programKey,
      },
    };
    return this.apiService.get('/media', options);
  }
}
