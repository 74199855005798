import {
  Component,
  OnInit,
  ElementRef,
  ViewChild,
  OnChanges,
  ChangeDetectionStrategy,
  ViewChildren,
} from '@angular/core';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { Observable, BehaviorSubject } from 'rxjs';
import { Frames } from '@app/core/models';
import { select, Store } from '@ngrx/store';
import { ExportBrandComponent } from '@app/shared/components/export-brand/export-brand.component';
import { MatDialog } from '@angular/material/dialog';
import { AppState, selectPatientId, selectProfile } from '@app/core/ngrx';
import { MyCollectionService } from '@app/core/services/my-collection.service';
import { CsvDownloadService } from '@app/core/services/csv-download.service';

interface PaginationOption {
  label: string;
  value: string;
}

@Component({
  selector: 'ecp-catalog-frame-list',
  templateUrl: './catalog-frame-list.component.html',
  styleUrls: ['./catalog-frame-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CatalogFrameListComponent implements OnInit, OnChanges {
  /**
   * frames$
   * @type  Observable<Frames>
   */
  // @select(['frames', 'list', 'frames'])
  public frames$: Observable<any>;
  public downloadFrames: any;
  // @select(['user', 'profile'])
  public user$: Observable<any>;
  public patientId$: Observable<any>;
  @ViewChild('frameList') frameList: ElementRef;
  public framesLength: number;
  /**
   * framesCount$
   * @type  Observable<Frames>
   */
  // @select(['frames', 'list', 'totalResults'])
  public framesCount$: Observable<number>;

  // @select(['frames', 'selectedBrands'])
  public selectedBrands$: Observable<any>;

  public selectedBrands: any = [];
  public mobileMediaQuery = '(max-width: 76px)';
  public paginationFrom$: BehaviorSubject<number>;
  public paginationTo$: BehaviorSubject<number>;
  public paginationLength$: Observable<number>;
  public paginationPage = 0;
  public paginationLimit: number =
    (this.breakpointObserver.isMatched(this.mobileMediaQuery) && 4) || 16;
  patiendId$: Observable<string>;
  patientId: string;

  constructor(
    private breakpointObserver: BreakpointObserver,
    public dialog: MatDialog,
    private store: Store<AppState>,
    public csvService: CsvDownloadService,
    public myCollectionService: MyCollectionService,
  ) {}

  ngOnInit() {
    this.frames$ = this.store.pipe(select(state => state.frames.list.frames));
    this.user$ = this.store.pipe(select(selectProfile));
    this.patientId$ = this.store.pipe(select(selectPatientId));
    this.selectedBrands$ = this.store.pipe(
      select(state => state.frames.selectedBrands),
    );
    this.framesCount$ = this.store.pipe(
      select(state => state.frames.list.totalResults),
    );
    this.frames$.pipe().subscribe(frames => {
      let tmpFrames: any = frames;
      this.framesLength = tmpFrames.length;
      this.framesCount$.pipe().subscribe(count => {
        this.configure();
      });
    });

    this.selectedBrands$.subscribe(selectedBrands => {
      this.selectedBrands = { brands: selectedBrands };
    });

    this.patiendId$ = this.store.select(selectPatientId);

    this.patiendId$.subscribe(code => {
      this.patientId = code;
    });
  }

  ngOnChanges() {
    this.configure();
  }

  configure(): void {
    if (!this.frames$) return;
    this.paginationFrom$ = new BehaviorSubject(
      this.paginationPage * this.paginationLimit + 1,
    );
    this.paginationTo$ = new BehaviorSubject(
      this.paginationPage * this.paginationLimit + this.framesLength,
    );

    this.paginationLength$ = this.framesCount$;

    this.breakpointObserver
      .observe([this.mobileMediaQuery])
      .subscribe((state: BreakpointState) => {
        this.paginationLimit = (state.matches && 6) || 12;
      });
  }

  openExportBrandDialog(): void {
    let dialogRef = this.dialog.open(ExportBrandComponent, {
      width: '600px',
      maxWidth: '89.3vw',
      maxHeight: '82vh',
    });

    dialogRef.afterClosed().subscribe();
  }

  DownloadNewFrames(): void {
    this.myCollectionService.getMyNewFrames().subscribe(frames => {
      this.downloadFrames = frames;
      this.csvService.exportToExcel(this.downloadFrames);
    });
  }

  scrollTo(element: HTMLElement): void {
    if ('scrollBehavior' in element.style) {
      return element.scrollIntoView({
        block: 'start',
        behavior: 'smooth',
      });
    }

    element.scrollIntoView();
  }
}
