import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { HttpClient } from '@angular/common/http';
import { ApiService } from '@app/core/services/api.service';
import { State } from '@ngrx/store';
import { AppState } from '@app/core/ngrx';
import { BulkUpload, BulkUploadResponse } from '../models/bulk-upload.model';

/**
 * BulkOrderService
 */
@Injectable({ providedIn: 'root' })
export class BulkOrderService {
  /**
   * url
   */
  public url;

  /**
   * constructor
   * @param {ApiService} apiService
   * @param {HttpClient} http
   */
  constructor(private apiService: ApiService, private http: HttpClient, private state: State<AppState>) {}

  /**
   * ValidateOrder
   * @returns {Observable<any>}
   */
  validateOrders(payload): Observable<BulkUploadResponse[]> {
    const options = {
      headers: {
        programKey: this.state.getValue().user.profile.program.programKey,
      },
    };
    return this.apiService.post('/bulkupload/validate', payload, options);
  }
  uploadOrders(payload): Observable<any>{
    const options = {
      headers: {
        programKey: this.state.getValue().user.profile.program.programKey,
      },
    };
    return this.apiService.post('/bulkupload', payload, options);

  }
  getBulkOrders():Observable<any>{
    const options = {
      headers: {
        programKey: this.state.getValue().user.profile.program.programKey,
      },
    };
    return this.apiService.get('/bulkupload', options);
  }
  getBulkOrderByOrderId(bulkdOrderId:any):Observable<any>{
    const options = {
      headers: {
        programKey: this.state.getValue().user.profile.program.programKey,
      },
    };
    return this.apiService.get(`/bulkupload/${bulkdOrderId}`, options);
  }
  
  submitBulkOrderReview(payload:any):Observable<any>{
    const options = {
      headers: {
        programKey: this.state.getValue().user.profile.program.programKey,
      },
    };
    return this.apiService.post(`/bulkupload/orderreview`,payload, options);
  }
}
