import {
  Component,
  OnDestroy,
  OnInit,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { Subscription } from 'rxjs/internal/Subscription';
import {
  debounceTime,
  distinctUntilChanged,
  map,
  skipWhile,
} from 'rxjs/operators';
import { Store, select } from '@ngrx/store';
import {
  AppState,
  CatalogActionTypes,
  FramesActionTypes,
} from '@app/core/ngrx';
import { AbstractControl, FormBuilder, FormGroup } from '@angular/forms';
import { Observable } from 'rxjs';

@Component({
  selector: 'ecp-catalog-search',
  templateUrl: './catalog-search.component.html',
  styleUrls: ['./catalog-search.component.scss'],
})
export class CatalogSearchComponent implements OnInit, OnDestroy {
  private searchSub$: Subscription;
  @Input() isBrandsShown: Boolean;
  @Output() brandClicked = new EventEmitter<boolean>();
  public form: FormGroup;
  get searchText(): AbstractControl {
    return this.form.get('searchText');
  }

  frameSizeSelected: any;
  public searchChips$: Observable<Array<string>>;
  public search$: BehaviorSubject<any> = new BehaviorSubject(null);
  public selectedFrameSize$: Observable<Array<any>>;
  public brands$: any;
  public brands: Observable<any>;
  public brandsSelected: Array<any> = [];
  public filter$: any;

  constructor(private fb: FormBuilder, private store: Store<AppState>) {}
  ngOnInit(): void {
    this.searchChips$ = this.store.pipe(
      select(state => state.catalog.filter.searchTerms),
    );
    this.selectedFrameSize$ = this.store.pipe(
      select(state => state.catalog.filter.selectedFrameSize),
    );

    this.form = this.createForm();
    this.searchSub$ = this.search$
      .pipe(
        skipWhile(event => !event),
        map(event => event.currentTarget.value),
        debounceTime(700),
        distinctUntilChanged(),
      )
      .subscribe(term => {});
    if (this.isBrandsShown) {
      this.loadPatientBrands();
    }
  }

  /**
   * createForm
   */
  createForm(): FormGroup {
    return this.fb.group({
      searchText: [''],
    });
  }

  /**
   * submitSearch
   * @description if the search value exists lets add it to the search chip in Redux
   */
  submitSearch() {
    if (this.searchText.value) {
      this.store.dispatch({
        type: CatalogActionTypes.ADD_SEARCH_CHIP,
        payload: this.searchText.value,
      });
      this.searchText.reset();
    }
  }

  loadPatientBrands() {
    this.brands$ = this.store.pipe(select(state => state.brands.list));
    this.filter$ = this.store.pipe(
      select(state => state.catalog.filter.selectedBrands),
    );
    this.brands$.pipe().subscribe(brands => {
      if (brands) {
        const brandsC = brands.brands.map(item => {
          return Object.assign({}, item, { isValid: true });
        });
        this.brands = brandsC;
      }
    });
    this.filter$.subscribe(selectedBrands => {
      if (!selectedBrands || selectedBrands.length === 0) {
        this.brandsSelected = [];
      } else {
        this.brandsSelected = selectedBrands;
      }
    });
  }

  checkBrandList(brand) {
    if (this.brandsSelected.includes(brand)) {
      this.brandsSelected = Object.assign([], this.brandsSelected);
      let index = this.brandsSelected.indexOf(brand);
      this.brandsSelected.splice(index, 1);
    } else {
      this.brandsSelected = Object.assign([], this.brandsSelected);
      this.brandsSelected.push(brand);
    }
    this.store.dispatch({
      type: FramesActionTypes.LOAD_SELECTED_BRANDS,
      payload: this.brandsSelected,
    });
    this.brandClicked.emit(true);
  }

  /**
   * ngOnDestroy
   */
  ngOnDestroy(): void {
    if (this.searchSub$) this.searchSub$.unsubscribe();
  }
}
