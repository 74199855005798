import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { of } from 'rxjs/internal/observable/of';
import * as pdfMake from 'pdfmake/build/pdfmake';
import * as pdfFonts from 'pdfmake/build/vfs_fonts';
import { Observer } from 'rxjs/internal/types';
import { VwService } from '@app/core/services/vw.service';
(<any>pdfMake).vfs = pdfFonts.pdfMake.vfs;
/**
 * UserService
 */
@Injectable({ providedIn: 'root' })
export class ExportBrandPdfService {
  /**
   * constructor
   */
  constructor(private vwService: VwService) {}

  exportBrandProcess(framesList, programKeyState, brandName?): Observable<boolean> {
    this.setBase64(framesList).then((framesWithBase64: Array<any>) => {
      // group frames by modulename
      let frameByCategory = framesWithBase64.reduce(
        (ubc, u) => ({
          ...ubc,
          [u.categoryName]: [...(ubc[u.categoryName] || []), u],
        }),
        {},
      );

      let categories = [];

      Object.keys(frameByCategory).forEach(prop => {
        let category = frameByCategory[prop];

        let frameContextType = category.reduce(
          (ubc, u) => ({
            ...ubc,
            [u.collectionContext]: [...(ubc[u.collectionContext] || []), u],
          }),
          {},
        );

        let contextTypes = [];

        Object.keys(frameContextType).forEach(cProp => {
          let contextType = frameContextType[cProp];

          if (cProp != 'InCluster') {
            let frameByModule = contextType.reduce(
              (ubc, u) => ({
                ...ubc,
                [u.moduleName]: [...(ubc[u.moduleName] || []), u],
              }),
              {},
            );

            var modules = [];

            Object.keys(frameByModule).forEach(mProp => {
              modules.push({ name: mProp, frames: frameByModule[mProp] });
            });
          } else {
            let frameByCluster = contextType.reduce(
              (ubc, u) => ({
                ...ubc,
                [u.clusterName]: [...(ubc[u.clusterName] || []), u],
              }),
              {},
            );

            var clusters = [];

            Object.keys(frameByCluster).forEach(mProp => {
              clusters.push({ name: mProp, frames: frameByCluster[mProp] });
            });
          }
          categories.push({ categoryName: prop, clusters: clusters, modules: modules });
        });
      });

      let reportHeaderImage;
      let reportFooterImage;
      /**
       * FMT-73 - R023 - (Customer Facing) Brand Export
       */
      switch (programKeyState) {
        case 'FOF':
          reportHeaderImage = '/assets/images/reports/report-header-fof.png';
          reportFooterImage = '/assets/images/reports/report-footer-fof.png';
          break;
        case 'GLXY':
          reportHeaderImage = '/assets/images/reports/report-header-gxly.png';
          reportFooterImage = '/assets/images/reports/report-footer-gxly.png';
          break;
        case 'PERC':
          reportHeaderImage = '/assets/images/reports/report-header-perc.png';
          reportFooterImage = '/assets/images/reports/report-footer-perc.png';
          break;
        case 'VA':
          reportHeaderImage = '/assets/images/reports/report-header-va.png';
          reportFooterImage = '/assets/images/reports/report-footer-va.png';
          break;
        case 'PIV':
          reportHeaderImage = '/assets/images/reports/report-header-essilor.png';
          reportFooterImage = '/assets/images/reports/report-footer-essilor.png';
          break;
        case 'VWFD':
          reportHeaderImage = '/assets/images/reports/report-header-fd.png';
          reportFooterImage = '/assets/images/reports/report-footer-fd.png';
          break;
        case 'KPLR':
          reportHeaderImage = '/assets/images/reports/report-header-kp.png';
          reportFooterImage = '/assets/images/reports/report-footer-kp.png';
          break;
        default:
          reportHeaderImage = '/assets/images/reports/report-header-essilor.png';
          reportFooterImage = '/assets/images/reports/report-footer-essilor.png';
      }

      this.getBase64ImageFromURL(reportHeaderImage).subscribe(base64data => {
        let headerImage = 'data:image/jpg;base64,' + base64data;

        this.getBase64ImageFromURL(reportFooterImage).subscribe(base64data2 => {
          let footerImage = 'data:image/jpg;base64,' + base64data2;

          let docDefinition: any = {
            pageMargins: [40, 45, 40, 30],
            header: {
              columns: [
                {
                  stack: [
                    {
                      image: headerImage,
                      width: 600,
                    },
                  ],
                },
              ],
            },
            footer: function(currentPage, pageCount) {
              return {
                table: {
                  body: [
                    [
                      {
                        image: 'footerImg',
                        width: 600,
                      },
                    ],
                    [
                      {
                        text: 'Page ' + currentPage.toString() + ' of ' + pageCount,
                        alignment: 'left',
                        style: {
                          fontSize: 10,
                          bold: false,
                        },
                        margin: [40, -35, 0, 0],
                      },
                    ],
                  ],
                },
                layout: 'noBorders',
              };
            },
            images: {
              footerImg: footerImage,
            },
            content: this.setContent(categories, brandName),
          };
          pdfMake
            .createPdf(docDefinition)
            .download(
              categories[0].categoryName === 'null' ? brandName : categories[0].categoryName + ' assortment.pdf',
            );
          return of(true);
        });
      });
    });
    return of(false);
  }

  setContent(collections, brandName) {
    // console.log('setContent - collections => ', collections)
    let content = [];
    let rowCount = 0;
    let columnCount = 0;
    let pageFramesCount = 0;
    let pageRows = 0;
    // Remove null category
    // collections.forEach(element => {
    //   if (!!!element.categoryName) {
    //     collections.splice(collections.indexOf(element), 1);
    //   }
    // });

    // To prioritize must have modules
    // this.setPriorityModule(collections);

    collections.forEach(data => {
      // console.log('>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>');
      // console.log('setContent - collections => ', JSON.stringify(collections));
      // console.log('setContent - EL => ', JSON.stringify(data));
      let _pdf = this;
      if (data.categoryName) {
        if (data.categoryName) {
          if (collections.indexOf(data) > 0) {
            content.push(_pdf.setPageBreak());
            rowCount = 0;
            pageFramesCount = 0;
            pageRows = 0;
          }
          // console.log('collections.forEach - setBrand - collections.brandName => ', collections.brandName);
          // console.log('>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>');
          content.push(_pdf.setBrand(brandName));
          content.push(_pdf.setCategory(data.categoryName));

          // if (data.clusters) {
          //   let clusterCount = data.clusters.length;
          //   data.clusters.forEach(mod => {
          //     //              window.console.log('Cluster : ' + mod.name )
          //     clusterCount--;
          //     rowCount = 0;
          //     content.push(_pdf.setModule(mod.name + ' *'));
          //     let row = _pdf.newRow();
          //     mod.frames.forEach(frame => {
          //       // Create a row (4 columns)
          //       row.columns.push(_pdf.setFrame(frame));

          //       columnCount++;
          //       if ((columnCount + 1) % 4 === 1 || mod.frames.length === columnCount) {
          //         rowCount++;

          //         // Insert row in pdf content
          //         content.push(row);
          //         row = _pdf.newRow();

          //         // New page every 6 rows
          //         if ((rowCount + 1) % 6 === 1) {
          //           // Check if last module and last column count
          //           if (data.clusters.length !== data.clusters.indexOf(mod) + 1 || mod.frames.length !== columnCount) {
          //             //                      window.console.log('Frame : ' +  frame.styleName + ' | ' + frame.colorDescription + ', Column : ' + columnCount + ', Row : ' + rowCount )
          //             this.newPage(content, brandName, data.categoryName);
          //           }
          //         }
          //         if (mod.frames.length === columnCount) {
          //           columnCount = 0;
          //         }
          //       }
          //     });
          //     if ((rowCount + 1) % 6 !== 1 && clusterCount > 0) {
          //       //              window.console.log('Cluster Count : ' + clusterCount + ', Column : ' + columnCount + ', Row : ' + rowCount )
          //       this.newPage(content, brandName, data.categoryName);
          //     }
          //   });
          // }
          if (data.modules) {
            data.modules.forEach(mod => {
              pageFramesCount += mod.frames.length;
              pageRows += Math.ceil(mod.frames.length/4);
              if(pageFramesCount > 24 || pageRows > 6)
              {
                this.newPage(content, brandName, data.categoryName);
                pageFramesCount = mod.frames.length;
                rowCount = 0;
                pageRows = 0;
              }
              // content.push(_pdf.setModule(mod));
              content.push(_pdf.setModule(mod.name));
              let row = _pdf.newRow();

              mod.frames.forEach(frame => {
                // Create a row (4 columns)
                row.columns.push(_pdf.setFrame(frame));
                columnCount++;

                if ((columnCount + 1) % 4 === 1 || mod.frames.length === columnCount) {
                  rowCount++;

                  // Insert row in pdf content
                  content.push(row);
                  row = _pdf.newRow();

                  // New page every 6 rows
                  if ((rowCount + 1) % 6 === 1) {
                    // Check if last module and last column count
                    if (data.modules.length !== data.modules.indexOf(mod) + 1 || mod.frames.length !== columnCount) {
                      this.newPage(content, frame.brandName, data.categoryName);
                      pageFramesCount = 0;
                      pageRows = 0;
                    }
                  }
                  if (mod.frames.length === columnCount) {
                    columnCount = 0;
                  }
                }
              });
            });
          }
        }
      }
    });
    return content;
  }

  setPriorityModule(collections) {
    collections.forEach(categories => {
      let mustHaveModules = [];
      categories.modules.forEach(modules => {
        if (modules.name.toLowerCase().includes('must have')) {
          let index = categories.modules.indexOf(modules);
          mustHaveModules.push(modules);
          categories.modules.splice(index, 1);
          categories.modules.splice(0, 0, modules);
        }
      });
    });
  }

  setPageBreak() {
    return {
      text: '',
      pageBreak: 'after',
    };
  }

  setBrand(val) {
    return {
      text: val === 'null' ? '' : val,
      // text: 'setBrand - ' + val,
      alignment: 'right',
      fontSize: 24,
      bold: false,
    };
  }

  setCategory(val) {
    return {
      text: val === 'null' ? '' : val,
      // text: 'setCategory - ' + val,
      alignment: 'right',
      fontSize: 12,
    };
  }

  setFrame(val) {
    try {
      return {
        stack: [
          {
            image: val.base64,
            width: 108,
            maxHeight: 90,
          },
          {
            text: val.brandName + ' ' + val.styleName,
            bold: false,
          },
          {
            text: val.colorDescription,
            fontSize: 8,
          },
        ],
        width: '25%',
        alignment: 'center',
      };
    } catch (error) {
      throw error;
    }
  }

  setModule(val) {
    return {
      text: val === 'null' ? '' : val,
      // text: 'setModule - ' + val,
      color: 'black',
      alignment: 'center',
      bold: true,
      margin: [0, 10, 0, 2],
      italics: true,
    };
  }

  newRow() {
    return {
      columns: [],
      columnGap: 10,
      margin: [0, 10, 0, 10],
    };
  }

  newPage(content, brand, category) {
    let _pdf = this;
    content.push(_pdf.setPageBreak());
    // console.log('newPage - setBrand - brand.toUpperCase() => ', brand.toUpperCase());
    content.push(_pdf.setBrand(brand.toUpperCase()));
    content.push(_pdf.setCategory(category));
  }

  setBase64(frameList: Array<any>) {
    let frameTotal = frameList.length;
    let frameCount = 0;

    return new Promise(resolve => {
      frameList.forEach(frame => {
        let defaultImage = '/assets/images/frame-default-sm.png';
        let realImage = frame.largeImageUrl;
        this.vwService.getFrameImage(frame.fpc).subscribe(base64data => {
          frame.base64 = 'data:image/jpg;base64,' + base64data;
          frameCount++;
          if (frameTotal === frameCount) resolve(frameList);
        });
      });
    });
  }

  getBase64ImageFromURL(url: string) {
    return new Observable((observer: Observer<string>) => {
      let img = new Image();
      img.crossOrigin = 'Anonymous';
      img.src = url;
      if (!img.complete) {
        img.onload = () => {
          observer.next(this.getBase64Image(img));
          observer.complete();
        };
        img.onerror = err => {
          observer.error(err);
        };
      } else {
        observer.next(this.getBase64Image(img));
        observer.complete();
      }
    });
  }

  getBase64Image(img: HTMLImageElement) {
    let canvas = document.createElement('canvas');
    canvas.width = img.width;
    canvas.height = img.height;
    let ctx = canvas.getContext('2d');
    ctx.drawImage(img, 0, 0);
    let dataURL = canvas.toDataURL('image/png');
    return dataURL.replace(/^data:image\/(png|jpg);base64,/, '');
  }
}
