import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { HttpClient } from '@angular/common/http';
import { RealignmentOrders } from '@app/core/models';
import { ApiService } from '@app/core/services/api.service';
import { State } from '@ngrx/store';
import { AppState } from '@app/core/ngrx';

/**
 * MyOrdersService
 */
@Injectable({ providedIn: 'root' })
export class RealignmentOrdersService {
  /**
   * url
   */
  public url;

  /**
   * constructor
   * @param {ApiService} apiService
   * @param {HttpClient} http
   */
  constructor(private apiService: ApiService, private http: HttpClient, private state: State<AppState>) {}

  /**
   * getMyOrders
   * @returns {Observable<RealignmentOrders[]>}
   */
  getRealignmentOrders(): Observable<RealignmentOrders[]> {
    const options = {
      headers: {
        programKey: this.state.getValue().user.profile.program.programKey,
      },
    };
    return this.apiService.get('/realignment', options);
  }

  /**
   * getMyOrders
   * @returns {Observable<RealignmentOrders[]>}
   */
  toggleHold(order) {
    const options = {
      headers: {
        programKey: this.state.getValue().user.profile.program.programKey,
      },
    };
    window.console.log(order);
    return this.apiService.post('/realignment/hold', order, options);
  }

  /**
   * getMyOrders
   * @returns {Observable<RealignmentOrders[]>}
   */
  releaseOrder(order) {
    const options = {
      headers: {
        programKey: this.state.getValue().user.profile.program.programKey,
      },
    };
    return this.apiService.post('/realignment/order', order, options);
  }
}
