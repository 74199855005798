/**
 * MyCollectionState
 */
import { MyCollection } from '@app/core/models/my-collection.model';
import { MyCollectionActions, MyCollectionActionTypes } from '../actions';

export interface MyCollectionState {
  loaded: boolean;
  pending: boolean;
  error: any;
  list: MyCollection;
  selectedBrand: string;
  searchTerm: string;
}

/**
 * MY_COLLECTION_INITIAL_STATE
 * @type {{loaded: boolean; pending: boolean; error: null; profile: null}}
 */
export const MY_COLLECTION_INITIAL_STATE: MyCollectionState = {
  loaded: false,
  pending: false,
  error: null,
  list: null,
  selectedBrand: 'All Brands',
  searchTerm: '',
};
/**
 * brandsReducer
 * @param {MyCollectionState} state
 * @param {MyCollectionActions} action
 * @returns {MyCollectionState}
 */
export const myCollectionReducer = (
  state: MyCollectionState = MY_COLLECTION_INITIAL_STATE,
  action: MyCollectionActions,
): MyCollectionState => {
  switch (action.type) {
    case MyCollectionActionTypes.LOAD_MY_COLLECTION:
      return {
        ...state,
        error: null,
        loaded: false,
        pending: true,
      };
    case MyCollectionActionTypes.LOAD_MY_COLLECTION_SUCCESS:
      return {
        ...state,
        loaded: true,
        pending: false,
        list: action.payload,
      };
    case MyCollectionActionTypes.LOAD_MY_COLLECTION_ERROR:
      return { ...state, error: action.payload };
    case MyCollectionActionTypes.SET_MY_COLLECTION_SELECTED_BRAND:
      return {
        ...state,
        selectedBrand: action.payload,
      };
    case MyCollectionActionTypes.SEARCH_MY_COLLECTION:
      return {
        ...state,
        searchTerm: action.payload,
      };
    case MyCollectionActionTypes.UPDATE_RETAIL_PRICE:
      return {
        ...state,
        list: {
          brands: [
            ...state.list.brands.slice(0, action.payload.brandId),
            {
              ...state.list.brands[action.payload.brandId],
              modules: [
                ...state.list.brands[action.payload.brandId].modules.slice(
                  0,
                  action.payload.moduleId,
                ),
                {
                  ...state.list.brands[action.payload.brandId].modules[
                    action.payload.moduleId
                  ],
                  frames: action.payload.frames,
                },
                ...state.list.brands[action.payload.brandId].modules.slice(
                  action.payload.moduleId + 1,
                  state.list.brands[action.payload.brandId].modules.length + 1,
                ),
              ],
            },
            ...state.list.brands.slice(
              action.payload.brandId + 1,
              state.list.brands.length + 1,
            ),
          ],
        },
      };
    default:
      return state;
  }
};
