import { Component, Input, OnInit, HostBinding, OnDestroy, ChangeDetectionStrategy } from '@angular/core';
import { User } from '@app/core/models';
import { BrowserGlobalRef } from '@app/shared/global-ref';
import { Subscription } from 'rxjs';

import { Store } from '@ngrx/store';
import * as fromRedux from '@app/core/ngrx';
import { selectProfile } from '@app/core/ngrx';

@Component({
  selector: 'ecp-widget',
  templateUrl: './widget.component.html',
  styleUrls: ['./widget.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WidgetComponent implements OnInit, OnDestroy {
  @Input() title: string;
  @Input() application: string;
  @Input() status: string;
  @Input() loading = false;

  // element: HTMLElement;

  isAllowed = false;

  private subscription$: Subscription;

  constructor(private store: Store<fromRedux.AppState>, private _global: BrowserGlobalRef) {}

  @HostBinding('class.widget') widget_ = true;
  @HostBinding('class.is-hidden') isHidden = false;

  ngOnInit(): void {
    if (this.status || this.application) {
      this.subscription$ = this.store.select(selectProfile).subscribe((user: User) => {
        if (user) {
          this.isAllowed = true; // this.checkUserAccess(user.applicationProfiles);
          this.isHidden = !this.isAllowed;
        }
      });
    } else {
      this.isAllowed = true;
      this.isHidden = !this.isAllowed;
    }

    this.sendTracking();
  }

  // checkUserAccess(profiles: ApplicationProfile[]): boolean {
  //   if (this.application && this.status) {
  //     return profiles.some(
  //       profile =>
  //         profile.application === this.application &&
  //         this.status.split('|').includes(profile.status),
  //     );
  //   } else if (this.application) {
  //     return profiles.some(profile => profile.application === this.application);
  //   }
  //
  //   return true;
  // }

  sendTracking(): void {
    if (this.title) {
      this._global.nativeGlobal.dataLayer.push({
        event: 'widget',
        widgetId: this.title,
      });
    }
  }

  ngOnDestroy(): void {
    if (this.subscription$) {
      this.subscription$.unsubscribe();
    }
  }
}
