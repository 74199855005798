import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

import { AuthProtectedGuard, AuthPublicGuard } from '@app/auth/guards';
import { CanLeaveService } from '@app/core/services/can-leave.service';
import { PatientUserResolver, UserResolver } from '@app/core/resolvers';
// import { BrandsResolver } from '@app/core/resolvers/brands.resolver';

const routes: Routes = [
  {
    path: 'auth',
    loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule),
  },

  {
    path: 'forgot-password',
    canActivate: [AuthPublicGuard],
    loadChildren: () => import('./forgot-password/forgot-password.module').then(m => m.ForgotPasswordModule),
    data: { title: 'Forgot Password' },
  },

  {
    path: 'reset-password',
    canActivate: [AuthPublicGuard],
    loadChildren: () => import('./reset-password/reset-password.module').then(m => m.ResetPasswordModule),
    data: { title: 'Reset Password' },
  },

  {
    path: 'reset-password/:email',
    canActivate: [AuthPublicGuard],
    loadChildren: () => import('./reset-password/reset-password.module').then(m => m.ResetPasswordModule),
    data: { title: 'Reset Password' },
  },

  {
    path: '',
    pathMatch: 'full',
    loadChildren: () => import('./welcome/welcome.module').then(m => m.WelcomeModule),
    canActivate: [AuthPublicGuard],
  },

  {
    path: 'catalog',
    loadChildren: () => import('./catalog/catalog.module').then(m => m.CatalogModule),
    resolve: { user: UserResolver },
    canActivate: [AuthProtectedGuard],
    data: { title: 'My Catalog' },
  },

  {
    path: 'my-collection',
    loadChildren: () => import('./my-collection/my-collection.module').then(m => m.MyCollectionModule),
    resolve: { user: UserResolver },
    canActivate: [AuthProtectedGuard],
    canDeactivate: [CanLeaveService],
    data: { title: 'My Collection' },
  },

  {
    path: 'refresh-return',
    loadChildren: () => import('./my-return/my-return.module').then(m => m.MyReturnModule),
    resolve: { user: UserResolver },
    canActivate: [AuthProtectedGuard],
    canDeactivate: [CanLeaveService],
    data: { title: 'Refresh Return' },
  },

  {
    path: 'frame-return',
    loadChildren: () => import('./frame-returns/frame-return.module').then(m => m.FrameReturnModule),
    resolve: { user: UserResolver },
    canActivate: [AuthProtectedGuard],
    canDeactivate: [CanLeaveService],
    data: { title: 'Frame Return' },
  },

  {
    path: 'returns-status',
    loadChildren: () =>
      import('./frame-returns-status/frame-returns-status.module').then(m => m.FrameReturnsStatusModule),
    resolve: { user: UserResolver },
    canActivate: [AuthProtectedGuard],
    data: { title: 'Return Request Status' },
  },

  {
    path: 'returns-cart',
    loadChildren: () => import('./frame-returns-cart/frame-returns-cart.module').then(m => m.FrameReturnsCartModule),
    resolve: { user: UserResolver },
    canActivate: [AuthProtectedGuard],
    data: { title: 'Returns Cart' },
  },

  {
    path: 'my-orders',
    loadChildren: () => import('./my-orders/my-orders.module').then(m => m.MyOrdersModule),
    resolve: { user: UserResolver },
    canActivate: [AuthProtectedGuard],
    data: { title: 'My Orders' },
  },

  {
    path: 'realignment-orders',
    loadChildren: () => import('./realignment-orders/realignment-orders.module').then(m => m.RealignmentOrdersModule),
    resolve: { user: UserResolver },
    canActivate: [AuthProtectedGuard],
    data: { title: 'Realignment Orders' },
  },

  {
    path: 'order',
    loadChildren: () => import('./order/order.module').then(m => m.OrderModule),
    data: { title: 'Order' },
  },
  
  {
    path: 'bulk-orders',
    loadChildren: () => import('./bulk-orders/bulk-orders.module').then(m => m.BulkOrdersModule),
    resolve: { user: UserResolver },
    canActivate: [AuthProtectedGuard],
    data: { title: 'Bulk Orders' },
  },

  {
    path: 'patient-carts',
    loadChildren: () => import('./patient-carts/patient-carts.module').then(m => m.PatientCartsModule),
    resolve: { user: UserResolver },
    canActivate: [AuthProtectedGuard],
    data: { title: 'Patient Carts' },
  },

  {
    path: 'help-center',
    loadChildren: () => import('./help-center/help-center.module').then(m => m.HelpCenterModule),
    resolve: { user: UserResolver },
    canActivate: [AuthProtectedGuard],
    data: { title: 'Help Center' },
  },
  
  {
    path: 'bulk-order-upload',
    loadChildren: () => import('./bulk-upload-orders/bulk-upload-orders.module').then(m => m.BulkUploadOrdersModule),
    resolve: { user: UserResolver },
    data: { title: 'Bulk Order Upload' },
  },

  {
    path: 'frame-to-come',
    loadChildren: () => import('./frame-to-come/frame-to-come.module').then(m => m.FrameToComeModule),
    resolve: { user: UserResolver },
    data: { title: 'Frame to Come' },
  },

  {
    path: 'support',
    loadChildren: () => import('./support/support.module').then(m => m.SupportModule),
    canActivate: [AuthPublicGuard],
    data: { title: 'Support Center' },
  },

/*   {
    path: ':id',
    pathMatch: 'full',
    loadChildren: () => import('./welcome-patient/welcome-patient.module').then(m => m.WelcomePatientModule),
    resolve: { user: PatientUserResolver },
    canActivate: [AuthPublicGuard],
  },

  {
    path: 'v2/:id',
    pathMatch: 'full',
    loadChildren: () => import('./welcome-patient/welcome-patient.module').then(m => m.WelcomePatientModule),
    resolve: { user: PatientUserResolver },
    canActivate: [AuthPublicGuard],
  }, */

  {
    path: ':id/shopping-cart',
    loadChildren: () => import('./shopping-cart/shopping-cart.module').then(m => m.ShoppingCartModule),
    resolve: { user: PatientUserResolver },
    data: { title: 'My Favorites' },
  },

  { path: '**', redirectTo: '/' },
];

/**
 * This module defines the root level routing used for the AppComponent's <router-outlet>.
 */
@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      useHash: false,
      preloadingStrategy: PreloadAllModules,
      scrollPositionRestoration: 'enabled',
      anchorScrolling: 'enabled',
      scrollOffset: [0, 70],
      relativeLinkResolution: 'legacy',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
