import {
  Component,
  Input,
  HostBinding,
  ChangeDetectionStrategy,
  ViewChildren,
  QueryList,
  AfterContentChecked,
  ContentChildren,
  OnInit,
} from '@angular/core';
import { CartFrames, MenuItem } from '@app/core/models';
import { NavItemLinkDirective } from './nav-item-link.directive';
import { Observable } from 'rxjs/internal/Observable';
import { select, Store } from '@ngrx/store';
import { AppState } from '@app/core/ngrx';

@Component({
  selector: 'ecp-nav-item',
  templateUrl: './nav-item.component.html',
  styleUrls: ['./nav-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavItemComponent implements OnInit, AfterContentChecked {
  framesCart$: Observable<CartFrames[]>;
  @HostBinding('class.nav__item') nav__item_ = true;
  @Input() label: string;
  @Input() title: string;
  @Input() icon: string[];
  @Input() link: string;
  @Input() fragment: string;
  @Input() ext: boolean;
  @Input() children: MenuItem[] = [];
  @Input() noLink: boolean;

  @ContentChildren(NavItemLinkDirective)
  contentLinks: QueryList<NavItemLinkDirective>;

  @ViewChildren(NavItemLinkDirective) links: QueryList<NavItemLinkDirective>;

  @HostBinding('class.is-active') hasActiveLinks = false;

  constructor(private store: Store<AppState>) {}

  ngOnInit() {
    this.framesCart$ = this.store.pipe(select(state => state.framesCart.framesCart));
  }

  get grouped(): boolean {
    return this.children.some(item => !item.link);
  }

  ngAfterContentChecked(): void {
    this.checkLinks();
  }

  checkLinks(): void {
    if (!this.links) return;

    this.hasActiveLinks = [...this.links.toArray(), ...this.contentLinks.toArray()].some(link => {
      return link.element.nativeElement.classList.contains('is-active');
    });
  }
}
