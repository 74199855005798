import { Store } from '@ngrx/store';
import { Injectable } from '@angular/core';
import {
  Resolve,
  ActivatedRoute,
  ActivatedRouteSnapshot,
} from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError, tap, timeout } from 'rxjs/operators';
import { AppState, UserActionTypes } from '@app/core/ngrx';
import { UserService } from '../services';

/**
 * UserResolver
 */
@Injectable({ providedIn: 'root' })
export class PatientUserResolver implements Resolve<Observable<any>> {
  /**
   * constructor
   * @param {Store<AppState>} store
   * @param {UserService} userService
   */
  constructor(
    private store: Store<AppState>,
    private userService: UserService,
  ) {}

  /**
   * resolve
   * @returns {Observable<any>}
   */
  resolve(route: ActivatedRouteSnapshot): Observable<any> {
    let patientID = route.paramMap.get('id');
    // 8/12/20 - When the login dialog popup occurs it somehow triggers a route change with a # as the param. Ignore it.
    if (patientID !== '#') {
      this.store.dispatch({
        type: UserActionTypes.LOAD_PATIENT_ID,
        payload: patientID,
      });
      return this.userService.getPatientUser(patientID).pipe(
        timeout(5000),
        tap(res => {
          this.store.dispatch({
            type: UserActionTypes.LOAD_USER_SUCCESS,
            payload: res,
          });
        }),
        catchError(err => {
          this.store.dispatch({
            type: UserActionTypes.LOAD_PATIENT_ERROR,
            payload: {
              name: 'Sorry, there seems to be a problem',
              message:
                "We can' location the frame catalog you are trying to find. Please contact your Eye Care Professional for assistance.",
            },
          });

          return of(null);
        }),
      );
    }
  }
}
