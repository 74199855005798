import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterByBrand',
  pure: true,
})
export class FilterByBrand implements PipeTransform {
  transform(myCollection: Array<any>, brand?: any): Array<any> {
    if (
      !myCollection ||
      !Array.isArray(myCollection) ||
      !brand ||
      brand === 'All Brands'
    )
      return myCollection;

    let newCollection = myCollection.filter(element => {
      return element.brandName === brand;
    });

    return newCollection;
  }
}
