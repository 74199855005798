import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { SwitchProfileActionTypes } from '../actions';
import { mergeMap, map, takeUntil, catchError, switchMap } from 'rxjs/operators';
import { Actions, Effect, ofType, createEffect } from '@ngrx/effects';
import { UserService } from '@app/core/services';

@Injectable()
export class SwitchProfileEffects {
  constructor(private actions$: Actions, private userService: UserService) {}

  switchProfile = createEffect(() =>
    this.actions$.pipe(
      ofType(SwitchProfileActionTypes.SWITCH_PROFILE),
      switchMap(({ payload }) => {
        return this.userService.switchProfile(payload).pipe(
          map(user => ({
            type: SwitchProfileActionTypes.SWITCH_PROFILE_SUCCESS,
            payload: user,
          })),
          catchError(err => of({ type: SwitchProfileActionTypes.SWITCH_PROFILE_ERROR, payload: err })),
        );
      }),
    ),
  );
}
