import { FrameToComeActions, FrameToComeActionTypes } from '@app/core/ngrx';

/**
 * FRAME TO SEARCH
 */
export interface FrameToComeSearchState {
  loaded: boolean;
  pending: boolean;
  error: any;
  frame:any;
  search:any;
}

export const FRAME_TO_COME_SEARCH_INITIAL_STATE: FrameToComeSearchState = {
  loaded: false,
  pending: false,
  error: null,
  frame: null,
  search:null
};
/**
 * FRAME TO SEARCH
 * @param state
 * @param action
 */
export const frameToComeSearchReducer = (
  state: FrameToComeSearchState = FRAME_TO_COME_SEARCH_INITIAL_STATE,
  action: FrameToComeActions,
): FrameToComeSearchState => {
  switch (action.type) {
    case FrameToComeActionTypes.SEARCH_FRAME_TO_COME:
      return {
        ...state,
        error: null,
        loaded: false,
        pending: true,
        search: action.payload
      };
    case FrameToComeActionTypes.SEARCH_FRAME_TO_COME_SUCCESS:
      return {
        ...state,
        loaded: true,
        pending: false,
        frame: action.payload,
      };
    case FrameToComeActionTypes.SEARCH_FRAME_TO_COME_ERROR:
        return { ...state, frame:null, error: action.payload, loaded: false, pending:false};
    default:
      return state;
  }
};
