import { EcpCurrencyPipe } from './currency.pipe';
import { EscapeCharsPipe } from './escape-chars.pipe';
import { FirstPipe } from './first.pipe';
import { PhoneMaskPipe } from './mask-phone.pipe';
import { FilterByBrand } from '@app/shared/pipes/filter-by-brand.pipe';
import { FilterMultipleBrand } from '@app/shared/pipes/filter-multiple-brand.pipe';
import { SearchList } from '@app/shared/pipes/search-list.pipe';

export const pipes: any[] = [
  EcpCurrencyPipe,
  EscapeCharsPipe,
  FirstPipe,
  PhoneMaskPipe,
  FilterByBrand,
  SearchList,
  FilterMultipleBrand,
];

export * from './currency.pipe';
export * from './escape-chars.pipe';
export * from './first.pipe';
export * from './mask-phone.pipe';
export * from './filter-by-brand.pipe';
export * from './search-list.pipe';
export * from './filter-multiple-brand.pipe';
