<ol class="breadcrumb">
  <li *ngFor="let breadcrumb of breadcrumbs">
    <span
      [routerLink]="breadcrumb.url"
      routerLinkActive="router-link-active"
      [routerLinkActiveOptions]="{ exact: true }"
    >
      {{ breadcrumb.label }}
    </span>
  </li>
</ol>
