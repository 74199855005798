import { Injectable } from '@angular/core';
import { ApiService } from './api.service';

@Injectable({ providedIn: 'root' })
export class ImageService {
  constructor(private apiService: ApiService) {}
  getImageData(param) {
    return this.apiService.get(`/image/imagedata/${param}`);
  }
}
