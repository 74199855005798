import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import {
  // MAT_LABEL_GLOBAL_OPTIONS,
  MAT_RIPPLE_GLOBAL_OPTIONS,
  MatNativeDateModule,
} from '@angular/material/core';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';

import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSortModule } from '@angular/material/sort';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTableModule } from '@angular/material/table';

/**
 * providers
 * @type {({provide: InjectionToken<RippleGlobalOptions>; useValue: {disabled: boolean}} | {provide: InjectionToken<LabelOptions>; useValue: {float: string}})[]}
 */
export const providers: any[] = [
  { provide: MAT_RIPPLE_GLOBAL_OPTIONS, useValue: { disabled: true } },
  //{ provide: MAT_LABEL_GLOBAL_OPTIONS, useValue: { float: 'never' } },
  {
    provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
    useValue: { floatLabel: 'always' },
  },
  { provide: MAT_DIALOG_DATA, useValue: {} },
];
/**
 * modules
 * @type {(MatCardModule | MatDialogModule | MatExpansionModule | MatFormFieldModule | MatIconModule | MatInputModule | MatListModule | MatPaginatorModule | MatSortModule | MatSelectModule | MatStepperModule | MatTableModule | MatTooltipModule | MatSidenavModule | MatCheckboxModule | MatTabsModule | MatRadioModule)[]}
 */
export const modules: any[] = [
  MatCardModule,
  MatCheckboxModule,
  MatDialogModule,
  MatExpansionModule,
  MatFormFieldModule,
  MatIconModule,
  MatInputModule,
  MatPaginatorModule,
  MatSortModule,
  MatSelectModule,
  MatTabsModule,
  MatRadioModule,
  MatSidenavModule,
  MatDatepickerModule,
  MatNativeDateModule,
  MatToolbarModule,
  MatTooltipModule,
  MatSnackBarModule,
  MatTableModule,
];
