import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterMultipleBrand',
  pure: true,
})
export class FilterMultipleBrand implements PipeTransform {
  transform(myCollection: Array<any>, brandArray: Array<any>): Array<any> {
    if (
      !myCollection ||
      !Array.isArray(myCollection) ||
      !brandArray ||
      !Array.isArray(brandArray) ||
      brandArray.length == 0
    ) {
      return myCollection;
    }

    let newCollection = myCollection.filter(element => {
      // brandArray.forEach(brand => {
      return brandArray.indexOf(element.brandName) !== -1;
      // });
    });

    return newCollection;
  }
}
