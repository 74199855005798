import { Injectable } from '@angular/core';
import * as XLSX from 'xlsx';

/**
 * BrandsService
 */
@Injectable({ providedIn: 'root' })
export class CsvDownloadService {
  exportToExcel(data, fileName = 'New Frames.xlsx') {
    let newFrames = [];
    if (data.newFrames) {
      data.newFrames.forEach(x => {
        newFrames.push({
          Brand: x.brandName,
          FPC: x.fpc,
          UPC: x.upc,
          Style: x.styleName,
          Color: x.colorDescription,
          Size: x.eyeSize + ',' + x.temple,
          WholesalePrice: x.wholesalePrice,
          PurchasePrice: x.purchasePrice,
          Category: x.categoryName,
          Role: x.moduleName,
          Vendor: x.vendorName,
        });
      });
    }

    let allFrames = [];
    data.allFrames.forEach(x => {
      allFrames.push({
        Brand: x.brandName,
        FPC: x.fpc,
        UPC: x.upc,
        Style: x.styleName,
        Color: x.colorDescription,
        Size: x.eyeSize + ',' + x.temple,
        WholesalePrice: x.wholesalePrice,
        PurchasePrice: x.purchasePrice,
        Category: x.categoryName,
        Role: x.moduleName,
        Vendor: x.vendorName,
      });
    });

    let returnFrames = [];
    data.returnFrames.forEach(x => {
      returnFrames.push({
        Brand: x.brandName,
        FPC: x.fpc,
        UPC: x.upc,
        Style: x.styleName,
        Color: x.colorDescription,
        Size: x.eyeSize + ',' + x.temple,
        WholesalePrice: x.wholesalePrice,
        PurchasePrice: x.purchasePrice,
        Category: x.categoryName,
        Role: x.moduleName,
        Vendor: x.vendorName,
      });
    });

    const newWorkSheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(newFrames);
    const allWorkSheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(allFrames);
    const returnWorkSheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(
      returnFrames,
    );
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, newWorkSheet, 'New Frames');
    XLSX.utils.book_append_sheet(wb, returnWorkSheet, 'Return Frames');
    XLSX.utils.book_append_sheet(wb, allWorkSheet, 'All Frames');
    XLSX.writeFile(wb, fileName);
  }
}
