import { User } from '@app/core/models';
import { SwitchProfileActions, SwitchProfileActionTypes } from '../actions';

/**
 * SwitchProfileState
 */
export interface SwitchProfileState {
  loaded: boolean;
  pending: boolean;
  error: any;
  profile: User;
  account: any;
}

/**
 * SWITCH_PROFILE_INITIAL_STATE
 * @type {{loaded: boolean; pending: boolean; error: null; profile: null}}
 */
export const SWITCH_PROFILE_INITIAL_STATE: SwitchProfileState = {
  loaded: false,
  pending: false,
  error: null,
  profile: {
    userName: null,
    businessName: null,
    firstName: null,
    lastName: null,
    program: {
      programKey: null,
      programName: null,
      isOrderable: false,
      isCatalogVisible: false,
      logoPath: 'assets/images/essilor-logo-white.svg',
      contactNumber: null,
      newsletterTitle: null,
      resourcesTitle: null,
      programOverviewTitle: null,
      assignedLab: null,
      hidePricing: null,
    },
    token: null,
    account: null,
    isPremiumMember: false,
    featureFlags: null,
    patientPreviewUrl: null,
    primaryLab: null,
    labAccountNumber: null,
    address: null,
    cityState: null,
    email: null,
    phoneNumber: null,
  },
  account: '',
};
/**
 * switchProfileReducer
 * @param {SwitchProfileState} state
 * @param {SwitchProfileActions} action
 * @returns {SwitchProfileState}
 */
export const switchProfileReducer = (
  state: SwitchProfileState = SWITCH_PROFILE_INITIAL_STATE,
  action: SwitchProfileActions,
): SwitchProfileState => {
  switch (action.type) {
    case SwitchProfileActionTypes.SWITCH_PROFILE:
      return {
        ...state,
        error: null,
        loaded: false,
        pending: true,
      };
    case SwitchProfileActionTypes.SWITCH_PROFILE_SUCCESS:
      return {
        ...state,
        loaded: true,
        pending: false,
        profile: { ...state.profile, ...action.payload },
        account: action.payload
      };
    case SwitchProfileActionTypes.SWITCH_PROFILE_ERROR:
      return { ...state, error: action.payload };
    default:
      return state;
  }
};
