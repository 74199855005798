import { Injectable } from '@angular/core';
import { LayoutActionTypes } from '@app/core/ngrx/actions';
import { concat, Observable, of } from 'rxjs';
import { flatMap } from 'rxjs/operators';
import { Actions, Effect, ofType, createEffect } from '@ngrx/effects';
import { ROUTER_NAVIGATION } from '@ngrx/router-store';

/**
 * LayoutEpics
 */
@Injectable()
export class LayoutEffects {
  constructor(private actions$: Actions) {}
  /**
   * onNavigation
   * @param {ActionsObservable<any>} action$
   * @returns {Observable<any>}
   */
  onNavigation = createEffect(() =>
    this.actions$.pipe(
      ofType(ROUTER_NAVIGATION),
      flatMap(() => concat(of({ type: LayoutActionTypes.CLOSE_SIDENAV }))),
    ),
  );
}
