import { Component, ViewChild, Input, OnInit } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { MenuItem } from '@app/core/models/menu.model';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { AppState } from '@app/core/ngrx';

@Component({
  selector: 'ecp-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss'],
})
export class SidenavComponent {
  @Input() appNav: MenuItem[];
  @Input() userNav: MenuItem[];

  @ViewChild('sidenav') sidenav: MatSidenav;

  reason = '';

  step = -1;

  subStep = -1;

  // @select(['layout', 'sidenav'])
  state: Observable<any>;

  constructor(private store: Store<AppState>) {
    this.state = this.store.pipe(select(state => state.layout.sidenav));
  }

  hasOneItem(item: MenuItem): boolean {
    if (!item.children) {
      return false;
    }

    return item.children.length === 1;
  }

  hasChildren(item: MenuItem): boolean {
    if (!item.children) {
      return false;
    }

    const childrenLength: number = item.children.reduce(
      (total: number, current: MenuItem) => {
        if (!current.children) {
          return total;
        }

        return total + current.children.length;
      },
      0,
    );

    return !!childrenLength;
  }

  setStep(index: number) {
    this.step = index;
    this.subStep = -1;
  }

  setSubStep(index: number) {
    this.subStep = index;
  }
}
