import { Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { select, Store } from '@ngrx/store';
import { AppState } from '@app/core/ngrx';
import { CartFrames, Frames } from '@app/core/models/frames.model';

@Component({
  selector: 'ecp-cart-dropdown',
  templateUrl: './cart-dropdown.component.html',
  styleUrls: ['./cart-dropdown.component.scss'],
})
export class CartDropdownComponent implements OnInit {
  framesCart$: Observable<CartFrames[]>;
  @Input() patientId;
  constructor(private store: Store<AppState>) {}

  ngOnInit() {
    this.framesCart$ = this.store.pipe(
      select(state => state.framesCart.framesCart),
    );
  }
}
