<footer class="footer">
  <div class="container footer__social"></div>
  <div class="container" *showAuthed="true">
    <div class="row">
      <div class="col s12 text-center">
        <ul>
          <li>
            <a
              href="https://www.essilorusa.com/about"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="About Essilor"
              title="Our Company"
            >
              Our Company
            </a>
          </li>
          <li>
            <a
              href="https://www.essilorusa.com/careers"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="Essilor careers"
              title="Careers"
            >
              Careers
            </a>
          </li>
          <li>
            <a
              href="https://www.essilorusa.com/newsroom"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="Essilor news"
              title="Newroom"
            >
              Newsroom
            </a>
          </li>
          <li>
            <a
              href="https://www.essilorusa.com/about/essilor-group"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="About Essilor group"
              title="Essilor Group"
            >
              Essilor Group
            </a>
          </li>
          <li>
            <a routerLink="/contact-us" target="_self" title="Contact us" aria-label="Contact us">
              Contact us
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
  <div class="footer__ribbon">
    <div class="container">
      <div class="row">
        <div class="col s12 m6 hide-mobile">
          <small class="footer__ribbon-copyright">©2018-2019 ESSILOR OF AMERICA, INC. ALL RIGHTS RESERVED.</small>
          <small>v2.3.0</small>
        </div>
        <div class="col s12 m6">
          <ul class="footer__ribbon-nav">
            <li>
              <a
                href="https://www.essilorusa.com/terms-and-conditions"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="Terms of use and conditions"
                aria-ta
                title="Terms of use"
              >
                Terms of Use
              </a>
            </li>
            <li>
              <a
                href="https://www.essilorusa.com/privacy-policy"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="Privacy Policy"
                title="Privacy Policy"
              >
                Privacy Policy
              </a>
            </li>
            <li>
              <a
                href="https://www.essilorusa.com/about/ethics-compliance"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="Ethics & Compliance"
                title="Ethics & Compliance"
              >
                Ethics & Compliance
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</footer>
<div class="container footer__ribbon-mobile hide-desktop">
  <div class="col s12">
    <small class="footer__ribbon-copyright">
      ©2017-2020 ESSILOR OF AMERICA, INC. ALL RIGHTS RESERVED.
    </small>
    <small>v2.3.0</small>
  </div>
</div>
