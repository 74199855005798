import { Store, select, State } from '@ngrx/store';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {
  AppState,
  SwitchProfileActionTypes,
  UserActionTypes,
  selectPatientId,
  selectProfile,
  selectSwitchProfile,
} from '@app/core/ngrx';
import { NavigationService } from '@app/core/services/navigation.service';
import { LoginDialogComponent } from '@app/shared/components';
import { BrowserGlobalRef } from '@app/shared/global-ref';
import { AppMenuItems, UserMenuItems } from '@app/shared/data/navigation.data';
import { MenuItem, User } from '@app/core/models';
import { Subscription } from 'rxjs/internal/Subscription';
import { Observable } from 'rxjs';
import { UserService } from '@app/core/services';

@Component({
  selector: 'ecp-portal',
  templateUrl: './portal.component.html',
  styleUrls: ['./portal.component.scss'],
})
export class PortalComponent implements OnInit, OnDestroy {
  /**
   * subscription$
   */
  private subscription$: Subscription;
  /**
   * menu
   */
  public menu: any = {
    app: [],
    user: [],
  };

  /**
   * appNav
   */
  public appNav: MenuItem[] = AppMenuItems;
  /**
   * userNav
   */
  public userNav: MenuItem[] = UserMenuItems;
  public patientId$: Observable<string>;
  public superUserData: any;

  constructor(
    private store: Store<AppState>,
    private navigationService: NavigationService,
    private _global: BrowserGlobalRef,
    public dialog: MatDialog,
    private state$: State<AppState>,
    private userService: UserService,
  ) {
    this.selectUser();
  }
  selectUser() {
    let profile$ = this.store.select(selectSwitchProfile);
    this.subscription$ = this.store.select(selectProfile).subscribe((user: User) => {
      if (user) {
        //Get EcpRole and location
        let navItems = this.appNav.filter(ap => {
          return ap.order !== 7;
        });
        if (user.ecpRole == 'superuser') {
          profile$.subscribe(prof => {
            if (prof.account !== null) {
              if (prof.account.toLowerCase().trim() !== user.account.toLowerCase().trim()) {
                this.store.dispatch({
                  type: UserActionTypes.LOAD_USER_SUCCESS,
                  payload: prof,
                });
              }
              this.superUserData = {
                ecpRole: prof.ecpRole,
                ecpLocations: prof.locations.map(e => e.trim()),
                accountLocation: prof.account.toUpperCase(),
                defaultLoc: prof.account.toUpperCase(),
              };
            } else {
              this.superUserData = {
                ecpRole: user.ecpRole,
                ecpLocations: user.locations.map(e => e.trim()),
                accountLocation: user.account.toUpperCase(),
                defaultLoc: user.account.toUpperCase(),
              };
            }
          });
          this.appNav = AppMenuItems;
        } else {
          this.appNav = navItems;
        }
        if (this.state$.getValue().user.patientID) {
          this.setupPatientNavigation(this.state$.getValue().user.patientID);
          if (!user.isPremiumMember) {
            this.menu.app.splice(1, 1); // Remove MyFavorites link from patient portal if the ECP is not  a premium member
          }
        } else if (this.state$.getValue().user.loaded) {
          // only display the navigation if the user is loaded
          this.setupNavigationItems();
          if (!user.isPremiumMember) {
            let index = this.menu.app.findIndex(element => {
              if (element.label === 'Patient Cart') {
                return true;
              }
            });
            if (index !== -1) this.menu.app.splice(index, 1); // Remove MyCart if the ECP is not a premium member

            // Temporary fix to enable Frame To Come for FOF only for initial launch. 
            var subMenu = this.menu.app[2].children;
            if(user.program.programKey !== 'FOF')
            {
              let idx = subMenu.findIndex(element => {
                if (element.label === 'Frame to Come') {
                  return true;
                }
              });
              if (idx !== -1) subMenu.splice(idx, 1); // Remove Frame To Come if the ECP is not a FOF && Premium
            }
          }
        }
      }
    });

    this.patientId$ = this.store.select(selectPatientId);
  }
  ngOnInit() {}

  setupNavigationItems(): void {
    this.menu = {
      app: this.appNav,
      user: this.userNav,
    };
  }

  setupPatientNavigation(patientId) {
    this.menu = {
      app: [
        {
          label: 'Catalog',
          link: '/' + patientId,
          order: 1,
        },
        {
          label: 'My Favorites',
          link: '/' + patientId + '/shopping-cart',
          order: 2,
        },
      ],
    };
  }

  openLoginDialog(event): void {
    this.dialog.open(LoginDialogComponent, {
      width: '345px',
      maxWidth: '89.3vw',
      maxHeight: '82vh',
    });
  }
  switchLocation(ev: any): void {
    this.store.dispatch({
      type: SwitchProfileActionTypes.SWITCH_PROFILE,
      payload: ev.trim(),
    });
  }
  ngOnDestroy(): void {
    this.subscription$.unsubscribe();
  }
}
