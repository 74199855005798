<div [class]="theme ? theme : 'one-portal'">
  <button class="mat-dialog-close" mat-dialog-close>
    <mat-icon svgIcon="close"></mat-icon>
  </button>

  <!-- Dialog: Header -->
  <div class="mat-dialog-header">
    <h2 *ngIf="data.title" [ngClass]="{ error: data.title === 'Notification' }">
      <fa-icon icon="exclamation-triangle" *ngIf="data.title === 'Notification'"></fa-icon>
      {{ data.title }}
    </h2>
  </div>

  <!-- Dialog: Content -->
  <div class="mat-dialog-body">
    <p *ngIf="data.content">{{ data.content }}</p>
    <div *ngIf="data.contentHtml" [innerHTML]="data.contentHtml"></div>
  </div>

  <!-- Dialog: Footer/Actions -->
  <div class="mat-dialog-footer" mat-dialog-actions>
    <div class="btn-group">
      <button
        cdkFocusInitial
        class="btn btn-primary"
        *ngIf="data.buttonClicked; else buttonWithoutAction"
        [mat-dialog-close]="true"
        (click)="data.buttonClicked()"
      >
        {{ data.confirmBtnText }}
      </button>
      <ng-template #buttonWithoutAction>
        <button cdkFocusInitial class="btn btn-primary" [mat-dialog-close]="true">
          {{ data.confirmBtnText }}
        </button>
      </ng-template>
    </div>
  </div>
</div>
