<div [class]="theme ? theme : 'one-portal'">
  <button class="mat-dialog-close" mat-dialog-close>
    <mat-icon svgIcon="close"></mat-icon>
  </button>

  <!-- Dialog: Header -->
  <div class="mat-dialog-header">
    <h2>
      Success!
    </h2>
    <p>You added the selected frame to "My Favorites"</p>
  </div>

  <!-- Dialog: Footer/Actions -->
  <div class="mat-dialog-footer" mat-dialog-actions>
    <div class="btn-group-vertical">
      <button class="btn btn-outline" [mat-dialog-close]="true">
        Continue Browsing
      </button>
      <button cdkFocusInitial class="btn btn-primary" [mat-dialog-close]="true" (click)="goToFrameCart(data.patientId)">
        View "My Favorites"
      </button>
    </div>
  </div>
</div>
