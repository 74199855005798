import { Store } from '@ngrx/store';
import { Component, NgZone, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppState, AuthActionTypes } from '@app/core/ngrx';

/**
 * LogoutComponent
 */
@Component({
  selector: 'ecp-auth-logout',
  template: '',
})
export class LogoutComponent implements OnInit {
  /**
   * constructor
   * @param {Store<AppState>} store
   * @param {Router} router
   */
  constructor(
    private _ngZone: NgZone,
    private store: Store<AppState>,
    private router: Router,
  ) {}

  /**
   * ngOnInit
   */
  ngOnInit(): void {
    this.store.dispatch({ type: AuthActionTypes.LOGOUT });
    this._ngZone.run(() => this.router.navigateByUrl(''));
  }
}
