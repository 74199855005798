import { ErrorHandler, Injectable, Injector, isDevMode } from '@angular/core';
import { Router } from '@angular/router';
import { BAD_REQUEST, FORBIDDEN, UNAUTHORIZED } from 'http-status-codes';
import { NotificationService } from './notification.service';
import { HttpErrorResponse } from '@angular/common/http';
import { AuthService } from '@app/auth/auth.service';

/**
 * EcpErrorHandler
 */
@Injectable({ providedIn: 'root' })
export class EcpErrorHandler implements ErrorHandler {
  /**
   * DEFAULT_ERROR_MESSAGE
   * @type {{title: string; content: string; confirmBtnText: string}}
   */
  static readonly DEFAULT_ERROR_MESSAGE: any = {
    title: 'We’re sorry!',
    content: 'There appears to be a problem — please try again later.',
    confirmBtnText: 'Close',
  };

  /**
   * constructor
   * @param {Injector} inject
   * @param {AuthService} authService
   */
  constructor(private inject: Injector) {}

  /**
   * handleHttpError
   * @param {HttpErrorResponse} error
   */
  private handleHttpError(error: HttpErrorResponse): void {
    const errorCode = error.status;
    const router = this.inject.get(Router);
    const notifier = this.inject.get(NotificationService);
    const authService = this.inject.get(AuthService);
    switch (errorCode) {
      case UNAUTHORIZED:
        authService.logout();
        break;
      case FORBIDDEN:
        router.navigateByUrl('/');
        break;
      case BAD_REQUEST:
        notifier.error(EcpErrorHandler.DEFAULT_ERROR_MESSAGE);
        break;
      case 0:
        break;
      default:
        notifier.error(EcpErrorHandler.DEFAULT_ERROR_MESSAGE);
    }
  }

  /**
   * handleError
   * @param {Error | HttpErrorResponse} error
   */
  public handleError(error: Error | HttpErrorResponse): void {
    if (error instanceof HttpErrorResponse) {
      this.handleHttpError(error);
    }
    if (isDevMode()) {
      console.error('--- ERROR HANDLER ---');
      console.error(error.message);
      console.error(error);
    }
  }
}
