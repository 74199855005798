import { Component, NgZone, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'environments/environment';
import { AuthService } from 'app/auth/auth.service';
import { OktaAuth, OktaAuthOptions } from '@okta/okta-auth-js';
import { AppState, AuthActionTypes } from '@app/core/ngrx';
import { Store } from '@ngrx/store';

@Component({
  selector: 'ecp-auth-idp',
  template: `
    <div style="padding: 5%;text-align:center;height:100%;background-color:#fff;color:#B0BEC5;">
      ... loading
    </div>
  `,
})
export class IdpComponent implements OnInit {
  /**
   * config
   */
  config: OktaAuthOptions = environment.okta.client;
  /**
   * authClient
   */
  authClient = new OktaAuth(this.config);

  /**
   * constructor
   * @param ngZone
   * @param authService
   * @param store
   * @param router
   */
  constructor(
    private ngZone: NgZone,
    private authService: AuthService,
    private store: Store<AppState>,
    private router: Router,
  ) {}
  /**
   * ngOnInit
   */
  ngOnInit() {
    this.authClient.token
      .parseFromUrl()
      .then(tokenOrTokens => {
        this.authService.setTokens(tokenOrTokens);
        this.router.navigateByUrl(window.localStorage.getItem('returnUrl') || 'dashboard', { replaceUrl: true });
        this.store.dispatch({ type: AuthActionTypes.LOGIN_SUCCESS });
        window.localStorage.removeItem('returnUrl');
      })
      .catch(err => {
        console.log('err => ', err);
        // this.authClient.signOut();
      });
  }
}
