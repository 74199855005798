/**
 * BulkOrdersState
 */

import { BulkOrder } from "@app/core/models/bulk-upload.model";
import { BulkOrdersActionTypes, BulkOrdersActions } from "../actions";


export interface BulkOrdersState {
  loaded: boolean;
  pending: boolean;
  error: any;
  list: BulkOrder;
  searchTerm: string;
}

/**
 * MY_ORDERS_INITIAL_STATE
 * @type {{loaded: boolean; pending: boolean; error: null; profile: null}}
 */
export const BULK_ORDERS_INITIAL_STATE: BulkOrdersState = {
  loaded: false,
  pending: false,
  error: null,
  list: null,
  searchTerm: '',
};
/**
 * bulkOrdersReducer
 * @param {BulkOrdersState} state
 * @param {BulkOrdersActions} action
 * @returns {BulkOrdersState}
 */
export const bulkOrdersReducer = (
  state: BulkOrdersState = BULK_ORDERS_INITIAL_STATE,
  action: BulkOrdersActions,
): BulkOrdersState => {
  switch (action.type) {
    case BulkOrdersActionTypes.LOAD_BULK_ORDERS:
      return {
        ...state,
        error: null,
        loaded: false,
        pending: true,
      };
    case BulkOrdersActionTypes.LOAD_BULK_ORDERS_SUCCESS:
      return {
        ...state,
        loaded: true,
        pending: false,
        list: action.payload,
      };
    case BulkOrdersActionTypes.LOAD_BULK_ORDERS_ERROR:
      return { ...state, error: action.payload };
    default:
      return state;
  }
};
