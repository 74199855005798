import { AbstractControl } from '@angular/forms';
import { size } from 'lodash-es';

interface Error {
  atLeastOneNumber?: Boolean;
  atLeastOneCapitalLetter?: Boolean;
  characterInvalid?: Boolean;
  isShort?: Boolean;
  atLeastOneUpperCase?: Boolean;
  atLeastOneLowerCase?: Boolean;
  notIncludeFromEmailAddress?: Boolean;
  samePasswordFromOld?: Boolean;
}

export function ValidatePasswordFPO(control: AbstractControl) {
  if (!control.value) {
    return null;
  }

  const value: string = control.value.trim();
  const errors: Error = {};

  if (!/[0-9]/.test(value)) {
    errors.atLeastOneNumber = true;
  }

  if (!/[A-Z]/.test(value)) {
    errors.atLeastOneUpperCase = true;
  }

  if (!/[a-z]/.test(value)) {
    errors.atLeastOneLowerCase = true;
  }

  if (/[^\w|\d|#|%|$|&|@|!|*]/i.test(value)) {
    errors.characterInvalid = true;
  }

  if (value.length < 8) {
    errors.isShort = true;
  }

  if (hasPasswordInUsername(value)) {
    errors.notIncludeFromEmailAddress = true;
  }

  if (isSamePasswordFromOldPassword(value)) {
    errors.samePasswordFromOld = true;
  }

  if (size(errors)) {
    return errors;
  }

  return null;
}

function hasPasswordInUsername(password) {
  let hasMatched = false;
  try {
    let username = localStorage.getItem('username_email');
    let email = username.split('@');
    let length = 0;
    if (password.length > 0) {
      length = password.length - 2;
    }

    for (let i = 0; i < length; i++) {
      let concatChars =
        password[i].toString() +
        password[i + 1].toString() +
        password[i + 2].toString();
      if (email[0].includes(concatChars)) {
        hasMatched = true;
      }
    }
  } catch (e) {
    hasMatched = false;
  }

  return hasMatched;
}

function isSamePasswordFromOldPassword(newPassword) {
  let oldPassword = localStorage.getItem('user_oldpassword');
  if (oldPassword) {
    if (newPassword === oldPassword) {
      return true;
    }
  }
  return false;
}
