<ecp-header>
  <ecp-nav class="mobile" *showAuthed="false" [toRight]="true">
    <ecp-nav-item label="Login" (click)="openLoginDialog($event)"> </ecp-nav-item>
  </ecp-nav>
  <ecp-nav *showAuthed="true" [ecpData]="superUserData" (locationSelect)="switchLocation($event)">
    <ecp-nav-item
      ecpToggle="is-open"
      *ngFor="let item of menu.app"
      [children]="item.children"
      [label]="item.label"
      [link]="item.link"
    >
    </ecp-nav-item>
  </ecp-nav>
  <ecp-nav *ngIf="patientId$ | async">
    <ecp-nav-item
      ecpToggle="is-open"
      *ngFor="let item of menu.app"
      [children]="item.children"
      [label]="item.label"
      [link]="item.link"
    >
    </ecp-nav-item>
  </ecp-nav>
</ecp-header>
<ecp-sidenav [appNav]="menu.app" [userNav]="menu.user">
  <main class="page-content">
    <ng-content></ng-content>
  </main>
</ecp-sidenav>
<ecp-footer></ecp-footer>
