import { MenuItem } from '@app/core/models/menu.model';

export const UserMenuItems: MenuItem[] = [
  {
    label: 'Log out',
    link: '/auth/logout',
    order: 1,
  },

  // {
  //   label: 'My Account',
  //   order: 2,
  //   children: [
  
  //     {
  //       label: 'My Profile Info',
  //       link: '/user/profile',
  //       order: 1,
  //     },
  //   ],
  // },
  //
  // {
  //   label: 'Manage',
  //   order: 3,
  //   children: [
  //     {
  //       label: 'Manage Practice Info',
  //       link: '/user/practice',
  //       order: 1,
  //     },
  //   ],
  // },
];

export const AppMenuItems: MenuItem[] = [
  {
    label: 'Catalog',
    link: '/catalog',
    order: 1,
  },

  {
    label: 'My Collection',
    link: '/my-collection',
    order: 2,
  },

  {
    label: 'Orders',
    order: 3,
    children: [
      {
        label: 'My Orders',
        link: '/my-orders',
        order: 1,
      },
      {
        label: 'Realignment Orders',
        link: '/realignment-orders',
        order: 2,
      },
      {
        label: 'Frame to Come',
        order: 3,
        link: '/frame-to-come/track-shipment',
        // children:[
        //   {
        //     label: 'Create New',
        //     link: 'frame-to-come',
        //     order: 0,
        //   }
        // ]
      },
    ],
  },

  {
    label: 'My Returns',
    order: 4,
    children: [
      {
        label: 'Refresh Returns',
        link: '/refresh-return',
        order: 1,
      },
      {
        label: 'Return Requests',
        link: '/frame-return',
        order: 2,
      },
      {
        label: 'Return Request Status',
        link: '/returns-status',
        order: 3,
      },
    ],
  },

  {
    label: 'Patient Cart',
    link: '/patient-carts',
    order: 5,
  },

  {
    label: 'Help Center',
    link: '/help-center',
    order: 6,
  },
  {
    label: 'Bulk Order',
    order: 7,
    children: [
      { label: 'Upload', link: '/bulk-order-upload', order: 1 },
      { label: 'Order Status', link: '/bulk-orders', order: 2 },
      { label: 'Individual Order Review', link: '/bulk-orders/review', order: 2 },
    ],
  },
];
