import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap, takeUntil } from 'rxjs/operators';
import { Router } from '@angular/router';
import { RealignmentOrdersService } from '@app/core/services';
import { Effect, Actions, ofType, createEffect } from '@ngrx/effects';
import { RealignmentOrdersActionTypes, AuthActionTypes } from '../actions';

/**
 * RealignmentOrdersEpics
 */
@Injectable()
export class RealignmentOrdersEffects {
  /**
   * constructor
   * @param {RealignmentOrdersService} RealignmentOrdersService
   */
  constructor(
    private RealignmentOrdersService: RealignmentOrdersService,
    private router: Router,
    private actions$: Actions,
  ) {}

  /**
   * loadRealignmentOrders
   * @param {ActionsObservable<any>} action$
   * @returns {Observable<any>}
   */
  loadRealignmentOrders = createEffect(() =>
    this.actions$.pipe(
      ofType(RealignmentOrdersActionTypes.LOAD_REALIGNMENT_ORDERS),
      switchMap(() => {
        return this.RealignmentOrdersService.getRealignmentOrders().pipe(
          map(orders => ({
            type: RealignmentOrdersActionTypes.LOAD_REALIGNMENT_ORDERS_SUCCESS,
            payload: orders,
          })),
          catchError(err =>
            of({
              type: RealignmentOrdersActionTypes.LOAD_REALIGNMENT_ORDERS_ERROR,
              payload: err,
            }),
          ),
        );
      }),
    ),
  );

  /**
   * loadRealignmentOrdersError
   * @param {ActionsObservable<any>} action$
   * @returns {Observable<any>}
   */
  loadRealignmentOrdersError = createEffect(() =>
    this.actions$.pipe(
      ofType(RealignmentOrdersActionTypes.LOAD_REALIGNMENT_ORDERS_ERROR),
      map(() => ({ type: AuthActionTypes.LOGOUT })),
    ),
  );
}
