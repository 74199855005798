import { Injectable } from '@angular/core';
import { AuthService } from '@app/auth/auth.service';
import { Actions, Effect, ofType, createEffect } from '@ngrx/effects';
import { map, tap } from 'rxjs/operators';
import { UserActionTypes } from '../actions';
import * as authActions from '../actions';
/**
 * AuthEffects
 */
@Injectable()
export class AuthEffects {
  /**
   * constructor
   * @param actions$
   * @param {AuthService} authService
   */
  constructor(private actions$: Actions, private authService: AuthService) {}

  login$ = createEffect(() =>
    this.actions$.pipe(
      ofType(authActions.AuthActionTypes.LOGIN_SUCCESS),
      map(() => ({ type: UserActionTypes.LOAD_USER })),
    ),
  );

  logout$ = createEffect(() =>
    this.actions$.pipe(
      ofType(authActions.AuthActionTypes.LOGOUT),
      tap(() => this.authService.logout()),
      map(() => {
        return { type: 'UPDATE_LOCATION', payload: '' };
      }),
    ),
  );
}
