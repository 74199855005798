import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { HttpClient } from '@angular/common/http';
import { MyReturn, MyRefresh } from '@app/core/models/my-return.model';
import { ApiService } from '@app/core/services/api.service';
import { State } from '@ngrx/store';
import { AppState } from '@app/core/ngrx';

/**
 * BrandsService
 */
@Injectable({ providedIn: 'root' })
export class MyReturnService {
  /**
   * url
   */
  public url;

  /**
   * constructor
   * @param {ApiService} apiService
   * @param {HttpClient} http
   */
  constructor(
    private apiService: ApiService,
    private http: HttpClient,
    private state: State<AppState>,
  ) {}

  /**
   * getMyReturn
   * @returns {Observable<MyReturn[]>}
   */
  getMyReturn(): Observable<MyRefresh> {
    const options = {
      headers: {
        programKey: this.state.getValue().user.profile.program.programKey,
      },
    };
    return this.apiService.get('/myreturn');
  }

  postReturn(payload) {
    const options = {
      headers: {
        programKey: this.state.getValue().user.profile.program.programKey,
      },
    };
    return this.apiService.post('/myreturn', payload, options);
  }
}
