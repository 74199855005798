import {
  ChangeDetectionStrategy,
  Component,
  Input,
  Inject,
  OnInit,
} from '@angular/core';
import { MyNotifications } from '@app/core/models/my-notifications.model';

import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs/internal/Observable';
import { AppState, selectProgram } from '@app/core/ngrx';
import { VWProgram } from '@app/core/models/user.model';

@Component({
  selector: 'ecp-add-to-cart-notifications',
  templateUrl: './add-to-cart-notifications.component.html',
  styleUrls: ['./add-to-cart-notifications.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddToCartNotificationsComponent implements OnInit {
  public program$: Observable<VWProgram>;
  theme = 'one-portal';
  @Input()
  notifications: MyNotifications[];
  constructor(
    private store: Store<AppState>,
    public router: Router,
    @Inject(MAT_DIALOG_DATA) public data,
  ) {}
  ngOnInit() {
    this.program$ = this.store.pipe(select(selectProgram));
    this.program$.pipe().subscribe(program => {
      if (program) {
        this.theme = program.programKey;
      }
    });
  }

  goToFrameCart(patientId) {
    this.router.navigateByUrl('/' + patientId + '/shopping-cart');
  }
}
