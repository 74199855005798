import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'phonemask',
})
export class PhoneMaskPipe implements PipeTransform {
  transform(number: string): string {
    try {
      const firstNum = number.slice(0, -7);
      const secondNum = number.slice(3, -4);
      const thirdNum = number.slice(6);
      return firstNum + '-' + secondNum + '-' + thirdNum;
    } catch (e) {
      return number;
    }
  }
}
