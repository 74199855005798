import { Component, NgZone, OnInit } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { Store, select } from '@ngrx/store';
import { AppState, AuthActionTypes } from '@app/core/ngrx';
import { AuthService } from '@app/auth/auth.service';
import { Router } from '@angular/router';

@Component({
  selector: 'ecp-login-header-form',
  templateUrl: './login-header-form.component.html',
  styleUrls: ['./login-header-form.component.scss'],
})
export class LoginHeaderFormComponent implements OnInit {
  loginForm: FormGroup;

  // @select(['auth', 'error'])
  error$: Observable<any>;

  // @select(['auth', 'pending'])
  pending$: Observable<boolean>;

  constructor(
    private _ngZone: NgZone,
    private router: Router,
    private store: Store<AppState>,
    private authService: AuthService,
  ) {}

  ngOnInit(): void {
    this.error$ = this.store.pipe(select(state => state.auth.error));
    this.pending$ = this.store.pipe(select(state => state.auth.pending));
    this.loginForm = this.createForm();
  }

  get username(): AbstractControl {
    return this.loginForm.get('username');
  }

  get password(): AbstractControl {
    return this.loginForm.get('password');
  }

  createForm(): FormGroup {
    const username = new FormControl('', {
      validators: Validators.compose([Validators.required, Validators.email]),
    });

    const password = new FormControl('', {
      validators: Validators.compose([Validators.required, Validators.minLength(1)]),
    });

    return new FormGroup({ username, password }, { updateOn: 'submit' });
  }

  onSubmit() {
    if (this.loginForm.valid) {
      this.store.dispatch({ type: AuthActionTypes.LOGIN });

      this.authService.login(this.loginForm.value).catch(err => {
        if (err.toString().indexOf('PASSWORD_EXPIRED') >= 1) {
          this.store.dispatch({
            type: AuthActionTypes.LOGIN_ERROR,
            payload: null,
          });
          this._ngZone.run(() => this.router.navigateByUrl(`reset-password/${this.username.value}`));
        } else {
          this.store.dispatch({
            type: AuthActionTypes.LOGIN_ERROR,
            payload: err,
          });
          this._ngZone.run(() => this.router.navigateByUrl('/auth/logout'));
        }
      });
    }

    return false;
  }
}
