import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpParams,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
/**
 * ApiService
 */
@Injectable({ providedIn: 'root' })
export class ChatApiService {
  /**
   * options
   * @type {{headers: {'Content-Type': string}}}
   */
  private options = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  /**
   * constructor
   * @param {HttpClient} http
   */
  constructor(private http: HttpClient) {}
  /**
   * formatErrors
   * @param {HttpErrorResponse} err
   * @returns {Observable<never>}
   */
  private formatErrors(err: HttpErrorResponse): Observable<never> {
    return throwError(err);
  }
  /**
   * get
   * @param {string} path
   * @param {HttpParams} params
   * @returns {Observable<any>}
   */
  get(path: string, params: HttpParams = new HttpParams()): Observable<any> {
    return this.http
      .get(`${path}`, { params })
      .pipe(catchError(this.formatErrors));
  }
}
