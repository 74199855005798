import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap, takeUntil } from 'rxjs/operators';

import { Router } from '@angular/router';
import { MyCollectionService } from '@app/core/services/my-collection.service';
import { Actions, Effect, ofType, createEffect } from '@ngrx/effects';
import { MyCollectionActionTypes, AuthActionTypes } from '../actions';

/**
 * MyCollectionEpics
 */
@Injectable()
export class MyCollectionEffects {
  /**
   * constructor
   * @param {MyCollectionService} brandsService
   */
  constructor(
    private brandsService: MyCollectionService,
    private actions$: Actions,
    private router: Router,
  ) {}

  /**
   * loadMyCollection
   * @param {ActionsObservable<any>} action$
   * @returns {Observable<any>}
   */
  loadMyCollection = createEffect(() =>
    this.actions$.pipe(
      ofType(MyCollectionActionTypes.LOAD_MY_COLLECTION),
      switchMap(() => {
        return this.brandsService.getMyCollection().pipe(
          map(brands => ({
            type: MyCollectionActionTypes.LOAD_MY_COLLECTION_SUCCESS,
            payload: brands,
          })),
          catchError(err =>
            of({
              type: MyCollectionActionTypes.LOAD_MY_COLLECTION_ERROR,
              payload: err,
            }),
          ),
        );
      }),
    ),
  );

  /**
   * loadMyCollectionError
   * @param {ActionsObservable<any>} action$
   * @returns {Observable<any>}
   */
  loadMyCollectionError = createEffect(() =>
    this.actions$.pipe(
      ofType(MyCollectionActionTypes.LOAD_MY_COLLECTION_ERROR),
      map(() => ({ type: AuthActionTypes.LOGOUT })),
    ),
  );
}
